import {RestService} from "@/services/rest/RestService";
import {OrderSettingsAPIApi} from "@/api/pharma-order-settings";
import {NameValuePairOverviewRestDto, NameValuePairValueRestDto} from "@/api/pharma-order-settings/models";
import {NameValuePairEnum} from "@/models/enum/NameValuePairEnum";

export class OrderSettingsRestService extends RestService {

    private apiService: OrderSettingsAPIApi;

    protected constructor() {
        super();

        this.apiService = new OrderSettingsAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().orderUrl);
    }

    public async findNameValuePairsPharmacy(pharmacy_code: string, customer_code: string): Promise<NameValuePairOverviewRestDto[]> {
        const response = await this.apiService.findPharmacyNvps(pharmacy_code, customer_code);

        return response.data;
    }

    public async updatePharmacyNvp(value: string, name: NameValuePairEnum, pharmacy_code: string, customer_code: string): Promise<void> {
        const body = {value} as NameValuePairValueRestDto;
        await this.apiService.updatePharmacyNvp(body, name, pharmacy_code, customer_code);
    }
}