
import {defineComponent} from "vue";
import {DpNameValuePairValueRestDtoModel} from "@/models/DpNameValuePairValueRestDtoModel";
import {UIStateDto} from "@/dtos/UIStateDto";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertError2 from "@/components/UI/AlertError2.vue";
import InputText from "@/components/UI/InputText.vue";
import {DpModuleHelper} from "@/helpers/DpModuleHelper";
import {NameValuePairEnumRestService} from "@/services/rest/pharma-master/NameValuePairEnumRestService";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {NameValuePairRestService} from "@/services/rest/pharma-master/NameValuePairRestService";
import InputSelect from "@/components/UI/InputSelect.vue";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {DpNameValuePairDetailRestDtoModel} from "@/models/DpNameValuePairDetailRestDtoModel";
import BaseCardHeader from "@/components/UI/Card/BaseCardHeader.vue";
import BaseCardBody from "@/components/UI/Card/BaseCardBody.vue";
import BaseCard from "@/components/UI/Card/BaseCard.vue";
import {mapActions, mapGetters} from "vuex";
import {NameValuePairCriteriaPayload} from "@/models/payload/NameValuePairCriteriaPayload";

export default defineComponent({
  name: "NameValuePairDetail",
  components: {BaseCard, BaseCardBody, BaseCardHeader, InputSelect, BaseSpinner, InputText, AlertError2, BaseTitle},
  props: ["mode"],
  data() {
    return {
      id: this.$route.params.id as string | null,

      nvp: new DpNameValuePairValueRestDtoModel(
          this.$route.query.customer_code as string | null | undefined,
          this.$route.query.pharmacy_code as string | null | undefined,
      ),
      nvpUI: UIStateDto.createWithDefaults(),
      nvpDetails: DpNameValuePairDetailRestDtoModel.createWithDefaults(),
      nvpName: null as string | null,
      nvpOptions: [] as CodeDescriptionRestDtoModel[]
    }
  },
  watch: {
    xDpModuleCode: {
      async handler(): Promise<void> {
        await this.reloadContent();
      },
      immediate: true
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('nvp', ["getNvpCriteriaPayload"]),
    xDpModuleCode: {
      get(): NameValuePairCriteriaPayload {
        return this.getNvpCriteriaPayload.x_dp_module_code;
      },
      set(value: string) {
        this.setNvpCriteriaModuleCode(value);
      }
    },
    goBackRoute(): object {
      return {name: 'nvps'};
    },
    DpModuleHelper() {
      return DpModuleHelper
    },
    PageDetailModeEnum() {
      return PageDetailModeEnum;
    },
  },
  methods: {
    ...mapActions("nvp", ["setNvpCriteriaModuleCode"]),
    async reloadContent(): Promise<void> {
      this.nvpUI
          .clearError()
          .setIsReady(false);

      try {
        const promises = [this.searchNameValuePairNames()];
        if (this.mode === PageDetailModeEnum.EDIT) {
          promises.push(this.searchNameValuePairDetails());
        }

        await Promise.allSettled(promises);

        this.nvpName = this.nvpDetails.name;
        this.nvp.value = this.nvpDetails?.value;
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.nvpUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.nvpUI.setIsReady(true);
      }
    },
    async searchNameValuePairDetails(): Promise<void> {
      this.nvpDetails = await NameValuePairRestService.getInstance().findNameValuePairDetails(
          this.id,
          this.xDpModuleCode
      );
    },
    async searchNameValuePairNames(): Promise<void> {
      this.nvpOptions = await NameValuePairEnumRestService.getInstance().findNameValuePairNames(
          this.xDpModuleCode
      );
    },
    async submitData(): Promise<void> {
      this.nvpUI.clearError();

      try {
        if (this.mode === PageDetailModeEnum.NEW) {
          await NameValuePairRestService.getInstance().createNameValuePair(
              this.nvp,
              this.nvpName,
              this.xDpModuleCode,
              this.nvp.customer_code
          );
          await handleSavedSuccessfully();

          await this.$router.push(this.goBackRoute);
        } else if (this.mode === PageDetailModeEnum.EDIT) {
          await NameValuePairRestService.getInstance().updateNameValuePair(
              this.nvp,
              this.id,
              this.xDpModuleCode,
              this.nvp.customer_code
          );
          await handleSavedSuccessfully();

          await this.reloadContent();
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.nvpUI as UIStateDto))
            .catch(exceptions, true);
      }
    }
  }
});
