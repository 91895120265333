import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pharmacy-info-field" }
const _hoisted_2 = {
  key: 0,
  class: "fw-bolder"
}
const _hoisted_3 = {
  key: 1,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.value), 1))
      : _createCommentVNode("", true)
  ]))
}