
import {defineComponent} from 'vue'
import AlertError2 from "@/components/UI/AlertError2.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {UIStateDto} from "@/dtos/UIStateDto";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {DpOnlyRestService} from "@/services/rest/pharma-cms-only/DpOnlyRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";

export default defineComponent({
  name: "DefaultClosingPeriods",
  components: {AlertError2, InputSelect},
  props: {
    customerCode: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentYear: new Date().getFullYear() as number,
      untilYear: null as string | null,
      optionsUntilYear: [] as CodeDescriptionRestDto[],
      defaultClosingPeriodsUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.generateOptionsUntilYear();
  },
  computed: {
    addForAllCustomers(): boolean {
      return !this.customerCode;
    }
  },
  methods: {
    generateOptionsUntilYear(): void {
      this.optionsUntilYear = [];

      for (let year = this.currentYear; year < this.currentYear + 6; year++) {
        this.optionsUntilYear.push(
            new CodeDescriptionRestDtoModel(year.toString(), year.toString())
        );
      }
    },
    async submitData(): Promise<void> {
      this.defaultClosingPeriodsUI.clearError();

      try {
        if (!this.untilYear) {
          throw 'Fill in the until field.';
        }

        if (this.addForAllCustomers) {
          const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
            title: 'Standaard sluitingsperiodes toevoegen',
            body: 'Ben je zeker dat je de standaard sluitingsperiodes wenst toe te voegen voor alle customers?',
            confirmButtonClass: 'btn-primary',
            confirmButtonText: 'Bevestigen',
          });

          onConfirm(async () => {
            await DpOnlyRestService.getInstance()
                .createDefaultClosingPeriods(parseInt(this.untilYear as string), undefined);

            await handleSavedSuccessfully();
          });

          await reveal();
        } else {
          await DpOnlyRestService.getInstance()
              .createDefaultClosingPeriods(parseInt(this.untilYear), this.customerCode);

          await handleSavedSuccessfully();
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.defaultClosingPeriodsUI as UIStateDto))
            .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.defaultClosingPeriodsUI as UIStateDto))
            .catch(exceptions, true);
      }
    }
  }
})
