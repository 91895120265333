
import {Exception} from '@/api/interfaces';
import store from "@/store";
import {defineComponent} from 'vue'
import {SimpleTextContentRestDto} from "@/api/pharma-cms-content-block/models";
import {mapGetters} from "vuex";
import {StatusEnum} from "@/api/enums/status-enum";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbTextForm",
  components: {InputTextEditor},

  data: () => ({
    exceptions: [] as Exception[],
    savedSuccessfully: false,
    cb: {
      status_code: StatusEnum.PUBLISHED,
      content: {
        nl: '',
      },
    } as SimpleTextContentRestDto,
  }),
  props: {
    title: {
      type: String,
    },
    label: {
      type: String,
    },
    cb_type: {
      type: String,
    },
    use_textarea: {
      type: Boolean,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_content_block', ['getSimpleContentBlock'])
  },
  methods: {
    submitData() {
      this.saveSimpleContentBlock();
    },
    async reloadContent() {
      try {
        await this.searchSimpleContentBlock();
        let simple_cb = this.getSimpleContentBlock(this.cb_type);
        if (simple_cb) {
          this.cb = simple_cb;
        }
      } catch (exceptions) {
        this.exceptions = exceptions;
      }
    },
    async saveSimpleContentBlock() {
      try {
        const payload = {
          cb_type: this.cb_type,
          cb: this.cb,
        }
        await store.dispatch('cms_content_block/saveSimpleContentBlock', payload);
        this.savedSuccessfully = true;
        setTimeout(() => this.savedSuccessfully = false, 2000);
      } catch (exceptions) {
        this.exceptions = exceptions;
      }
    },
    async searchSimpleContentBlock() {
      try {
        await store.dispatch('cms_content_block/searchSimpleContentBlock', this.cb_type);
      } catch (exceptions) {
        this.exceptions = exceptions;
      }
    },
  },
});
