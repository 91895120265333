import {DpNameValuePairValueRestDto} from "@/api/pharma-master-nvp/models";

export class DpNameValuePairValueRestDtoModel implements DpNameValuePairValueRestDto {
    customer_code?: string | null;
    pharmacy_code?: string | null;
    value?: string | null;

    constructor(customer_code?: string | null, pharmacy_code?: string | null, value?: string | null) {
        this.customer_code = customer_code;
        this.pharmacy_code = pharmacy_code;
        this.value = value;
    }

    public static createWithDefaults(): DpNameValuePairValueRestDtoModel {
        return new DpNameValuePairValueRestDtoModel(null, null, null);
    }
}