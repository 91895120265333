import {LanguageSettingsRestDto} from "@/api/pharma-customer-settings/models";

export class LanguageSettingsRestDtoModel implements LanguageSettingsRestDto {
    default_language_code?: string;
    additional_language_codes?: string[] | null;

    constructor(default_language_code: string, additional_language_codes: string[] | null) {
        this.default_language_code = default_language_code;
        this.additional_language_codes = additional_language_codes;
    }

    public static createWithDefaults(): LanguageSettingsRestDtoModel {
        return new this('nl', []);
    }
}