import {createStore} from 'vuex';

import authModule from './auth/auth.js';
import cmsContentBlock from "@/store/pharma-cms-content-block/cms-content-block";
import cmsDocument from "@/store/pharma-cms-document/cms_document";
import cmsPharmacy from "@/store/pharma-cms-pharmacy/cms_pharmacy";
import customersModule from './pharma-customer/customers.ts';
import jobRunsModule from './pharma-monitoring/job-runs.ts';
import licenseInfoModule from './pharma-license-license-info/license-license-info.ts';
import masterEnum from "@/store/pharma-master-enum/master_enum";
import masterLicenseModule from './pharma-master-license-mgmt/master-license-mgmt.ts';
import nvpModule from './nvp/nvp.ts';
import orderSettingsModule from './pharma-order-settings/order_settings';
import textProcessingModule from './tp/text_processing';

const store = createStore({
    modules: {
        auth: authModule,
        job_runs: jobRunsModule,
        cms_content_block: cmsContentBlock,
        cms_document: cmsDocument,
        cms_pharmacy: cmsPharmacy,
        customers: customersModule,
        license_info: licenseInfoModule,
        master_license: masterLicenseModule,
        nvp: nvpModule,
        order_settings: orderSettingsModule,
        master_enum: masterEnum,
        text_processing: textProcessingModule
    },
});

export default store;
