
import {defineComponent} from 'vue';

export default defineComponent({
  name: "BaseSpinner",
  props: {
    spin: {
      type: Boolean,
      required: false,
      default: true,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
    useOverlay: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    modeClasses() {
      let classes = '';

      if (this.useOverlay) {
        classes += 'spinner-overlay';
      }

      return classes;
    }
  }
});
