import {createRouter, createWebHistory} from 'vue-router';
import store from '../store/index.js';
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";

import Home from '@/pages/Home.vue';
import LoginPage from '@/pages/auth/LoginPage.vue';
import NotFound from '@/pages/NotFound.vue';
import Oauth2Callback from '@/pages/auth/Oauth2Callback.vue';

import IndexCustomer from "@/pages/customer/Index.vue";
import CustomerOverview from '@/pages/customer/CustomerOverview.vue';
import CustomerDetail from "@/pages/customer/CustomerDetail.vue";
import CustomerDetailDomain from "@/pages/customer/CustomerDetailDomain.vue";
import CustomerDetailLicense from "@/pages/customer/CustomerDetailLicense.vue";
import CustomerDetailPharmacy from "@/pages/customer/CustomerDetailPharmacy.vue";
import CustomerDetailPharmacistUser from "@/pages/customer/CustomerDetailPharmacistUser.vue";

import Dashboard from "@/pages/dashboard/Dashboard.vue";

import IndexNameValuePair from "@/pages/nvp/Index.vue";
import NameValuePairOverview from "@/pages/nvp/NameValuePairOverview.vue";
import NameValuePairDetail from "@/pages/nvp/NameValuePairDetail.vue";

import JobRunOverview from '@/pages/monitoring/JobRunOverview.vue';

import IndexTextProcessing from "@/pages/text-processing/Index.vue";
import TaskOverview from "@/pages/text-processing/TaskOverview.vue";
import TaskDetail from "@/pages/text-processing/TaskDetail.vue";

import DefaultClosingPeriodsGeneral from "@/pages/opening-hours/DefaultClosingPeriodsGeneral.vue";

import IndexLegal from "@/pages/legal/Index.vue";
import TermsAndConditionsStatic from "@/pages/legal/TermsAndConditionsStatic";
import TermsAndConditionsReservation from "@/pages/legal/TermsAndConditionsReservation";
import TermsAndConditionsWebshop from "@/pages/legal/TermsAndConditionsWebshop";
import PrivacyPolicyStatic from "@/pages/legal/PrivacyPolicyStatic";
import PrivacyPolicyReservation from "@/pages/legal/PrivacyPolicyReservation";
import PrivacyPolicyWebshop from "@/pages/legal/PrivacyPolicyWebshop";
import PharmacyOnCall from "@/pages/pharmacy-on-call/PharmacyOnCall";
import CustomerCreatePharmacistUser from "@/pages/customer/CustomerCreatePharmacistUser.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: '/', redirect: '/home'},
        {name: 'home', path: '/home', components: {default: Home}},
        {name: 'login', path: '/login', components: {default: LoginPage}},
        {path: '/oauth2/callback', component: Oauth2Callback, meta: {requiresUnauth: false}},
        {
            name: 'customers',
            path: '/customers',
            meta: {requiresAuth: true},
            component: CustomerOverview,
        },
        {
            name: 'customer',
            path: '/customer',
            component: IndexCustomer,
            meta: {requiresAuth: true},
            children: [
                {
                    name: 'new-customer',
                    path: '',
                    component: CustomerDetail,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'edit-customer',
                    path: ':id',
                    component: CustomerDetail,
                    props: {mode: PageDetailModeEnum.EDIT},
                },
                {
                    name: 'new-domain',
                    path: ':customer_code/domain',
                    component: CustomerDetailDomain,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'edit-domain',
                    path: ':customer_code/domain/:domain_id',
                    component: CustomerDetailDomain,
                    props: {mode: PageDetailModeEnum.EDIT},
                },
                {
                    name: 'new-license',
                    path: ':customer_code/license',
                    component: CustomerDetailLicense,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'edit-license',
                    path: ':customer_code/license/:license_id',
                    component: CustomerDetailLicense,
                    props: {mode: PageDetailModeEnum.EDIT},
                },
                {
                    name: 'new-pharmacy',
                    path: ':customer_code/pharmacy',
                    component: CustomerDetailPharmacy,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'create-pharmacist-user',
                    path: ':customer_code/user',
                    component: CustomerCreatePharmacistUser,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'edit-pharmacist-user',
                    path: ':customer_code/user/:id',
                    component: CustomerDetailPharmacistUser,
                    props: {mode: PageDetailModeEnum.EDIT},
                },
            ]
        },
        {
            name: 'dashboard',
            path: '/dashboard',
            component: Dashboard,
            meta: {requiresAuth: true}
        },
        {
            name: 'nvps',
            path: '/nvps',
            component: NameValuePairOverview,
            meta: {requiresAuth: true}
        },
        {
            name: 'nvp',
            path: '/nvp',
            component: IndexNameValuePair,
            meta: {requiresAuth: true},
            children: [
                {
                    name: 'new-nvp',
                    path: '',
                    component: NameValuePairDetail,
                    props: {mode: PageDetailModeEnum.NEW},
                },
                {
                    name: 'edit-nvp',
                    path: ':id',
                    component: NameValuePairDetail,
                    props: {mode: PageDetailModeEnum.EDIT},
                }
            ]
        },
        {
            name: 'job-runs',
            path: '/job-runs',
            meta: {requiresAuth: true},
            component: JobRunOverview
        },
        {
            name: 'text-processing',
            path: '/text-processing',
            component: IndexTextProcessing,
            meta: {requiresAuth: true},
            children: [
                {
                    name: 'text-processing-task-overview',
                    path: 'tasks',
                    component: TaskOverview,
                },
                {
                    name: 'text-processing-task-detail',
                    path: 'task/:id',
                    component: TaskDetail,
                },
            ]
        },
        {
            name: 'default-closing-periods',
            path: '/default-closing-periods',
            component: DefaultClosingPeriodsGeneral,
            meta: {requiresAuth: true},
        },
        {
            name: 'terms-and-conditions',
            path: '/terms-and-conditions',
            meta: {requiresAuth: true},
            component: IndexLegal,
            children: [
                {
                    name: 'terms-and-conditions-static',
                    path: 'static',
                    component: TermsAndConditionsStatic,
                },
                {
                    name: 'terms-and-conditions-reservation',
                    path: 'reservation',
                    component: TermsAndConditionsReservation,
                },
                {
                    name: 'terms-and-conditions-webshop',
                    path: 'webshop',
                    component: TermsAndConditionsWebshop,
                }
            ]
        },
        {
            name: 'privacy-policy',
            path: '/privacy-policy',
            meta: {requiresAuth: true},
            component: IndexLegal,
            children: [
                {
                    name: 'privacy-policy-static',
                    path: 'static',
                    component: PrivacyPolicyStatic,
                },
                {
                    name: 'privacy-policy-reservation',
                    path: 'reservation',
                    component: PrivacyPolicyReservation,
                },
                {
                    name: 'privacy-policy-webshop',
                    path: 'webshop',
                    component: PrivacyPolicyWebshop,
                }
            ]
        },
        {
            name: 'pharmacy-on-call',
            path: '/pharmacy-on-call',
            meta: {requiresAuth: true},
            component: PharmacyOnCall,
        },
        {path: '/auth', component: LoginPage, meta: {requiresUnauth: true}},
        {path: '/oauth2/callback', component: Oauth2Callback, meta: {requiresUnauth: false}},
        {path: '/:notFound(.*)', component: NotFound},
    ],
    linkActiveClass: 'active',
    scrollBehavior(_, _2, savedPosition) {
        // console.log(to, from, savedPosition);
        if (savedPosition) {
            return savedPosition;
        }
        return {left: 0, top: 0};
    },
});

router.beforeEach(function (to, _, next) {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        next('/customers');
    } else {
        next();
    }
});

export default router;