interface MergedObject {
    [key: string]: any;
}

function mergeDeepJson(target: MergedObject, json: string): MergedObject {
    const source = JSON.parse(json) as MergedObject;

    return mergeDeep(target, source);
}

function mergeDeep(target: MergedObject, source: MergedObject): MergedObject {
    const merged = { ...target };

    for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
            if (typeof source[key] === 'object' && Object.prototype.hasOwnProperty.call(target, key) && typeof target[key] === 'object') {
                merged[key] = mergeDeep(target[key], source[key]);
            } else {
                merged[key] = source[key];
            }
        }
    }

    return merged;
}

export {mergeDeepJson, mergeDeep};