<template>
  <cb-text-form :title="$t('pharmacyOnCall')"
                :label="$t('url')"
                :cb_type="'PHARMACY_ON_CALL_URL'"
                :use_textarea="false"/>
</template>

<script>
import CbTextForm from "@/components/layouts/CbTextForm";

export default {
  components: {CbTextForm}
}
</script>

<style scoped>

</style>