export class AuthInfoModel {
    constructor(
        public user: object | null,
        public accessToken: string | null,
        public idToken: string | null,
        public didAutoLogout: boolean
    ) {}

    public static createWithDefaults(): AuthInfoModel {
        return new this(null, null, null, false);
    }
}