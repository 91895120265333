
import JobRunItem from '../../components/layouts/monitoring/JobRunItem.vue';
import JobRunCriteriaPanel from '../../components/layouts/monitoring/JobRunCriteriaPanel.vue';
import {defineComponent} from "vue";
import {JobRunCriteriaRestDto, JobRunRestDto} from "@/api/pharma-monitoring/models";
import store from "@/store";


export default defineComponent({
  components: {
    JobRunItem,
    JobRunCriteriaPanel,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      criteria: {} as JobRunCriteriaRestDto,
    };
  },
  provide() {
    return {
      getError: this.getError,
      isIsLoading: this.isIsLoading,
      searchJobRuns: this.searchJobRuns,
    };
  },
  computed: {
    filteredJobRuns(): Array<JobRunRestDto> {
      return store.getters['job_runs/getJobRuns'];
    },
    hasJobRuns(): Boolean {
      return (!(Array.isArray(this.filteredJobRuns) && !this.filteredJobRuns.length));
    },
  },
  methods: {
    isIsLoading() {
      return this.isLoading;
    },
    getError() {
      return this.error;
    },
    async searchJobRuns(criteria: JobRunCriteriaRestDto) {
      console.log('criteria', criteria);

      this.error = null;
      try {
        this.isLoading = true;
        await store.dispatch('job_runs/searchJobRuns', criteria);
        this.isLoading = false;
      } catch (error) {
        console.log('COMPONENT HANDLING', error);
        this.error = error.message;
      }
    },
  },
});
