
import {defineComponent, ref} from 'vue'
import InputSelect from "@/components/UI/InputSelect.vue";
import BasePagination from "@/components/UI/Bootstrap/BasePagination.vue";
import {PropType} from "vue/dist/vue";
import {PagingMetadataRestDto} from "@/api/pharma-tp-task/models";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {PagingPayload} from "@/models/payload/PagingPayload";

export default defineComponent({
  name: "TaskDetailControls",
  components: {BasePagination, InputSelect},
  emits: ["change"],
  props: {
    pagingPayload: {
      type: PagingPayload,
      required: true
    },
    pagingMetadata: {
      type: Object as PropType<PagingMetadataRestDto>,
      required: true
    }
  },
  setup(props) {
    const internalPayload = ref(PagingPayload.cloneFrom(props.pagingPayload));

    return {
      internalPayload,
    };
  },
  watch: {
    pagingPayload: {
      handler(newPayload: PagingPayload) {
        // console.log('payload changed: ', newPayload);
        this.internalPayload = PagingPayload.cloneFrom(newPayload);
      },
      deep: true,
      immediate: false
    }
  },
  computed: {
    pageSizeOptions(): CodeDescriptionRestDto[] {
      const sizes = ['12', '24', '48', '96', '192'] as string[];
      const pageSizeOptions = [] as CodeDescriptionRestDto[];

      sizes.forEach((size: string) => {
        pageSizeOptions.push(
            new CodeDescriptionRestDtoModel(size, size)
        );
      });

      return pageSizeOptions;
    }
  },
  methods: {
    onChange(): void {
      this.$emit('change', this.internalPayload);
    },
    onChangePageNumber(number: number): void {
      this.internalPayload.number = number;
      this.onChange();
    },
    onChangePageSize(): void {
      this.onChangePageNumber(1);
    }
  }
});
