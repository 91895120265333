import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cff1514"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-5 input-file-container row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "d-flex flex-column justify-content-between" }
const _hoisted_4 = {
  key: 0,
  class: "my-4 alert alert-danger"
}
const _hoisted_5 = ["for"]
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "mt-4 py-4 upload-preview" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_spinner = _resolveComponent("base-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("label", {
            for: _ctx.id,
            class: "form-label"
          }, _toDisplayString(_ctx.label), 9, _hoisted_5),
          _createElementVNode("input", {
            ref: `fileupload-${_ctx.id}`,
            class: "form-control",
            type: "file",
            id: _ctx.id,
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChosenFile && _ctx.handleChosenFile(...args)))
          }, null, 40, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.uploading)
            ? (_openBlock(), _createBlock(_component_base_spinner, {
                key: 0,
                "use-overlay": ""
              }))
            : (_ctx.preview_url)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: _ctx.preview_url,
                  class: "img-fluid",
                  alt: "preview uploaded image"
                }, null, 8, _hoisted_8))
              : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('noPreview')), 1))
        ])
      ]),
      _createElementVNode("button", {
        class: "btn btn-outline-primary mt-4",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)), ["prevent"]))
      }, _toDisplayString(_ctx.$t('delete')), 1)
    ])
  ]))
}