import store from "@/store";
import {UrlResponseRestDto} from "@/api/pharma-cms-document/models";
import {DocumentApiService} from "@/main";

export default {
    namespaced: true,
    state() {
        return {
            logo_img_url: {} as UrlResponseRestDto,
            logo_img_document_key: String,
        };
    },
    actions: {
        async searchPreviewDocument(context: any, payload: any) {
            const document_type = payload['document_type'];
            const document_key = payload['document_key'];
            await DocumentApiService.documentV1DocumentDocumentKeyPublicUrlGet(document_key).then(response => {
                const payloadResponse = {
                    document_type: document_type,
                    document_url: response.data,
                }
                store.commit('cms_document/setPreviewDocument', payloadResponse);
                store.commit('cms_document/setUploadedDocumentKey', payload);
            })
        },
        clearUploadedDocumentKey(context: any, type: String): void {
            const payload = {
                document_type: type,
                document_key: null
            }
            store.commit('cms_document/setUploadedDocumentKey', payload);
        }
    },
    mutations: {
        setPreviewDocument(state: any, payload: any) {
            const document_type = payload['document_type'];
            const document_url = payload['document_url'];
            // todo map gebruiken
            if (document_type === 'LOGO_IMG') {
                store.state.logo_img_url = document_url;
            } else {
                throw new Error('Unsupported document_type to search ' + document_type);
            }
        },
        setUploadedDocumentKey(state: any, payload: any) {
            const document_type = payload['document_type'];
            const document_key = payload['document_key'];
            console.log('clear img doc key', document_key)
            // todo map gebruiken
            if (document_type === 'LOGO_IMG') {
                store.state.logo_img_document_key = document_key;
            } else {
                throw new Error('Unsupported document_type to search ' + document_type);
            }
        },
    },
    getters: {
        getPreviewDocument: (state: any) => (document_type: String) => {
            // todo map gebruiken
            if (document_type === 'LOGO_IMG') {
                return store.state.logo_img_url;
            } else {
                throw new Error('Unsupported document_type to search ' + document_type);
            }
        },
        getUploadedDocumentKey: (state: any) => (document_type: String) => {
            // todo map gebruiken
            if (document_type === 'LOGO_IMG') {
                return store.state.logo_img_document_key;
            } else {
                throw new Error('Unsupported document_type to search ' + document_type);
            }
        },
    },
};
