
import {defineComponent} from "vue";
import PharmacyInfoField from "@/components/layouts/pharmacy/PharmacyInfoField.vue";

export default defineComponent({
  name: "PharmacyInfoAddress",
  components: {PharmacyInfoField},
  props: {
    pharmacyInfo: {
      type: Object,
      required: true,
    }
  },
  computed: {
    street(): string {
      let street = '' as string;

      if (this.pharmacyInfo.company_address?.street?.nl) {
        street = street.concat(this.pharmacyInfo.company_address.street.nl);
      }
      if (this.pharmacyInfo.company_address?.house_number) {
        street = street.concat(' ', this.pharmacyInfo.company_address.house_number);
      }
      if (this.pharmacyInfo.company_address?.box) {
        street = street.concat(' ', this.pharmacyInfo.company_address.box);
      }

      return street;
    }
  }
});
