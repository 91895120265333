import {RestService} from "@/services/rest/RestService";
import {TextProcessingTaskAPIApi} from "@/api/pharma-tp-task";
import {
    AiOutputAltTextRestDto,
    PagedTextProcessingTaskOverviewResultsRestDto,
    PagedTextProcessingTaskSourceAndOutputOverviewResultsRestDto,
    TaskOutputFeedbackRestDto,
    TextProcessingTaskDetailRestDto,
    TextProcessingTaskSourceAndOutputCriteriaRestDto,
} from "@/api/pharma-tp-task/models";
import {
    TextProcessingTaskCriteriaRestDtoModel
} from "@/models/api/pharma-tp-task/TextProcessingTaskCriteriaRestDtoModel";

export class TextProcessingTaskRestService extends RestService {

    private apiService: TextProcessingTaskAPIApi;

    protected constructor() {
        super();

        this.apiService = new TextProcessingTaskAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().textProcessingUrl);
    }

    public async findTextProcessingTasksWithCriteria(criteria: TextProcessingTaskCriteriaRestDtoModel): Promise<PagedTextProcessingTaskOverviewResultsRestDto> {
        const response = await this.apiService.findTextProcessingTasksByTextProcessingTaskCriteriaRestDto(criteria);

        return response.data;
    }

    public async findTextProcessingTask(id: string): Promise<TextProcessingTaskDetailRestDto> {
        const response = await this.apiService.getTextProcessingTask(id);

        return response.data;
    }

    public async findTextProcessingTaskOutput(criteria: TextProcessingTaskSourceAndOutputCriteriaRestDto): Promise<PagedTextProcessingTaskSourceAndOutputOverviewResultsRestDto> {
        const response = await this.apiService
            .getTextProcessingTaskOutputOverviewByTextProcessingTaskSourceAndOutputCriteriaRestDto(criteria);

        return response.data;
    }

    public async saveTextProcessingTaskOutputFeedback(taskOutputFeedback: TaskOutputFeedbackRestDto, taskId: string, outputId: string): Promise<void> {
        await this.apiService
            .saveTextProcessingTaskOutputFeedback(taskOutputFeedback, taskId, outputId);
    }

    public async saveAlternativeText(alternativeText: string | null, taskId: string, outputId: string): Promise<void> {
        const body = {
            alternative_text: alternativeText
        } as AiOutputAltTextRestDto;

        await this.apiService
            .saveAiOutputAltText(body, taskId, outputId);
    }
}