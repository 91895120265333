
import {defineComponent} from 'vue'
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {TextProcessingTaskRestService} from "@/services/rest/tp-task/TextProcessingTaskRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {
  PagingMetadataRestDto,
  TaskOutputFeedbackRestDto,
  TextProcessingTaskDetailRestDto,
  TextProcessingTaskSourceAndOutputOverviewRestDto
} from "@/api/pharma-tp-task/models";
import {formatDateTime} from "@/helpers/functions/date";
import {arrayHasContent} from "@/helpers/functions/array";
import {InputSizeEnum} from "@/models/enum/InputSizeEnum";
import TaskRunOutput from "@/components/layouts/text-processing/TaskRunOutput.vue";
import TaskRunInput from "@/components/layouts/text-processing/TaskRunInput.vue";
import BaseModal from "@/components/UI/Modal/BaseModal.vue";
import {generateDomId} from "@/helpers/functions/string";
import TaskRunSettings from "@/components/layouts/text-processing/TaskRunSettings.vue";
import BaseModalButton from "@/components/UI/Modal/BaseModalButton.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import TaskDetailControls from "@/components/layouts/text-processing/TaskDetailControls.vue";
import {PagingPayload} from "@/models/payload/PagingPayload";
import {
  TextProcessingTaskSourceAndOutputCriteriaRestDtoModel
} from "@/models/api/pharma-tp-task/TextProcessingTaskSourceAndOutputCriteriaRestDtoModel";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputRadioBoolean from "@/components/UI/InputRadioBoolean.vue";
import InputText from "@/components/UI/InputText.vue";
import {TaskTypeEnum} from "@/api/enums/task-type-enum";

export default defineComponent({
  name: "TaskDetail",
  components: {
    InputText,
    InputNumber,
    InputRadioBoolean,
    TaskDetailControls,
    BaseModalButton, TaskRunSettings, BaseModal, TaskRunInput, TaskRunOutput, AlertError2, BaseSpinner, BaseTitle
  },
  data() {
    return {
      id: this.$route.params.id as string,

      paramsModalId: generateDomId('paramsModalId-'),

      taskDetail: {} as TextProcessingTaskDetailRestDto,
      taskSourceAndOutputOverview: [] as TextProcessingTaskSourceAndOutputOverviewRestDto[],
      taskCriteria: TextProcessingTaskSourceAndOutputCriteriaRestDtoModel.createWithDefaults(),
      taskPaging: PagingPayload.createWithDefaults(),
      taskPagingMetadata: {} as PagingMetadataRestDto,
      taskDetailUI: UIStateDto.createWithDefaults(),
      taskOutputUI: UIStateDto.createWithDefaults(),

      selectedTaskRuns: [] as string[],
    }
  },
  mounted() {
    this.selectedTaskRuns = [this.id];
    this.taskCriteria.id = this.id;

    this.reloadContent();
  },
  computed: {
    TaskTypeEnum() {
      return TaskTypeEnum
    },
    InputSizeEnum() {
      return InputSizeEnum
    },
    hasParams(): boolean {
      return (!!this.taskDetail?.ai_api_parameters || !!this.taskDetail?.ai_prompt_template)
          || (this.hasSubtasks && (!!this.taskDetail?.subtasks[0]?.ai_api_parameters || !!this.taskDetail?.subtasks[0]?.ai_prompt_template));
    },
    hasSubtasks(): boolean {
      return arrayHasContent(this.taskDetail.subtasks);
    },
    hasTasks(): boolean {
      return arrayHasContent(this.taskSourceAndOutputOverview);
    },
  },
  methods: {
    formatDateTime,
    resetCriteria(): void {
      this.taskCriteria = TextProcessingTaskSourceAndOutputCriteriaRestDtoModel.createWithDefaults();
      this.taskCriteria.page = this.taskPaging.number;
      this.taskCriteria.page_size = this.taskPaging.size;
    },
    async findTextProcessingTask(): Promise<void> {
      this.taskDetailUI.setIsReady(false);

      try {
        this.taskDetail = await TextProcessingTaskRestService.getInstance()
            .findTextProcessingTask(this.id);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.taskDetailUI.setIsReady(true);
      }
    },
    async findTextProcessingTaskOutput(): Promise<void> {
      this.taskOutputUI.setIsReady(false);

      try {
        this.taskCriteria.page = this.taskPaging.number;
        this.taskCriteria.page_size = this.taskPaging.size;

        const taskDetailOutput = await TextProcessingTaskRestService.getInstance()
            .findTextProcessingTaskOutput(this.taskCriteria);
        this.taskPagingMetadata = taskDetailOutput.paging_metadata;
        this.taskSourceAndOutputOverview = taskDetailOutput.results;
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.taskOutputUI.setIsReady(true);
      }
    },
    async onControlsChanged(payload: PagingPayload): Promise<void> {
      this.taskDetailUI.clearError();

      try {
        this.taskPaging = payload;
        await this.findTextProcessingTaskOutput();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async reloadContent(): Promise<void> {
      this.taskDetailUI.clearError();

      await Promise.all([
        this.findTextProcessingTask(),
        this.findTextProcessingTaskOutput()
      ]);
    },
    async submitAlternativeText(alternativeText: string, taskId: string, outputId: string): Promise<void> {
      this.taskDetailUI.clearError();

      try {
        await TextProcessingTaskRestService.getInstance()
            .saveAlternativeText(alternativeText, taskId, outputId);

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    async submitFeedback(taskOutputFeedback: TaskOutputFeedbackRestDto, taskId: string, outputId: string): Promise<void> {
      this.taskDetailUI.clearError();

      try {
        await TextProcessingTaskRestService.getInstance()
            .saveTextProcessingTaskOutputFeedback(taskOutputFeedback, taskId, outputId);

        await handleSavedSuccessfully();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.taskDetailUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.taskDetailUI as UIStateDto))
          .catch(exceptions, true);
    }
  }
})
