import {MultilingualFieldRestDto} from "@/api/pharma-cms-content-block/models";

export class MultilingualRestDtoModel implements MultilingualFieldRestDto {
    de?: string|null;
    en?: string|null;
    fr?: string|null;
    nl?: string|null;

    constructor(de: string|null, en: string|null, fr: string|null, nl: string|null) {
        this.de = de;
        this.en = en;
        this.fr = fr;
        this.nl = nl;
    }

    public static createWithDefaults(): MultilingualRestDtoModel {
        return new MultilingualRestDtoModel(null, null, null, null);
    }
}
