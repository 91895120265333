
import {defineComponent} from "vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputText from "@/components/UI/InputText.vue";
import {DpModuleHelper} from "@/helpers/DpModuleHelper";
import {mapGetters} from "vuex";
import {NameValuePairCriteriaPayload} from "@/models/payload/NameValuePairCriteriaPayload";

export default defineComponent({
    name: "NameValuePairCriteria",
    components: {InputText, InputSelect},
    emits: ["search"],
    computed: {
        ...mapGetters('nvp', ["getNvpCriteriaPayload"]),
        nvpCriteriaPayload(): NameValuePairCriteriaPayload {
            return this.getNvpCriteriaPayload;
        },
        DpModuleHelper() {
            return DpModuleHelper
        }
    }
});
