import {DpNameValuePairDetailRestDto} from "@/api/pharma-master-nvp/models";
import {TypeEnum} from "@/api/enums/type-enum";

export class DpNameValuePairDetailRestDtoModel implements DpNameValuePairDetailRestDto {
    name: string;
    description?: string | null;
    value?: string | null;
    required: boolean;
    pharmacy_code_required: boolean;
    pharmacy_code_allowed: boolean;
    customer_code_required: boolean;
    customer_code_allowed: boolean;
    type_code: TypeEnum;

    constructor(name: string, required: boolean, pharmacy_code_required: boolean, pharmacy_code_allowed: boolean, customer_code_required: boolean, customer_code_allowed: boolean, type_code: TypeEnum) {
        this.name = name;
        this.required = required;
        this.pharmacy_code_required = pharmacy_code_required;
        this.pharmacy_code_allowed = pharmacy_code_allowed;
        this.customer_code_required = customer_code_required;
        this.customer_code_allowed = customer_code_allowed;
        this.type_code = type_code;
    }

    public static createWithDefaults(): DpNameValuePairDetailRestDtoModel {
        const model = new DpNameValuePairDetailRestDtoModel(
            'NAME',
            false,
            false,
            true,
            false,
            true,
            TypeEnum.STRING
        );
        model.description = null;
        model.value = null;

        return model;
    }
}