import {Singleton} from "@/patterns/Singleton";
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {createInstance} from "@/helpers/axios";
import {registerErrorInterceptor} from "@/helpers/interceptors/response/error-interceptor";

export default class Axios extends Singleton {
    private readonly axiosInstance: AxiosInstance;
    private readonly dpAxiosInstance: AxiosInstance;

    protected constructor() {
        super();

        this.axiosInstance = this.createInstance();
        this.dpAxiosInstance = createInstance();
    }

    public getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }

    public getDpAxiosInstance(): AxiosInstance {
        return this.dpAxiosInstance;
    }

    protected createInstance(config?: AxiosRequestConfig): AxiosInstance {
        const instance = axios.create(config);

        registerErrorInterceptor(instance);

        instance.defaults.headers.common['Content-Type'] = 'application/json';

        return instance;
    }
}