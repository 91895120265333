
import {defineComponent} from "vue";
import {PharmacistUserRestDtoModel} from "@/models/PharmacistUserRestDtoModel";
import {IdmPharmacistUserRestService} from "@/services/rest/pharma-idm/IdmPharmacistUserRestService";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {formatDateTime} from "@/helpers/functions/date";
import {generateDomId} from "@/helpers/functions/string";
import {Modal} from "bootstrap";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import CustomerFormContainer from "@/components/layouts/customer/CustomerFormContainer.vue";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmModal from "@/components/UI/ConfirmModal.vue";

export default defineComponent({
  name: "PharmacistUserDetail",
  components: {AlertError2, CustomerFormContainer, BaseTitle, BaseSpinner},
  props: ["mode"],
  data: () => ({
    setPasswordModal: null as Modal | null,
    setPasswordModalId: generateDomId('modal-'),
    setPasswordPassword: '' as string,
    setPasswordModalUI: UIStateDto.createWithDefaults(),

    pharmacistUser: PharmacistUserRestDtoModel.createWithDefaults(),
    pharmacistUserUI: UIStateDto.createWithDefaults(),
  }),
  computed: {
    customerCode(): any {
      return this.$route.params.customer_code;
    },
    pharmacistUserId(): any {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.setPasswordModal = Modal.getOrCreateInstance('#' + this.setPasswordModalId);

    this.reloadContent();
  },
  methods: {
    formatDateTime,
    async reloadContent(): Promise<void> {
      this.pharmacistUserUI.setIsReady(false);

      try {
        this.pharmacistUser = await IdmPharmacistUserRestService.getInstance()
            .getPharmacistUser(this.pharmacistUserId);
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacistUserUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.pharmacistUserUI.setIsReady(true);
      }
    },
    openSetPasswordModal(): void {
      this.setPasswordModal?.show();
    },
    async updatePassword(): Promise<void> {
      this.setPasswordModalUI.clearError();

      try {
        await IdmPharmacistUserRestService.getInstance().updatePharmacistUserPassword(this.pharmacistUserId, this.setPasswordPassword);
        await handleSavedSuccessfully();

        this.setPasswordModal?.hide();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.setPasswordModalUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async enableUser(): Promise<void> {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: 'Enable pharmacist user',
        body: 'Are you sure you want to enable pharmacist user <strong>' + this.pharmacistUser.first_name + ' ' + this.pharmacistUser.last_name + '</strong>?',
        confirmButtonClass: 'btn-success',
        confirmButtonText: 'Enable',
      });

      onConfirm(async () => {
        this.pharmacistUserUI
            .clearError()
            .setNotReady();

        try {
          await IdmPharmacistUserRestService.getInstance().enablePharmacistUser(this.pharmacistUser.id ?? '');
          await handleSavedSuccessfully();
        } catch (exceptions: unknown) {
          ErrorHandlerQueue
              .create()
              .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacistUserUI as UIStateDto))
              .catch(exceptions, true);
        }

        await this.reloadContent();
      });

      await reveal();
    },
    async disableUser(): Promise<void> {
      const {reveal, onConfirm} = createConfirmDialog(ConfirmModal, {
        title: 'Disable pharmacist user',
        body: 'Are you sure you want to disable pharmacist user <strong>' + this.pharmacistUser.first_name + ' ' + this.pharmacistUser.last_name + '</strong>?',
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'Disable',
      });

      onConfirm(async () => {
        this.pharmacistUserUI
            .clearError()
            .setNotReady();

        try {
          await IdmPharmacistUserRestService.getInstance().disablePharmacistUser(this.pharmacistUser.id ?? '');
          await handleSavedSuccessfully();
        } catch (exceptions: unknown) {
          ErrorHandlerQueue
              .create()
              .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacistUserUI as UIStateDto))
              .catch(exceptions, true);
        }

        await this.reloadContent();
      });

      await reveal();
    }
  }
});
