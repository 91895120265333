import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5421f4e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check form-switch mb-1" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dp_field = _resolveComponent("dp-field")!
  const _component_dp_error_message = _resolveComponent("dp-error-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dp_field, {
      type: "checkbox",
      role: "switch",
      value: true,
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      name: _ctx.name,
      id: _ctx.name,
      class: _normalizeClass(['form-check-input', { 'is-invalid': _ctx.errors[_ctx.name] }]),
      disabled: _ctx.disabled,
      rules: _ctx.rules
    }, null, 8, ["modelValue", "name", "id", "class", "disabled", "rules"]),
    _createVNode(_component_dp_error_message, {
      class: "invalid-feedback d-block",
      name: _ctx.name
    }, null, 8, ["name"]),
    _createElementVNode("label", {
      for: _ctx.name,
      class: "form-label form-check-label mb-0"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2)
  ]))
}