
import {defineComponent} from 'vue';
import CustomerFormContainer from "@/components/layouts/customer/CustomerFormContainer.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import InputText from "@/components/UI/InputText.vue";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/AlertError2.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {DomainSettingsRestService} from "@/services/rest/pharma-customer/DomainSettingsRestService";
import {CustomerDomainRestDtoModel} from "@/models/api/pharma-customer/CustomerDomainRestDtoModel";

export default defineComponent({
    name: "CustomerDomainForm",
    components: {InputCheckboxBoolean, AlertError2, InputText, BaseSpinner, BaseTitle, CustomerFormContainer},
    props: ['mode'],
    data() {
        return {
            customerCode: this.$route.params.customer_code as string,
            domainId: this.$route.params.domain_id as string,

            domain: CustomerDomainRestDtoModel.createWithDefaults(),
            domainUI: UIStateDto.createWithDefaults(),
        }
    },
    mounted() {
        this.reloadContent();
    },
    computed: {
        PageDetailModeEnum() {
            return PageDetailModeEnum
        },
    },
    methods: {
        async reloadContent(): Promise<void> {
            this.domainUI
                .setNotReady()
                .clearError();

            try {
                if (this.mode === PageDetailModeEnum.EDIT) {
                    this.domain = await DomainSettingsRestService.getInstance()
                        .findCustomerDomain(this.customerCode, this.domainId);
                }
            } catch (exceptions: unknown) {
                this.handleExceptions(exceptions);
            } finally {
                this.domainUI.setReady();
            }
        },
        async submitData(): Promise<void> {
            this.domainUI.clearError();

            try {
                if (this.mode === PageDetailModeEnum.NEW) {
                    await DomainSettingsRestService.getInstance()
                        .createCustomerDomain(this.domain, this.customerCode);
                } else {
                    await DomainSettingsRestService.getInstance()
                        .updateCustomerDomain(this.domain, this.customerCode, this.domainId);
                }

                await handleSavedSuccessfully();

                await this.$router.push({
                    name: 'edit-customer', params: {id: this.customerCode}
                });
            } catch (exceptions: unknown) {
                this.handleExceptions(exceptions);
            }
        },
        async deleteDomain() {
            this.domainUI.clearError();

            try {
                const answer = window.confirm(this.$t('deleteConfirmation'));
                if (!answer) {
                    return
                } else {
                    await DomainSettingsRestService.getInstance()
                        .deleteCustomerDomain(this.customerCode, this.domainId);
                    await handleSavedSuccessfully('Successfully deleted');

                    await this.$router.push({
                        name: 'edit-customer', params: {id: this.customerCode}
                    });
                }
            } catch (exceptions: unknown) {
                this.handleExceptions(exceptions);
            }
        },
        handleExceptions(exceptions: unknown) {
            ErrorHandlerQueue
                .create()
                .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.domainUI as UIStateDto))
                .catch(exceptions, true);
        }
    }
});
