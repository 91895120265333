import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion-item" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["data-bs-target", "aria-expanded", "aria-controls"]
const _hoisted_4 = ["id", "aria-labelledby", "data-bs-parent"]
const _hoisted_5 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      class: "accordion-header",
      id: _ctx.headingId
    }, [
      _createElementVNode("button", {
        class: _normalizeClass(['accordion-button bg-transparent fs-5', _ctx.collapsed ? 'collapsed' : '']),
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": '#' + _ctx.collapseId,
        "aria-expanded": _ctx.collapsed ? 'false' : 'true',
        "aria-controls": _ctx.collapseId
      }, _toDisplayString(_ctx.title), 11, _hoisted_3)
    ], 8, _hoisted_2),
    _createElementVNode("div", {
      id: _ctx.collapseId,
      class: _normalizeClass(['accordion-collapse collapse', _ctx.collapsed ? '' : 'show']),
      "aria-labelledby": _ctx.headingId,
      "data-bs-parent": '#' + _ctx.parentId
    }, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 10, _hoisted_4)
  ]))
}