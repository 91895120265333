import {PharmacistUserResultsRestDto} from "@/api/pharma-idm-pharmacist-user/models";
import {PharmacistUserRestDtoModel} from "@/models/PharmacistUserRestDtoModel";

export class PharmacistUserResultsRestDtoModel implements PharmacistUserResultsRestDto {
    results: PharmacistUserRestDtoModel[];

    constructor(results: PharmacistUserRestDtoModel[]) {
        this.results = results;
    }

    public static createWithDefaults(): PharmacistUserResultsRestDtoModel {
        return new this([]);
    }
}