import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_DefaultClosingPeriods = _resolveComponent("DefaultClosingPeriods")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseTitle, {
      title: "Standaard sluitingsperiodes toevoegen voor alle customers",
      "help-text": "Als je op deze toevoegt, zullen de sluitingsdagen van de customer ‘90-Content Provider Digital Pharma’ gekopieerd en toegevoegd worden aan alle customers. We overlopen hierbij alle jaren vanaf het huidig kalenderjaar tem het gekozen ‘tot’-jaar, voor ieder jaar waarvoor er voor een customer nog geen énkel sluitingsperiode gevonden wordt, worden de sluitingsperiodes van de content provider overgenomen.",
      mb: 3
    }),
    _createVNode(_component_DefaultClosingPeriods)
  ]))
}