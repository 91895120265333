import {RestService} from "@/services/rest/RestService";
import {CustomerDomainSettingsAPIApi} from "@/api/pharma-customer-settings";
import {
    CustomerDomainOverviewRestDto,
    CustomerDomainRestDto,
    LanguageSettingsRestDto
} from "@/api/pharma-customer-settings/models";
import {CustomerDomainRestDtoModel} from "@/models/api/pharma-customer/CustomerDomainRestDtoModel";
import {DpExceptions} from "@/api/models/DpExceptions";
import {Exception} from "@/api/interfaces";

export class DomainSettingsRestService extends RestService {

    private apiService: CustomerDomainSettingsAPIApi;

    protected constructor() {
        super();

        this.apiService = new CustomerDomainSettingsAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().customerUrl);
    }

    public async findCustomerDomainOverview(x_dp_customer_code: string): Promise<CustomerDomainOverviewRestDto[]> {
        const response = await this.apiService.findCustomerDomain(x_dp_customer_code);

        return response.data;
    }

    public async findCustomerDomain(x_dp_customer_code: string, id: string): Promise<CustomerDomainRestDto> {
        const customerDomains = await this.findCustomerDomainOverview(x_dp_customer_code) as CustomerDomainOverviewRestDto[];
        const domain = customerDomains
            .find((customerDomain: CustomerDomainOverviewRestDto) => customerDomain.id === id);

        if (domain) {
            return new CustomerDomainRestDtoModel(domain.domain_url, domain.is_active, domain.is_test);
        }

        throw new DpExceptions([
            {message: 'No domain found with id ' + id} as Exception
        ]);
    }

    public async createCustomerDomain(body: CustomerDomainRestDto, x_dp_customer_code: string): Promise<void> {
        await this.apiService.createCustomerDomain(body, x_dp_customer_code);
    }

    public async deleteCustomerDomain(x_dp_customer_code: string, id: string): Promise<void> {
        await this.apiService.deleteCustomerDomain(x_dp_customer_code, id);
    }

    public async updateCustomerDomain(body: CustomerDomainRestDto, x_dp_customer_code: string, id: string): Promise<void> {
        await this.apiService.updateCustomerDomain(body, x_dp_customer_code, id);
    }
}