import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "bi bi-caret-left-fill fs-5 lh-1"
}
const _hoisted_2 = {
  key: 2,
  class: "bi bi-caret-right-fill fs-5 lh-1"
}
const _hoisted_3 = {
  key: 3,
  class: "bi bi-three-dots fs-5 lh-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["page-item", _ctx.pageItemClasses])
  }, [
    _createElementVNode("a", {
      class: "page-link d-flex justify-content-center align-items-center h-100",
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePage && _ctx.changePage(...args)), ["prevent"]))
    }, [
      (_ctx.pageNumber)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(_ctx.pageNumber), 1)
          ], 64))
        : (_ctx.isFirst)
          ? (_openBlock(), _createElementBlock("i", _hoisted_1))
          : (_ctx.isLast)
            ? (_openBlock(), _createElementBlock("i", _hoisted_2))
            : (_openBlock(), _createElementBlock("i", _hoisted_3))
    ])
  ], 2))
}