<script>
import AbstractInputControl from "@/components/UI/AbstractInputControl";

export default {
  extends: AbstractInputControl,
  methods: {
    determineInputType() {
      return 'text';
    },
    determineRules() {
      return [];
    },
  }
};
</script>
