import {POSITION, useToast} from "vue-toastification";

function handleError(message: unknown): void {
    const toast = useToast();

    toast.error(String(message), {
        position: POSITION.TOP_CENTER,
    });
}

async function handleSavedSuccessfully(message?: string): Promise<void> {
    const toast = useToast();

    if (message === undefined) {
        message = 'Saved successfully.';
    }

    toast.success(message, {
        position: POSITION.TOP_RIGHT,
    });
}

export {
    handleError,
    handleSavedSuccessfully,
};