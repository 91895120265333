<template>
  <i class="bi bi-circle-fill"
     :style="this.isActive ? 'color:#198754' : 'color:#dc3545'"
  ></i>
</template>

<script>
export default {
  props: {
    isActive: Boolean
  }
}
</script>
