
import {defineComponent} from "vue";
import {createInstance} from '@/helpers/axios';
import {InputSizeEnum} from "@/models/enum/InputSizeEnum";

export default defineComponent({
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: [String, Number],
    },
    options: {
      type: Array,
      required: false,
      default: null,
      validator(options: any) {
        // validate that each option has a key 'code' and 'description'
        return options.every((o: any) => 'code' in o && 'description' in o);
      },
    },
    optionsMap: {
      type: Map,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
    },
    errors: {
      type: Object,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    help: {
      type: String,
      required: false,
      default: null
    },
    inputSize: {
      type: String,
      required: false,
      default: null
    },
    rules: {
      type: String,
    },
    selectText: {
      type: String,
      required: false,
      default: "Select a value"
    },
    url: {
      type: String,
    },

    isFloating: {
      type: Boolean,
      required: false,
      default: false
    },
    isInputGroup: {
      type: Boolean,
      required: false,
      default: false
    },
    showCode: {
      type: Boolean,
      required: false,
      default: false
    },
    firstEmptyItemDisabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    enums: {
      inputSizeEnum: InputSizeEnum,
    },
    fetchedOptions: [],
  }),
  async mounted() {
    if (this.url) {
      const axios = createInstance();
      const response = await axios.get(this.url);
      this.fetchedOptions = response.data;
    }
  },
  computed: {
    selectOptions() {
      return this.url ? this.fetchedOptions : (this.options ?? this.optionsMap);
    },
  },
  methods: {
    updateValue(value: any) {
      this.$emit('update:modelValue', value);
    },
  },
});
