import axios from 'axios';
import { registerErrorInterceptor } from '@/helpers/interceptors/response/error-interceptor';
import { registerAuthInterceptor } from '@/helpers/interceptors/request/auth-interceptor';
import {registerDpLanguageInterceptor} from "@/helpers/interceptors/request/x-dp-language-interceptor";

export const createInstance = (config) => {
  const instance = axios.create(config);

  registerErrorInterceptor(instance);
  registerDpLanguageInterceptor(instance);
  registerAuthInterceptor(instance);

  instance.defaults.headers.common['Content-Type'] = 'application/json';

  return instance;
};