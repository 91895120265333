<template>
  <div class="overlay position-absolute w-100 h-100 indec"
       :data-bs-toggle="tooltipText ? 'tooltip' : undefined"
       :data-bs-placement="tooltipText ? tooltipPlacement : undefined"
       :title="tooltipText"
  ></div>
</template>

<script>
export default {
  name: "BaseOverlay",
  props: {
    tooltipPlacement: {
      type: String,
      required: false,
      default: 'top',
    },
    tooltipText: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.overlay {
  background: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
</style>