import configFile from '../public/config.json';
import {config, fetchConfig} from '@/helpers/fetch-config';

// Load config in to local storage
fetchConfig().then((loadedConfig) => {
    if (!config || JSON.stringify(config) !== JSON.stringify(loadedConfig)) {
        localStorage.setItem('config', JSON.stringify(loadedConfig));
        location.reload();
    }
});

import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';

import 'bootstrap/dist/js/bootstrap.esm';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@/assets/scss/main.scss';

import store from '@/store/index.js';
import router from '@/router/router.js';
import axios from 'axios';
import * as ConfirmDialog from 'vuejs-confirm-dialog';
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import 'sweetalert2/dist/sweetalert2.min.css';

import messages_nl from '@/assets/messages/nl.json';
import messages_en from '@/assets/messages/en.json';
import messages_fr from '@/assets/messages/fr.json';

import {defineRule, configure, Field, Form, ErrorMessage} from 'vee-validate';
import {localize, setLocale} from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import {required, max, email, min} from '@vee-validate/rules';

import App from '@/App.vue';

import AlertError from '@/components/UI/AlertError.vue';

import BaseAccordion from '@/components/UI/Accordion/BaseAccordion.vue';
import BaseAccordionItem from '@/components/UI/Accordion/BaseAccordionItem.vue';

import BaseCard from '@/components/UI/Card/BaseCard.vue';
import BaseCardBody from '@/components/UI/Card/BaseCardBody.vue';
import BaseCardFooter from '@/components/UI/Card/BaseCardFooter.vue';
import BaseCardHeader from '@/components/UI/Card/BaseCardHeader.vue';
import BaseCardImage from '@/components/UI/Card/BaseCardImage.vue';

import BaseButton from '@/components/UI/BaseButton.vue';
import BaseDialog from '@/components/UI/BaseDialog.vue';
import BaseModal from '@/components/UI/Modal/BaseModal.vue';
import BaseModalButton from '@/components/UI/Modal/BaseModalButton.vue';
import BaseOverlay from '@/components/UI/BaseOverlay.vue';
import BaseSpinner from '@/components/UI/BaseSpinner.vue';

import LocaleChanger from '@/components/UI/LocaleChanger.vue';
import InputText from '@/components/UI/InputText.vue';
import InputSelect from '@/components/UI/InputSelect.vue';
import InputFile from '@/components/UI/InputFile.vue';
import InputNumber from '@/components/UI/InputNumber.vue';
import InputRadio from '@/components/UI/InputRadio.vue';
import InputRadioBoolean from '@/components/UI/InputRadioBoolean.vue';
import InputEmail from '@/components/UI/InputEmail.vue';
import InputDate from "@/components/UI/InputDate.vue";

import PharmacyInfo from "@/components/layouts/pharmacy/PharmacyInfo.vue";
import PharmacyInfoAddress from "@/components/layouts/pharmacy/PharmacyInfoAddress.vue";
import PharmacyInfoField from "@/components/layouts/pharmacy/PharmacyInfoField.vue";

import masterEnum from '@/store/pharma-master-enum/axios-pharma-master-enum';


axios.defaults.headers.get['Accept'] = 'application/json';

import {SimpleContentBlockEndpointsApi, ContentBlockEndpointsApi} from '@/api/pharma-cms-content-block';
import {DocumentAPIApi} from "@/api/pharma-cms-document";
import {PharmacyInformationEndpointsApi} from '@/api/pharma-cms-pharmacy';
import {CustomerCRUDApi} from '@/api/pharma-customer';
import {LicenseInformationEnumEndpointsApi, ProductFeatureEndpointsApi} from "@/api/pharma-license-license-info";
import {ProductLicenseEndpointsApi} from "@/api/pharma-master-license-mgmt";
import {JobAPIApi} from '@/api/pharma-monitoring';
import {OrderSettingsAPIApi} from '@/api/pharma-order-settings';

import cmsContentBlockImport from "@/store/pharma-cms-content-block/axios-pharma-cms-content-block";
import cmsDocumentImport from '@/store/pharma-cms-document/axios-pharma-cms-document';
import cmsPharmacyImport from '@/store/pharma-cms-pharmacy/axios-pharma-cms-pharmacy';
import customerImport from "@/store/pharma-customer/axios-pharma-customer";
import licenseInfoImport from "@/store/pharma-license-license-info/axios-pharma-license-license-info";
import masterLicenseImport from "@/store/pharma-master-license-mgmt/axios-pharma-master-license-mgmt";
import monitoringImport from "@/store/pharma-monitoring/axios-pharma-monitoring";
import orderSettingsImport from "@/store/pharma-order-settings/axios-pharma-order-settings";
// import {BASE_PATH} from "@/api/pharma-customer/base";

const cmsContentBlockInstance = cmsContentBlockImport;
const cmsDocumentInstance = cmsDocumentImport;
const cmsPharmacyInstance = cmsPharmacyImport;
const customerInstance = customerImport;
const licenseInfoInstance = licenseInfoImport;
const masterLicenseInstance = masterLicenseImport;
const monitoringInstance = monitoringImport;
const orderSettingsInstance = orderSettingsImport;
const masterEnumInstance = masterEnum;

export const SimpleContentBlockApiService = new SimpleContentBlockEndpointsApi(cmsContentBlockInstance, undefined, configFile.cmsUrl);
export const ContentBlockApiService = new ContentBlockEndpointsApi(cmsContentBlockInstance, undefined, configFile.cmsUrl);
export const DocumentApiService = new DocumentAPIApi(cmsDocumentInstance, undefined, configFile.cmsUrl);
export const PharmacyInformationApiService = new PharmacyInformationEndpointsApi(cmsPharmacyInstance, undefined, configFile.cmsUrl);
export const CustomerCrudApiService = new CustomerCRUDApi(customerInstance, undefined, configFile.customerUrl);
export const LicenseInformationEnumApiService = new LicenseInformationEnumEndpointsApi(licenseInfoInstance, undefined, configFile.licenseUrl);
export const ProductFeatureApiService = new ProductFeatureEndpointsApi(licenseInfoInstance, undefined, configFile.licenseUrl);
export const ProductLicenseApiService = new ProductLicenseEndpointsApi(masterLicenseInstance, undefined, configFile.masterUrl);
export const JobRunApiService = new JobAPIApi(monitoringInstance, undefined, configFile.monitoringUrl);
export const OrderSettingsApiService = new OrderSettingsAPIApi(orderSettingsInstance, undefined, configFile.orderUrl);
export const MasterEnumEndpointsApiService = new EnumEndpointsApi(masterEnumInstance, undefined, configFile.masterUrl);

const app = createApp(App);

// Docs - https://vue-i18n.intlify.dev/
const i18n = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages: {
        nl: messages_nl,
        en: messages_en,
        fr: messages_fr,
    },
});

app.use(router);
app.use(store);
app.use(i18n);
app.use(ConfirmDialog);

import {handleError} from "@/helpers/toast-helper";
import {handleDpExceptions} from "@/helpers/exception-helper";
import {DpExceptions} from "@/api/models/DpExceptions";

app.use(Toast, {
    container: document.querySelector('body'),
    position: POSITION.TOP_RIGHT,
    timeout: 5000
});

app.component('alert-error', AlertError);

app.component('base-accordion', BaseAccordion);
app.component('base-accordion-item', BaseAccordionItem);

app.component('base-card', BaseCard);
app.component('base-card-body', BaseCardBody);
app.component('base-card-footer', BaseCardFooter);
app.component('base-card-header', BaseCardHeader);
app.component('base-card-image', BaseCardImage);
app.component('base-button', BaseButton);
app.component('base-dialog', BaseDialog);
app.component('base-modal', BaseModal);
app.component('base-modal-button', BaseModalButton);
app.component('base-overlay', BaseOverlay);
app.component('base-spinner', BaseSpinner);

app.component('input-file', InputFile);
app.component('input-number', InputNumber);
app.component('input-text', InputText);
app.component('input-select', InputSelect);
app.component('input-radio-boolean', InputRadioBoolean);
app.component('input-radio', InputRadio);
app.component('input-email', InputEmail);
app.component('input-date', InputDate);

app.component('pharmacy-info', PharmacyInfo);
app.component('pharmacy-info-address', PharmacyInfoAddress);
app.component('pharmacy-info-field', PharmacyInfoField);

app.component('locale-changer', LocaleChanger);

// Vee-validate components
app.component('dp-form', Form);
app.component('dp-field', Field);
app.component('dp-error-message', ErrorMessage);

import {onError} from "@/helpers/toast-service";
import {EnumEndpointsApi} from "@/api/pharma-master-enum";

// Global error handler
app.config.errorHandler = (error) => {
    console.error(error);
    onError(error);
};

// Tijdelijk warnings disablen:
// app.config.warnHandler = () => null;

const vm = app.mount('#app');

// Vee-validate configuration and global validators
// Docs - https://vee-validate.logaretm.com/v4/
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);

configure({
    generateMessage: localize({en, nl, fr}),
});


setLocale('nl');

export default vm;