
import {defineComponent} from "vue";
import {formatDate} from "@/helpers/functions/date";

export default defineComponent({
  name: "BaseDateFromTill",
  props: {
    beginDate: {
      type: String,
      required: false,
      default: null
    },
    endDate: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {formatDate}
});
