
import {defineComponent, PropType} from "vue";
import BaseIconIsActive from "@/components/UI/BaseIconIsActive.vue";
import {default as Offcanvas} from 'bootstrap/js/dist/offcanvas';
import BaseDateFromTill from "@/components/UI/BaseDateFromTill.vue";
import {ProductLicenseOverviewRestDtoModel} from "@/models/ProductLicenseOverviewRestDtoModel";
import ProductLicenseFeatureOverview from "@/components/layouts/license/ProductLicenseFeatureOverview.vue";

export default defineComponent({
  name: "ProductLicenseOverview",
  emits: ["goToEdit"],
  components: {ProductLicenseFeatureOverview, BaseDateFromTill, BaseIconIsActive},
  props: {
    productLicenses: {
      type: Array as PropType<ProductLicenseOverviewRestDtoModel[]>,
      required: false
    },
    customerCode: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      activeProductLicenseCode: null as string | null,
      offcanvasProductLicenseFeatures: null as Offcanvas | null
    }
  },
  mounted() {
    this.offcanvasProductLicenseFeatures = new Offcanvas('#offcanvas-product-license-features');
  },
  unmounted() {
    this.offcanvasProductLicenseFeatures?.dispose();
  },
  methods: {
    openOffcanvasProductLicenseFeatures(productLicenseCode: string) {
      this.activeProductLicenseCode = productLicenseCode;
      this.offcanvasProductLicenseFeatures?.show();
    }
  },
});
