// Docs - https://sweetalert2.github.io/#examples
import Swal from "sweetalert2";

// Error toast
export function onError(title, message) {
  Swal.fire({
    toast: true,
    position: 'top',
    icon: 'error',
    title,
    text: message,
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    showCloseButton: true,
    width: '40%',
  });
}

// Warning toast
export function onWarn(message) {
  Swal.fire({
    toast: true,
    position: 'top',
    icon: 'warning',
    title: message,
    showConfirmButton: false,
    timer: '10000',
    timerProgressBar: true,
    showCloseButton: true,
  });
}

// Info toast
export function onInfo() {
  console.log('Not implemented');
}