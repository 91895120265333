import {
    TextProcessingTaskSourceAndOutputCriteriaRestDto
} from "@/api/pharma-tp-task/models";
import {PagingPayload} from "@/models/payload/PagingPayload";
import {generateUUIDv4} from "@/helpers/functions/string";

export class TextProcessingTaskSourceAndOutputCriteriaRestDtoModel implements TextProcessingTaskSourceAndOutputCriteriaRestDto {
    x_dp_customer_code?: string | null;
    x_dp_language?: string | null;

    page?: number | null;
    page_size?: number | null;

    id: string;
    filter_cnk_code?: number | null;
    search_source_keyword?: string | null;

    filter_feeback_score_from?: number|null;
    filter_feeback_score_till?: number|null;

    filter_is_alt_text_available?: boolean|null;
    filter_is_feedback_note_available?: boolean|null;
    filter_is_feedback_score_available?: boolean|null;
    filter_is_on_hold?: boolean|null;

    constructor(id: string, page: number, page_size: number) {
        this.id = id;
        this.page = page;
        this.page_size = page_size;
    }

    public static createWithDefaults(): TextProcessingTaskSourceAndOutputCriteriaRestDtoModel {
        const defaultPaging = PagingPayload.createWithDefaults();
        return new this(generateUUIDv4(), defaultPaging.number, defaultPaging.size);
    }
}