import {
    ProductLicenseOverviewRestDto,
    ProductLicenseRestDto,
} from "@/api/pharma-master-license-mgmt/models";
import {ProductLicenseApiService} from "@/main";

export default {
    namespaced: true,
    state() {
        return {
            product_license: {} as ProductLicenseRestDto,
            product_license_overview: {} as ProductLicenseOverviewRestDto,
        }
    },
    actions: {
        async searchProductLicense(context: any, id: string) {
            const response = await ProductLicenseApiService.findProductLicense(id);

            context.commit("setProductLicense", response.data);
        },
        async searchProductLicenseOverview(context: any, payload: any) {
            const response = await ProductLicenseApiService.findCustomerProductLicenses(payload.customer_code, payload?.filter_is_active);

            context.commit("setProductLicenseOverview", response.data);
        },
        async createProductLicense(context: any, payload: any) {
            await ProductLicenseApiService.createProductLicense(payload.body as ProductLicenseRestDto, payload.customer_code);
        },
        async updateProductLicense(context: any, payload: any) {
            await ProductLicenseApiService.updateProductLicense(payload.body as ProductLicenseRestDto, payload.id);
        },
    },
    mutations: {
        setProductLicense(state: any, payload: ProductLicenseRestDto) {
            state.product_license = payload;
        },
        setProductLicenseOverview(state: any, payload: ProductLicenseOverviewRestDto) {
            state.product_license_overview = payload;
        }
    },
    getters: {
        getProductLicense(state: any): ProductLicenseRestDto {
            return state.product_license;
        },
        getProductLicenseOverview(state: any): ProductLicenseOverviewRestDto {
            return state.product_license_overview;
        }
    }
}