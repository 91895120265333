import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c1ab112"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row my-2" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_text = _resolveComponent("input-text")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_input_number = _resolveComponent("input-number")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createBlock(_component_dp_form, {
    as: "form",
    onSubmit: _ctx.submitData,
    novalidate: ""
  }, {
    default: _withCtx(({ errors }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_input_text, {
            label: "Jobname",
            name: "jobName",
            modelValue: _ctx.criteria.searchJobName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.criteria.searchJobName) = $event)),
            modelModifiers: { trim: true },
            errors: errors
          }, null, 8, ["modelValue", "errors"])
        ]),
        _createVNode(_component_input_select, {
          class: "col",
          name: "status",
          label: _ctx.$t('status'),
          selectText: _ctx.$t('selectText'),
          modelValue: _ctx.criteria.filterStatus,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.criteria.filterStatus) = $event)),
          options: _ctx.statusOptions,
          url: _ctx.config.jobStatusUrl,
          errors: errors
        }, null, 8, ["label", "selectText", "modelValue", "options", "url", "errors"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_input_select, {
          class: "col col-xl-2",
          name: "maxRecords",
          label: _ctx.$t('maxRecords'),
          selectText: _ctx.$t('selectText'),
          modelValue: _ctx.criteria.filterMaxRecords,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.criteria.filterMaxRecords) = $event)),
          options: _ctx.maxNumberOfRecordsOptions,
          errors: errors
        }, null, 8, ["label", "selectText", "modelValue", "options", "errors"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_input_number, {
            label: _ctx.$t('fromDaysBack'),
            name: "daysBackStart",
            modelValue: _ctx.criteria.filterDaysBackStart,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.criteria.filterDaysBackStart) = $event)),
            errors: errors
          }, null, 8, ["label", "modelValue", "errors"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_input_number, {
            label: _ctx.$t('tillDaysBack'),
            name: "daysBackEnd",
            modelValue: _ctx.criteria.filterDaysBackEnd,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.criteria.filterDaysBackEnd) = $event)),
            errors: errors
          }, null, 8, ["label", "modelValue", "errors"])
        ])
      ]),
      _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t('search')), 1),
      _createElementVNode("a", {
        class: "ms-2 btn btn-outline-primary btn-md",
        type: "button",
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.clearCriteria && _ctx.clearCriteria(...args)))
      }, _toDisplayString(_ctx.$t("resetFilters")), 1)
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}