
import {defineComponent, PropType} from 'vue'
import {TextProcessingTaskDetailRestDto} from "@/api/pharma-tp-task/models";
import {TaskTypeEnum} from "@/api/enums/task-type-enum";
import {UtilityHelper} from "@/helpers/UtilityHelper";

export default defineComponent({
  name: "TaskRunSettings",
  props: {
    taskDetail: {
      type: Object as PropType<TextProcessingTaskDetailRestDto>,
      required: true
    },
    taskRun: {
      type: Number,
      required: true
    },
  },
  computed: {
    TaskTypeEnum() {
      return TaskTypeEnum
    },
    UtilityHelper() {
      return UtilityHelper
    },
  }
})
