import {PharmacistUserRestDto} from "@/api/pharma-idm-pharmacist-user/models";
import {PharmacistUserStatusEnum} from "@/api/enums/pharmacist-user-status-enum";

export class PharmacistUserRestDtoModel implements PharmacistUserRestDto {
    created: string | null;
    cug_code: string | null;
    customer_codes: string[] | null;
    customer_user_roles: string[] | null;
    email: string | null;
    first_name: string | null;
    id: string | null;
    is_email_verified: boolean | null;
    is_enabled: boolean | null;
    last_name: string | null;
    modified: string | null;
    phone: string | null;
    status: PharmacistUserStatusEnum | null;

    constructor(created: string | null, cug_code: string | null, customer_codes: string[] | null, customer_user_roles: string[] | null, email: string | null, first_name: string | null, id: string | null, is_email_verified: boolean | null, is_enabled: boolean | null, last_name: string | null, modified: string | null, phone: string | null, status: PharmacistUserStatusEnum | null) {
        this.created = created;
        this.cug_code = cug_code;
        this.customer_codes = customer_codes;
        this.customer_user_roles = customer_user_roles;
        this.email = email;
        this.first_name = first_name;
        this.id = id;
        this.is_email_verified = is_email_verified;
        this.is_enabled = is_enabled;
        this.last_name = last_name;
        this.modified = modified;
        this.phone = phone;
        this.status = status;
    }

    public static createWithDefaults(): PharmacistUserRestDtoModel
    {
        return new this(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
    }
}