import {CreatePharmacistUserRestDto} from "@/api/pharma-idm-pharmacist-user/models";

export class CreatePharmacistUserRestDtoModel implements CreatePharmacistUserRestDto {
    first_name: string;
    last_name: string;
    email: string;
    customer_codes: string[];
    customer_user_roles: string[];

    constructor(first_name: string, last_name: string, email: string, customer_codes: string[], customer_user_roles: string[]) {
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
        this.customer_codes = customer_codes;
        this.customer_user_roles = customer_user_roles;
    }

    public static createWithDefaults(): CreatePharmacistUserRestDtoModel {
        return new CreatePharmacistUserRestDtoModel('', '', '', [], []);
    }
}