import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8d56d7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check mb-2" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dp_field = _resolveComponent("dp-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dp_field, {
      type: "radio",
      value: _ctx.value,
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      label: _ctx.label,
      name: _ctx.name,
      class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.errors[_ctx.name] }]),
      disabled: _ctx.disabled,
      rules: _ctx.rules
    }, null, 8, ["value", "modelValue", "label", "name", "class", "disabled", "rules"]),
    _createElementVNode("label", {
      for: _ctx.name,
      class: "form-check-label ps-2 mb-0"
    }, _toDisplayString(_ctx.label), 9, _hoisted_2)
  ]))
}