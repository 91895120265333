import {Context} from "@/patterns/Context";
import {OAuth2Token} from "@/dtos/OAuth2Token";
import {JwtHelper} from "@/helpers/JwtHelper";

export class AuthContext extends Context {
    private static _idTokenBody: Object|null = null;
    private static _oauth2Token: OAuth2Token|null = null;

    static get oauth2Token(): OAuth2Token | null {
        return this._oauth2Token;
    }

    static setOAuth2Token(value: OAuth2Token) {
        this._oauth2Token = value;
    }

    static getOAuth2TokenOrFail(): OAuth2Token {
        if (this._oauth2Token === null) {
            throw new Error('oauth2Token is not set');
        }

        return this._oauth2Token;
    }

    static isAuthenticated(): boolean {
        try {
            return this.getOAuth2TokenOrFail().expiresAt > (new Date).getTime();
        } catch (error: any) {
            return false;
        }
    }

    static isDpUser(): boolean {
        try {
            return this.getIdTokenProperty('custom:cug_code') === 'DP';
        } catch (error: any) {
            return false;
        }
    }

    static getFullName(): string {
        return this.getIdTokenProperty('name') + ' ' + this.getIdTokenProperty('family_name');
    }

    static getUserId(): string {
        return this.getIdTokenProperty('sub');
    }

    static getUsername(): string {
        return this.getIdTokenProperty('cognito:username');
    }

    static clear(): void {
        this._oauth2Token = null;
        this._idTokenBody = null;
    }

    protected static getIdTokenProperty(property: string): any {
        if (this._idTokenBody === null) {
            this._idTokenBody = JwtHelper.parseBodyFromJwt(this.getOAuth2TokenOrFail().idToken);
        }

        return (this._idTokenBody as any)[property];
    }
}
