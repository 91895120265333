
import {defineComponent} from 'vue';
import store from "@/store";
import CustomerFormContainer from "@/components/layouts/customer/CustomerFormContainer.vue";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertError2 from "@/components/UI/AlertError2.vue";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {PharmacyRestDtoModel} from "@/models/PharmacyRestDtoModel";
import {AddressRestDtoModel} from "@/models/AddressRestDtoModel";
import {generateDomId} from "@/helpers/functions/string";
import JsonImport from "@/components/JsonImport.vue";
import {mergeDeepJson} from "@/helpers/functions/object";

export default defineComponent({
  name: "CustomerPharmacyForm",
  components: {JsonImport, BaseSpinner, AlertError2, BaseTitle, CustomerFormContainer},
  props: ['mode'],
  data: () => ({
    pharmacyUI: UIStateDto.createWithDefaults(),
    pharmacyInfo: PharmacyRestDtoModel.createWithDefaults(),

    importModalId: generateDomId(),
  }),
  computed: {
    PageDetailModeEnum() {
      return PageDetailModeEnum;
    },
    customerCode(): string {
      return this.$route.params.customer_code as string;
    },
    pharmacyPublicAddress: {
      get() {
        return this.pharmacyInfo.public_address ? this.pharmacyInfo.public_address : AddressRestDtoModel.createWithDefaults();
      },
      set(value: any) {
        this.pharmacyInfo.public_address = value;
      },
    },
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    reloadContent(): void {
      this.pharmacyUI.setIsReady(true);
    },
    async submitData(): Promise<void> {
      this.pharmacyUI
          .setIsReady(false)
          .clearError();

      try {
        const payload = {
          pharmacy_info: this.pharmacyInfo,
          customer_code: this.customerCode,
        };
        await store.dispatch('cms_pharmacy/createPharmacyInfo', payload);
        await handleSavedSuccessfully();

        await this.$router.push({
          name: 'edit-customer',
          params: {
            id: this.$route.params.customer_code,
          },
        });
      } catch (exceptions) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacyUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.pharmacyUI.setIsReady(true);
      }
    },
    import(json: string): void {
      this.pharmacyInfo = mergeDeepJson(this.pharmacyInfo, json) as PharmacyRestDtoModel;
    }
  }
});
