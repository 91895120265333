/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Monitoring
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { JobRunEndRestDto } from '../models';
import { JobRunRestDto } from '../models';
import { JobRunStartRestDto } from '../models';
/**
 * JobAPIApi - axios parameter creator
 * @export
 */
export const JobAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint verantwoordelijk voor het registreren van het eindtijdstip van een Digital-Pharma JobRun.
         * @summary REST.API.PM.JOB.02 Registreer JobRun einde.
         * @param {JobRunEndRestDto} body 
         * @param {string} id De unieke identificatie van de JobRun.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobV1JobRunEndPut: async (body: JobRunEndRestDto, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling jobV1JobRunEndPut.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling jobV1JobRunEndPut.');
            }
            const localVarPath = `/job/v1/job-run/end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API waarmee de Digital-Pharma JobRun elementen gezocht kunnen worden. De resultaten worden gesorteerd op start_time (van recent naar oud)
         * @summary REST.API.PM.JOB.04 Zoek JobRun elementen.
         * @param {string} [filterDpCustomerCode] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {string} [filterStatus] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {string} [searchJobName] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van job_name. (Er wordt case insensitive gezocht.)
         * @param {number} [filterDaysBackStart] Filter de resultaten waarbij start_time &gt; (huidige systeemdatum - filter_days_back_start).
         * @param {number} [filterDaysBackEnd] Filter de resultaten waarbij start_time &lt; (huidige systeemdatum - filter_days_back_start).
         * @param {number} [filterMaxRecords] Filter de resultaten zodat er maximum het opgegeven aantal teruggegeven worden.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobV1JobRunGet: async (filterDpCustomerCode?: string, filterStatus?: string, searchJobName?: string, filterDaysBackStart?: number, filterDaysBackEnd?: number, filterMaxRecords?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/job/v1/job-run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (filterDpCustomerCode !== undefined) {
                localVarQueryParameter['filter_dp_customer_code'] = filterDpCustomerCode;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter_status'] = filterStatus;
            }

            console.log('searchJobName', searchJobName)
            if (searchJobName !== undefined) {
                localVarQueryParameter['search_job_name'] = searchJobName;
            }

            if (filterDaysBackStart !== undefined) {
                localVarQueryParameter['filter_days_back_start'] = filterDaysBackStart;
            }

            if (filterDaysBackEnd !== undefined) {
                localVarQueryParameter['filter_days_back_end'] = filterDaysBackEnd;
            }

            if (filterMaxRecords !== undefined) {
                localVarQueryParameter['filter_max_records'] = filterMaxRecords;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API waarmee de details van een Digital-Pharma JobRun geraadpleegd kunnen worden.
         * @summary REST.API.PM.JOB.03 Raadpleeg JobRun.
         * @param {string} id De unieke identificatie van de JobRun.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobV1JobRunIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling jobV1JobRunIdGet.');
            }
            const localVarPath = `/job/v1/job-run/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint verantwoordelijk voor het registreren van het starten van een Digital-Pharma JobRun.
         * @summary REST.API.PM.JOB.01 Registreer JobRun start.
         * @param {JobRunStartRestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobV1JobRunStartPost: async (body: JobRunStartRestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling jobV1JobRunStartPost.');
            }
            const localVarPath = `/job/v1/job-run/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobAPIApi - functional programming interface
 * @export
 */
export const JobAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint verantwoordelijk voor het registreren van het eindtijdstip van een Digital-Pharma JobRun.
         * @summary REST.API.PM.JOB.02 Registreer JobRun einde.
         * @param {JobRunEndRestDto} body 
         * @param {string} id De unieke identificatie van de JobRun.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobV1JobRunEndPut(_axios: AxiosInstance, body: JobRunEndRestDto, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await JobAPIApiAxiosParamCreator(configuration).jobV1JobRunEndPut(body, id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API waarmee de Digital-Pharma JobRun elementen gezocht kunnen worden. De resultaten worden gesorteerd op start_time (van recent naar oud)
         * @summary REST.API.PM.JOB.04 Zoek JobRun elementen.
         * @param {string} [filterDpCustomerCode] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {string} [filterStatus] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {string} [searchJobName] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van job_name. (Er wordt case insensitive gezocht.)
         * @param {number} [filterDaysBackStart] Filter de resultaten waarbij start_time &gt; (huidige systeemdatum - filter_days_back_start).
         * @param {number} [filterDaysBackEnd] Filter de resultaten waarbij start_time &lt; (huidige systeemdatum - filter_days_back_start).
         * @param {number} [filterMaxRecords] Filter de resultaten zodat er maximum het opgegeven aantal teruggegeven worden.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobV1JobRunGet(_axios: AxiosInstance, filterDpCustomerCode?: string, filterStatus?: string, searchJobName?: string, filterDaysBackStart?: number, filterDaysBackEnd?: number, filterMaxRecords?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobRunRestDto>>> {
            const localVarAxiosArgs = await JobAPIApiAxiosParamCreator(configuration).jobV1JobRunGet(filterDpCustomerCode, filterStatus, searchJobName, filterDaysBackStart, filterDaysBackEnd, filterMaxRecords, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API waarmee de details van een Digital-Pharma JobRun geraadpleegd kunnen worden.
         * @summary REST.API.PM.JOB.03 Raadpleeg JobRun.
         * @param {string} id De unieke identificatie van de JobRun.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobV1JobRunIdGet(_axios: AxiosInstance, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobRunRestDto>> {
            const localVarAxiosArgs = await JobAPIApiAxiosParamCreator(configuration).jobV1JobRunIdGet(id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint verantwoordelijk voor het registreren van het starten van een Digital-Pharma JobRun.
         * @summary REST.API.PM.JOB.01 Registreer JobRun start.
         * @param {JobRunStartRestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobV1JobRunStartPost(_axios: AxiosInstance, body: JobRunStartRestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await JobAPIApiAxiosParamCreator(configuration).jobV1JobRunStartPost(body, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};


/**
 * JobAPIApi - object-oriented interface
 * @export
 * @class JobAPIApi
 * @extends {BaseAPI}
 */
export class JobAPIApi extends BaseAPI {
    /**
     * API endpoint verantwoordelijk voor het registreren van het eindtijdstip van een Digital-Pharma JobRun.
     * @summary REST.API.PM.JOB.02 Registreer JobRun einde.
     * @param {JobRunEndRestDto} body 
     * @param {string} id De unieke identificatie van de JobRun.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAPIApi
     */
    public jobV1JobRunEndPut(body: JobRunEndRestDto, id: string, options?: any) {
        return JobAPIApiFp(this.configuration).jobV1JobRunEndPut(this.axios, body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * API waarmee de Digital-Pharma JobRun elementen gezocht kunnen worden. De resultaten worden gesorteerd op start_time (van recent naar oud)
     * @summary REST.API.PM.JOB.04 Zoek JobRun elementen.
     * @param {string} [filterDpCustomerCode] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
     * @param {string} [filterStatus] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
     * @param {string} [searchJobName] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van job_name. (Er wordt case insensitive gezocht.)
     * @param {number} [filterDaysBackStart] Filter de resultaten waarbij start_time &gt; (huidige systeemdatum - filter_days_back_start).
     * @param {number} [filterDaysBackEnd] Filter de resultaten waarbij start_time &lt; (huidige systeemdatum - filter_days_back_start).
     * @param {number} [filterMaxRecords] Filter de resultaten zodat er maximum het opgegeven aantal teruggegeven worden.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAPIApi
     */
    public jobV1JobRunGet(filterDpCustomerCode?: string, filterStatus?: string, searchJobName?: string, filterDaysBackStart?: number, filterDaysBackEnd?: number, filterMaxRecords?: number, options?: any) {
        return JobAPIApiFp(this.configuration).jobV1JobRunGet(this.axios, filterDpCustomerCode, filterStatus, searchJobName, filterDaysBackStart, filterDaysBackEnd, filterMaxRecords, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * API waarmee de details van een Digital-Pharma JobRun geraadpleegd kunnen worden.
     * @summary REST.API.PM.JOB.03 Raadpleeg JobRun.
     * @param {string} id De unieke identificatie van de JobRun.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAPIApi
     */
    public jobV1JobRunIdGet(id: string, options?: any) {
        return JobAPIApiFp(this.configuration).jobV1JobRunIdGet(this.axios, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * API endpoint verantwoordelijk voor het registreren van het starten van een Digital-Pharma JobRun.
     * @summary REST.API.PM.JOB.01 Registreer JobRun start.
     * @param {JobRunStartRestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobAPIApi
     */
    public jobV1JobRunStartPost(body: JobRunStartRestDto, options?: any) {
        return JobAPIApiFp(this.configuration).jobV1JobRunStartPost(this.axios, body, options).then((request) => request(this.axios, this.basePath));
    }
}
