import {PharmacyRestDtoModel} from "@/models/PharmacyRestDtoModel";
import {AddressRestDtoModel} from "@/models/AddressRestDtoModel";

function parseSavedPharmacy(pharmacy: PharmacyRestDtoModel): PharmacyRestDtoModel {
    const parsed_pharmacy = JSON.parse(JSON.stringify(pharmacy));

    if (!parsed_pharmacy.different_contactinfo) {
        parsed_pharmacy.public_address = undefined;
        parsed_pharmacy.public_email = undefined;
        parsed_pharmacy.public_phone = undefined;
    }

    return parsed_pharmacy;
}

function parseSearchedPharmacy(pharmacy: PharmacyRestDtoModel): PharmacyRestDtoModel {
    const parsed_pharmacy = JSON.parse(JSON.stringify(pharmacy));

    if (!parsed_pharmacy.public_address) {
        parsed_pharmacy.public_address = AddressRestDtoModel.createWithDefaults();
    }

    return parsed_pharmacy;
}

export {
    parseSavedPharmacy,
    parseSearchedPharmacy
}