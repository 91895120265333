import {DateTimeOptions} from "vue-i18n";

/**
 * Return list of months
 * 🌍 locale   : name of local, f.e. en-GB, default en
 * ✅ monthFormat : short, numeric, long (Default)
 */
function getMonthsForLocale(locale = 'en', monthFormat = 'long') {
    const format = new Intl.DateTimeFormat(locale, {month: monthFormat})
    const months = []
    for (let month = 0; month < 12; month++) {
        const testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
        months.push(format.format(testDate))
    }
    return months;
}

function formatDate(date: Date): string {
    const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    } as DateTimeOptions;

    return new Intl.DateTimeFormat('nl', options).format(date);
}

function formatDateTime(dateTime: Date): string {
    const options = {
        hour12: false,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        timezone: 'Europe/Brussels',
    } as DateTimeOptions;

    return new Intl.DateTimeFormat('nl', options).format(dateTime);
}

export {
    getMonthsForLocale,
    formatDate,
    formatDateTime
};