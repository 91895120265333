
import {defineComponent} from "vue";
import {DpException} from "@/exception/DpException";
import {InputSizeEnum} from "@/models/enum/InputSizeEnum";
import {Tooltip} from "bootstrap";

export default defineComponent({
  emits: [
    "update:modelValue",
    "blur"
  ],
  props: {
    modelValue: {
      type: [String, Number],
    },

    // GENERAL
    disabled: {
      type: Boolean,
    },
    errors: {
      type: Object,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    help: {
      type: String,
      required: false,
      default: null,
    },
    inputSize: {
      type: String,
      required: false,
      default: null,
    },
    language: {
      type: String,
      required: false,
    },
    rules: {
      type: String,
    },

    //BOOLEANS
    canClear: {
      type: Boolean,
      required: false,
      default: false
    },
    isFloating: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInputGroup: {
      type: Boolean,
      required: false,
      default: false,
    },
    isMultilingual: {
      type: Boolean,
      required: false,
      default: false,
    },
    withPasswordPreview: {
      type: Boolean,
      required: false,
      default: true,
    },

    // NUMBER
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      enums: {
        inputSizeEnum: InputSizeEnum,
      },
      inputType: this.determineInputType(),
    };
  },
  mounted() {
    document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
      new Tooltip(el as Element);
    });
  },
  methods: {
    determineInputType(): string {
      throw [new DpException('Child component should overwrite determineInputType():string')];
    },
    determineRules(): string[] {
      return [];
    },
    determineStep(): number | null {
      return null;
    },
    determinePlaceholder(): string | null {
      return null;
    },
    getPlaceholder(): string {
      return this.determinePlaceholder() ?? this.placeholder ?? this.label;
    },
    getRulesAsString(): string {
      return (this.rules ?? '')
          .split('|')
          .concat(this.determineRules())
          .join('|');
    },
    clearValue(): void {
      this.$emit('update:modelValue', null);
    },
    updateValue(value: any): void {
      this.$emit('update:modelValue', value);
    },
    checkRequired(rules: any): boolean | void {
      if (rules && rules.includes("required")) {
        return true;
      }
    },
    isInputType(type: string): boolean {
      return type === this.determineInputType();
    },
    togglePasswordPreview(): void {
      if (this.inputType === 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    }
  },
});
