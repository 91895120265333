import {JobRunCriteriaRestDto} from "@/api/pharma-monitoring/models";
import {JobRunApiService} from "@/main";
import store from "@/store";

export default {
    namespaced: true,
    state() {
        return {
            job_runs: [],
        };
    },
    actions: {
        async searchJobRuns(context: any, criteria: JobRunCriteriaRestDto) {
            console.log('criteria.job_name', criteria.searchJobName)
            
            JobRunApiService.jobV1JobRunGet(criteria.filterDpCustomerCode, criteria.filterStatus, criteria.searchJobName, criteria.filterDaysBackStart, criteria.filterDaysBackEnd, criteria.filterMaxRecords).then(response => {
                store.commit('job_runs/setJobRuns', response.data);
            });
        },
    },
    mutations: {
        setJobRuns(state: any, payload: any) {
            store.state.job_runs.job_runs = payload;
        },
    },
    getters: {
        getJobRuns(state: any) {
            return state.job_runs;
        },
        hasCustomers(state: any) {
            return store.state.job_runs.job_runs && store.state.job_runs.job_runs.length > 0;
        },
    },
};
