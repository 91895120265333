import {DpNvpCriteriaRestDto} from "@/api/pharma-master-nvp/models";

export class DpNvpCriteriaRestDtoModel implements DpNvpCriteriaRestDto {
    search_name?: string | null;
    search_customer_name?: string | null;
    filter_customer_code?: string | null;

    constructor(search_name?: string | null, search_customer_name?: string | null, filter_customer_code?: string | null) {
        this.search_name = search_name;
        this.search_customer_name = search_customer_name;
        this.filter_customer_code = filter_customer_code;
    }

    public static createWithDefaults(): DpNvpCriteriaRestDtoModel {
        return new DpNvpCriteriaRestDtoModel(null, null, null);
    }
}