
import {CustomerCriteriaRestDto, CustomerOverviewRestDto} from "@/api/pharma-customer/models";
import {defineComponent} from "vue";
import store from "@/store";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import CustomerCriteriaPanel from "@/components/layouts/customer/CustomerCriteriaPanel.vue";
import AlertError from "@/components/UI/AlertError.vue";
import {Exception} from "@/api/interfaces";

export default defineComponent({
  components: {AlertError, CustomerCriteriaPanel, BaseTitle},
  data: () => ({
    isReady: false as boolean,
    exceptions: [] as Exception[],
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    filteredCustomers(): Array<CustomerOverviewRestDto> {
      return store.getters['customers/getCustomerOverview'];
    },
    hasCustomers(): boolean {
      return store.getters['customers/hasCustomers'];
    },
  },
  methods: {
    async reloadContent() {
      this.isReady = false;

      await this.searchCustomerOverview({});

      this.isReady = true;
    },
    async searchCustomerOverview(criteria: CustomerCriteriaRestDto) {
      try {
        await store.dispatch('customers/searchCustomerOverview', criteria);
      } catch (exceptions) {
        this.exceptions = exceptions;
      }
    },
  }
});
