/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Document API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { DocumentResponseRestDto } from '../models';
import { UrlResponseRestDto } from '../models';
/**
 * DocumentAPIApi - axios parameter creator
 * @export
 */
export const DocumentAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible retrieving a public url for a document.
         * @summary REST.API.CMS.DOC.02 Get public url.
         * @param {string} document_key The unique identification of the requested document.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentV1DocumentDocumentKeyPublicUrlGet: async (document_key: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'document_key' is not null or undefined
            if (document_key === null || document_key === undefined) {
                throw new RequiredError('document_key','Required parameter document_key was null or undefined when calling documentV1DocumentDocumentKeyPublicUrlGet.');
            }
            const localVarPath = `/document/v1/document/{document_key}/public_url`
                .replace(`{${"document_key"}}`, encodeURIComponent(String(document_key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.DOC.01 Upload a document.
         * @param {string} file 
         * @param {string} type_code The document type of the uploaded document.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentV1DocumentTypeCodeUploadPost: async (file: string, type_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling documentV1DocumentTypeCodeUploadPost.');
            }
            // verify required parameter 'type_code' is not null or undefined
            if (type_code === null || type_code === undefined) {
                throw new RequiredError('type_code','Required parameter type_code was null or undefined when calling documentV1DocumentTypeCodeUploadPost.');
            }
            const localVarPath = `/document/v1/document/{type_code}/upload`
                .replace(`{${"type_code"}}`, encodeURIComponent(String(type_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentAPIApi - functional programming interface
 * @export
 */
export const DocumentAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible retrieving a public url for a document.
         * @summary REST.API.CMS.DOC.02 Get public url.
         * @param {string} document_key The unique identification of the requested document.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentV1DocumentDocumentKeyPublicUrlGet(_axios: AxiosInstance, document_key: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlResponseRestDto>> {
            const localVarAxiosArgs = await DocumentAPIApiAxiosParamCreator(configuration).documentV1DocumentDocumentKeyPublicUrlGet(document_key, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.DOC.01 Upload a document.
         * @param {string} file 
         * @param {string} type_code The document type of the uploaded document.
         * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentV1DocumentTypeCodeUploadPost(_axios: AxiosInstance, file: string, type_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentResponseRestDto>> {
            const localVarAxiosArgs = await DocumentAPIApiAxiosParamCreator(configuration).documentV1DocumentTypeCodeUploadPost(file, type_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentAPIApi - object-oriented interface
 * @export
 * @class DocumentAPIApi
 * @extends {BaseAPI}
 */
export class DocumentAPIApi extends BaseAPI {
    /**
     * API endpoint responsible retrieving a public url for a document.
     * @summary REST.API.CMS.DOC.02 Get public url.
     * @param {string} document_key The unique identification of the requested document.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentAPIApi
     */
     public documentV1DocumentDocumentKeyPublicUrlGet(document_key: string, x_dp_customer_code?: string, options?: any) {
        return DocumentAPIApiFp(this.configuration).documentV1DocumentDocumentKeyPublicUrlGet(this.axios, document_key, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.DOC.01 Upload a document.
     * @param {string} file 
     * @param {string} type_code The document type of the uploaded document.
     * @param {string} [x_dp_customer_code] Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentAPIApi
     */
     public documentV1DocumentTypeCodeUploadPost(file: string, type_code: string, x_dp_customer_code?: string, options?: any) {
        return DocumentAPIApiFp(this.configuration).documentV1DocumentTypeCodeUploadPost(this.axios, file, type_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
