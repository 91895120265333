import {RestService} from "@/services/rest/RestService";
import {DashboardAPIApi} from "@/api/pharma-master-dashboard";
import {MonthlyDashboardRowRestDto, MonthlyDashboardTypeOverviewRestDto} from "@/api/pharma-master-dashboard/models";
import {DpModuleEnum} from "@/api/enums/dp-module-enum";

export class DashboardRestService extends RestService {

    private apiService: DashboardAPIApi;

    protected constructor() {
        super();

        this.apiService = new DashboardAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().masterUrl);
    }

    public async findMonthlyDashboardTypes(x_dp_customer_code?: string): Promise<MonthlyDashboardTypeOverviewRestDto[]> {
        const response = await this.apiService.findMonthlyDashboardTypes(x_dp_customer_code);

        return (response.data.results) ?? [];
    }

    public async findMonthlyDashboardsForType(dashboard_type_code: string, x_dp_customer_code?: string, x_dp_module_code?: DpModuleEnum, search_customer_name?: string, filter_customer_code?: string, filter_customer_groups?: boolean): Promise<MonthlyDashboardRowRestDto[]> {
        const response = await this.apiService.findMonthlyDashboardsForType(dashboard_type_code, x_dp_customer_code, x_dp_module_code, search_customer_name, filter_customer_code, filter_customer_groups);

        return (response.data.results) ?? [];
    }
}