
import {config} from '@/helpers/fetch-config';
import {JobRunCriteriaRestDto} from "@/api/pharma-monitoring/models";
import {defineComponent} from "vue";
import InputText from "@/components/UI/InputText.vue";

export default defineComponent({
  components: {InputText},
  emits: ['searchJobRuns'],
  data() {
    return {
      config,
      criteria: {
        filterMaxRecords: '10',
      } as JobRunCriteriaRestDto,
      maxNumberOfRecordsOptions: [
        {code: '1', description: '1'},
        {code: '5', description: '5'},
        {code: '10', description: '10'},
        {code: '20', description: '20'},
        {code: '50', description: '50'},
        {code: '100', description: '100'},
      ],
      statusOptions: [
        {
          code: 'SUCCESS',
          description: 'Success',
        },
        {
          code: 'FAILED',
          description: 'Failed',
        },
      ],
    }
  },
  mounted() {
    this.submitData();
  },
  methods: {
    submitData() {
      this.$emit('searchJobRuns', this.criteria);
    },
    clearCriteria() {
      this.criteria = {} as JobRunCriteriaRestDto;
    },
  },
});
