import {createInstance} from '@/helpers/axios';
import {config} from '@/helpers/fetch-config';

const axiosOptions = {
    //TODO: do we need this?  And does the value have to be contentBlock?
    baseURL: config.contentBlock
};

const masterEnumInstance = createInstance(axiosOptions);

// possibility to add instance specific configuration like interceptors etc.

export default masterEnumInstance;
