import {RestService} from "@/services/rest/RestService";
import {NvpAPIApi} from "@/api/pharma-master-nvp";
import {
    DpNameValuePairDetailRestDto,
    DpNameValuePairOverviewResultsRestDto, DpNameValuePairValueRestDto,
    DpNvpCriteriaRestDto
} from "@/api/pharma-master-nvp/models";

export class NameValuePairRestService extends RestService {

    private apiService: NvpAPIApi;

    protected constructor() {
        super();

        this.apiService = new NvpAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().masterUrl);
    }

    public async findNameValuePairs(x_dp_module_code?: string, dto?: DpNvpCriteriaRestDto): Promise<DpNameValuePairOverviewResultsRestDto> {
        const response = await this.apiService.findDpNvps(
            x_dp_module_code,
            dto?.search_name ?? undefined,
            dto?.search_customer_name ?? undefined,
            dto?.filter_customer_code ?? undefined
        );

        return response.data;
    }

    public async findNameValuePairDetails(id: string, x_dp_module_code?: string): Promise<DpNameValuePairDetailRestDto> {
        const response = await this.apiService.getDpNvpById(id, x_dp_module_code);

        return response.data;
    }

    public async createNameValuePair(body: DpNameValuePairValueRestDto, name: string, x_dp_module_code?: string, x_dp_customer_code?: string): Promise<void> {
        await this.apiService.createDpNvp(body, name, x_dp_module_code, x_dp_customer_code);
    }

    public async updateNameValuePair(body: DpNameValuePairValueRestDto, id: string, x_dp_module_code?: string, x_dp_customer_code?: string): Promise<void> {
        await this.apiService.updateDpNvp(body, id, x_dp_module_code, x_dp_customer_code);
    }
}