/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */
export enum TaskTypeEnum {
    AI_API = 'AI_API',
    SCRAPE_PAGE = 'SCRAPE_PAGE',
    HANDLE_HTML_PAGE = 'HANDLE_HTML_PAGE',
    QUERY_PROCESSING = 'QUERY_PROCESSING',
    TASK_COMPARISON = 'TASK_COMPARISON',
}