/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Master License Management API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ProductLicenseOverviewRestDto } from '../models';
import { ProductLicenseRestDto } from '../models';
/**
 * ProductLicenseEndpointsApi - axios parameter creator
 * @export
 */
export const ProductLicenseEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.LIC.MGMT.04 Create a product license.
         * @param {ProductLicenseRestDto} body 
         * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductLicense: async (body: ProductLicenseRestDto, customer_code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createProductLicense.');
            }
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling createProductLicense.');
            }
            const localVarPath = `/license_mgmt/v1/customer/{customer_code}/license`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.01 Find the product licenses for a customer.
         * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
         * @param {boolean} [filter_is_active] Filter the results based on the fact if the license is currently active.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomerProductLicenses: async (customer_code: string, filter_is_active?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling findCustomerProductLicenses.');
            }
            const localVarPath = `/license_mgmt/v1/customer/{customer_code}/license`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_active || typeof(filter_is_active) === "boolean") {
                localVarQueryParameter['filter_is_active'] = filter_is_active;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.02 Find the product license.
         * @param {string} id Uniquely identifies the product license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findProductLicense: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findProductLicense.');
            }
            const localVarPath = `/license_mgmt/v1/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.03 Update the product license.
         * @param {ProductLicenseRestDto} body 
         * @param {string} id Uniquely identifies the product license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductLicense: async (body: ProductLicenseRestDto, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProductLicense.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateProductLicense.');
            }
            const localVarPath = `/license_mgmt/v1/license/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductLicenseEndpointsApi - functional programming interface
 * @export
 */
export const ProductLicenseEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.LIC.MGMT.04 Create a product license.
         * @param {ProductLicenseRestDto} body 
         * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductLicense(_axios: AxiosInstance, body: ProductLicenseRestDto, customer_code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductLicenseEndpointsApiAxiosParamCreator(configuration).createProductLicense(body, customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.01 Find the product licenses for a customer.
         * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
         * @param {boolean} [filter_is_active] Filter the results based on the fact if the license is currently active.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomerProductLicenses(_axios: AxiosInstance, customer_code: string, filter_is_active?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductLicenseOverviewRestDto>>> {
            const localVarAxiosArgs = await ProductLicenseEndpointsApiAxiosParamCreator(configuration).findCustomerProductLicenses(customer_code, filter_is_active, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.02 Find the product license.
         * @param {string} id Uniquely identifies the product license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findProductLicense(_axios: AxiosInstance, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLicenseRestDto>> {
            const localVarAxiosArgs = await ProductLicenseEndpointsApiAxiosParamCreator(configuration).findProductLicense(id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.03 Update the product license.
         * @param {ProductLicenseRestDto} body 
         * @param {string} id Uniquely identifies the product license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductLicense(_axios: AxiosInstance, body: ProductLicenseRestDto, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductLicenseEndpointsApiAxiosParamCreator(configuration).updateProductLicense(body, id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductLicenseEndpointsApi - object-oriented interface
 * @export
 * @class ProductLicenseEndpointsApi
 * @extends {BaseAPI}
 */
export class ProductLicenseEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.LIC.MGMT.04 Create a product license.
     * @param {ProductLicenseRestDto} body 
     * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLicenseEndpointsApi
     */
     public createProductLicense(body: ProductLicenseRestDto, customer_code: string, options?: any) {
        return ProductLicenseEndpointsApiFp(this.configuration).createProductLicense(this.axios, body, customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.LIC.MGMT.01 Find the product licenses for a customer.
     * @param {string} customer_code Code that uniquely identifies the Digital Pharma customer.
     * @param {boolean} [filter_is_active] Filter the results based on the fact if the license is currently active.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLicenseEndpointsApi
     */
     public findCustomerProductLicenses(customer_code: string, filter_is_active?: boolean, options?: any) {
        return ProductLicenseEndpointsApiFp(this.configuration).findCustomerProductLicenses(this.axios, customer_code, filter_is_active, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.LIC.MGMT.02 Find the product license.
     * @param {string} id Uniquely identifies the product license.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLicenseEndpointsApi
     */
     public findProductLicense(id: string, options?: any) {
        return ProductLicenseEndpointsApiFp(this.configuration).findProductLicense(this.axios, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.LIC.MGMT.03 Update the product license.
     * @param {ProductLicenseRestDto} body 
     * @param {string} id Uniquely identifies the product license.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductLicenseEndpointsApi
     */
     public updateProductLicense(body: ProductLicenseRestDto, id: string, options?: any) {
        return ProductLicenseEndpointsApiFp(this.configuration).updateProductLicense(this.axios, body, id, options).then((request) => request(this.axios, this.basePath));
    }

}
