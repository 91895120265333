import store from "@/store";
import {
    CodeDescriptionRestDto,
} from "@/api/pharma-license-license-info/models";
import {LicenseInformationEnumApiService, ProductFeatureApiService} from "@/main";

export default {
    namespaced: true,
    state() {
        return {
            product_enums: [] as CodeDescriptionRestDto[],
        }
    },
    actions: {
        async searchProductEnums(context: any) {
            if (context.state.product_enums.length <= 0) {
                const response = await LicenseInformationEnumApiService.findProductEnums();

                context.commit("setProductEnums", response.data);
            }
        }
    },
    mutations: {
        setProductEnums(state: any, payload: CodeDescriptionRestDto[]) {
            state.product_enums = payload;
        },
    },
    getters: {
        getProductEnums(state: any): CodeDescriptionRestDto[] {
            return state.product_enums;
        },
    }
}