/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Auth Pharmacist Users API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreatePharmacistUserRestDto } from '../models';
import { PharmacistUserPasswordRestDto } from '../models';
import { PharmacistUserRestDto } from '../models';
import { PharmacistUserResultsRestDto } from '../models';
/**
 * PharmacistUserManagementEndpointsApi - axios parameter creator
 * @export
 */
export const PharmacistUserManagementEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create new pharmacist user
         * @summary REST.API.IDM.PHUSER.06 Create pharmacist users.
         * @param {CreatePharmacistUserRestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPharmacistUsers: async (body: CreatePharmacistUserRestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPharmacistUsers.');
            }
            const localVarPath = `/pharmacist_user_mgmt/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Disable pharmacist user
         * @summary REST.API.IDM.PHUSER.05 Disable pharmacist user.
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disablePharmacistUser: async (user_id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling disablePharmacistUser.');
            }
            const localVarPath = `/pharmacist_user_mgmt/v1/user/{user_id}/disable`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable pharmacist user
         * @summary REST.API.IDM.PHUSER.04 Enable pharmacist user.
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enablePharmacistUser: async (user_id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling enablePharmacistUser.');
            }
            const localVarPath = `/pharmacist_user_mgmt/v1/user/{user_id}/enable`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Find pharmacist users based on the provided search criteria.
         * @summary REST.API.IDM.PHUSER.01 Find pharmacist users.
         * @param {string} [filter_customer_code] Filter the results based on the customer code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPharmacistUsers: async (filter_customer_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/pharmacist_user_mgmt/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_customer_code || typeof(filter_customer_code) === "boolean") {
                localVarQueryParameter['filter_customer_code'] = filter_customer_code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.IDM.PHUSER.02 Get the pharmacist user details.
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPharmacistUserDetail: async (user_id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling getPharmacistUserDetail.');
            }
            const localVarPath = `/pharmacist_user_mgmt/v1/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update pharmacist user password
         * @summary REST.API.IDM.PHUSER.03 Update pharmacist user password.
         * @param {PharmacistUserPasswordRestDto} body 
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePharmacistUserPassword: async (body: PharmacistUserPasswordRestDto, user_id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePharmacistUserPassword.');
            }
            // verify required parameter 'user_id' is not null or undefined
            if (user_id === null || user_id === undefined) {
                throw new RequiredError('user_id','Required parameter user_id was null or undefined when calling updatePharmacistUserPassword.');
            }
            const localVarPath = `/pharmacist_user_mgmt/v1/user/{user_id}/password`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(user_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PharmacistUserManagementEndpointsApi - functional programming interface
 * @export
 */
export const PharmacistUserManagementEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Create new pharmacist user
         * @summary REST.API.IDM.PHUSER.06 Create pharmacist users.
         * @param {CreatePharmacistUserRestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPharmacistUsers(_axios: AxiosInstance, body: CreatePharmacistUserRestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PharmacistUserManagementEndpointsApiAxiosParamCreator(configuration).createPharmacistUsers(body, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Disable pharmacist user
         * @summary REST.API.IDM.PHUSER.05 Disable pharmacist user.
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disablePharmacistUser(_axios: AxiosInstance, user_id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PharmacistUserManagementEndpointsApiAxiosParamCreator(configuration).disablePharmacistUser(user_id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Enable pharmacist user
         * @summary REST.API.IDM.PHUSER.04 Enable pharmacist user.
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enablePharmacistUser(_axios: AxiosInstance, user_id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PharmacistUserManagementEndpointsApiAxiosParamCreator(configuration).enablePharmacistUser(user_id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Find pharmacist users based on the provided search criteria.
         * @summary REST.API.IDM.PHUSER.01 Find pharmacist users.
         * @param {string} [filter_customer_code] Filter the results based on the customer code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPharmacistUsers(_axios: AxiosInstance, filter_customer_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PharmacistUserResultsRestDto>> {
            const localVarAxiosArgs = await PharmacistUserManagementEndpointsApiAxiosParamCreator(configuration).findPharmacistUsers(filter_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.IDM.PHUSER.02 Get the pharmacist user details.
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPharmacistUserDetail(_axios: AxiosInstance, user_id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PharmacistUserRestDto>> {
            const localVarAxiosArgs = await PharmacistUserManagementEndpointsApiAxiosParamCreator(configuration).getPharmacistUserDetail(user_id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update pharmacist user password
         * @summary REST.API.IDM.PHUSER.03 Update pharmacist user password.
         * @param {PharmacistUserPasswordRestDto} body 
         * @param {string} user_id Uniquely identifies a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePharmacistUserPassword(_axios: AxiosInstance, body: PharmacistUserPasswordRestDto, user_id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PharmacistUserManagementEndpointsApiAxiosParamCreator(configuration).updatePharmacistUserPassword(body, user_id, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PharmacistUserManagementEndpointsApi - object-oriented interface
 * @export
 * @class PharmacistUserManagementEndpointsApi
 * @extends {BaseAPI}
 */
export class PharmacistUserManagementEndpointsApi extends BaseAPI {
    /**
     * Create new pharmacist user
     * @summary REST.API.IDM.PHUSER.06 Create pharmacist users.
     * @param {CreatePharmacistUserRestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistUserManagementEndpointsApi
     */
     public createPharmacistUsers(body: CreatePharmacistUserRestDto, options?: any) {
        return PharmacistUserManagementEndpointsApiFp(this.configuration).createPharmacistUsers(this.axios, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disable pharmacist user
     * @summary REST.API.IDM.PHUSER.05 Disable pharmacist user.
     * @param {string} user_id Uniquely identifies a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistUserManagementEndpointsApi
     */
     public disablePharmacistUser(user_id: string, options?: any) {
        return PharmacistUserManagementEndpointsApiFp(this.configuration).disablePharmacistUser(this.axios, user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable pharmacist user
     * @summary REST.API.IDM.PHUSER.04 Enable pharmacist user.
     * @param {string} user_id Uniquely identifies a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistUserManagementEndpointsApi
     */
     public enablePharmacistUser(user_id: string, options?: any) {
        return PharmacistUserManagementEndpointsApiFp(this.configuration).enablePharmacistUser(this.axios, user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Find pharmacist users based on the provided search criteria.
     * @summary REST.API.IDM.PHUSER.01 Find pharmacist users.
     * @param {string} [filter_customer_code] Filter the results based on the customer code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistUserManagementEndpointsApi
     */
     public findPharmacistUsers(filter_customer_code?: string|null, options?: any) {
        return PharmacistUserManagementEndpointsApiFp(this.configuration).findPharmacistUsers(this.axios, filter_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.IDM.PHUSER.02 Get the pharmacist user details.
     * @param {string} user_id Uniquely identifies a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistUserManagementEndpointsApi
     */
     public getPharmacistUserDetail(user_id: string, options?: any) {
        return PharmacistUserManagementEndpointsApiFp(this.configuration).getPharmacistUserDetail(this.axios, user_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update pharmacist user password
     * @summary REST.API.IDM.PHUSER.03 Update pharmacist user password.
     * @param {PharmacistUserPasswordRestDto} body 
     * @param {string} user_id Uniquely identifies a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistUserManagementEndpointsApi
     */
     public updatePharmacistUserPassword(body: PharmacistUserPasswordRestDto, user_id: string, options?: any) {
        return PharmacistUserManagementEndpointsApiFp(this.configuration).updatePharmacistUserPassword(this.axios, body, user_id, options).then((request) => request(this.axios, this.basePath));
    }

}
