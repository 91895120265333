
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseAccordionItem",
  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    collapseId(): String {
      return 'collapse' + this.index.toString();
    },
    headingId(): String {
      return 'heading' + this.index.toString();
    },
  },
});
