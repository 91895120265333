
import {defineComponent, PropType} from 'vue'
import InputNumber from "@/components/UI/InputNumber.vue";
import {TaskOutputFeedbackRestDto, TaskOutputRestDto} from "@/api/pharma-tp-task/models";
import {InputSizeEnum} from "@/models/enum/InputSizeEnum";
import {TaskTypeEnum} from "@/api/enums/task-type-enum";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {formatDateTime} from "@/helpers/functions/date";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "TaskRunOutput",
  emits: ["submitAltText", "submitFeedback"],
  components: {InputTextEditor, InputNumber},
  props: {
    taskRun: {
      type: Number,
      required: true
    },
    sourceText: {
      type: String,
      required: false
    },
    taskOutput: {
      type: Object as PropType<TaskOutputRestDto>,
      required: true
    },
    selectedTaskRuns: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  data() {
    return {
      feedbackNote: null as string | null,
      feedbackScore: null as number | null,

      alternativeText: null as string | null,
      isEditingAltText: false as boolean
    }
  },
  watch: {
    taskOutput: {
      handler() {
        this.reloadContent();
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    UtilityHelper() {
      return UtilityHelper
    },
    TaskTypeEnum() {
      return TaskTypeEnum
    },
    InputSizeEnum() {
      return InputSizeEnum
    }
  },
  methods: {
    formatDateTime,
    reloadContent(): void {
      if (this.taskOutput !== null) {
        this.feedbackNote = this.taskOutput?.feedback_note ?? null;
        this.feedbackScore = this.taskOutput?.feedback_score ?? null;

        if (this.taskOutput.task_type_code === TaskTypeEnum.AI_API) {
          if (this.taskOutput.specific_output.alternative_text !== null && this.taskOutput.specific_output.alternative_text !== undefined) {
            this.alternativeText = this.taskOutput.specific_output.alternative_text;
            this.isEditingAltText = true;
          } else {
            this.alternativeText = this.taskOutput.specific_output.ai_output;
          }
        }
      } else {
        this.feedbackNote = null;
        this.feedbackScore = null;
        this.alternativeText = null;
      }
    },
    submitAlternativeText(): void {
      const taskId = this.taskOutput.task_id as string;
      const outputId = this.taskOutput.id as string;

      this.$emit('submitAltText', this.alternativeText, taskId, outputId);

      // eslint-disable-next-line vue/no-mutating-props
      this.taskOutput.specific_output.alternative_text = this.alternativeText;
      this.isEditingAltText = true;
    },
    submitFeedback(score: number): void {
      // console.log('submitFeedback', score);
      if (score === null || score < 0) {
        throw 'Fill in a score.';
      }

      if (this.feedbackNote?.length === 0) {
        this.feedbackNote = null;
      }

      this.feedbackScore = score;

      const taskOutputFeedback = {
        feedback_note: this.feedbackNote,
        feedback_score: score
      } as TaskOutputFeedbackRestDto;
      const taskId = this.taskOutput.task_id as string;
      const outputId = this.taskOutput.id as string;

      this.$emit('submitFeedback', taskOutputFeedback, taskId, outputId);
    }
  }
})
