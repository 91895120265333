export enum EndReasonsEnum {
    DP_WEBSITE_PREMIUM_DP2 = 'DP2 Premium website',

    DP_WEBSITE_STARTER_DP3 = 'DP3 Starter website',

    DP_WEBSITE_BASIC_DP3 = 'DP3 Basic website',

    DP_WEBSITE_PREMIUM_DP3 = 'DP3 Premium website',

    DP_PHARMACOLLECT_APB_DP3 = 'DP3 Pharmacollect APB design',

    DP_PHARMACOLLECT_TEMPL_DP3 = 'DP3 Pharmacollect Template design',

    WEG_BIJ_DP = 'Weg bij DP',

    NAAR_CONCURENT = 'Naar concurrent',

    VERGISSING_TE_VERWIJDEREN = 'Vergissing, te verwijderen',

    ANDERE = 'Andere',
}