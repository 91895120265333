import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {XDPModuleCodeEnum} from "@/models/enum/XDPModuleCodeEnum";

export class DpModuleHelper {
    public static getOptionsAsList(): CodeDescriptionRestDtoModel[] {
        return [
            new CodeDescriptionRestDtoModel(XDPModuleCodeEnum.AUTH, 'Pharma-Auth'),
            new CodeDescriptionRestDtoModel(XDPModuleCodeEnum.CPC, 'Pharma-CPC'),
            new CodeDescriptionRestDtoModel(XDPModuleCodeEnum.ORDER, 'Pharma-Order'),
        ];
    }
}
