
import {config} from '@/helpers/fetch-config';
import {mapGetters} from "vuex";
import {defineComponent} from 'vue';
import {Exception} from "@/api/interfaces";
import store from "@/store";
import {CodeDescriptionRestDto} from "@/api/pharma-license-license-info/models";
import {defineRule} from "vee-validate";
import {createCodeDescriptionArrayFromEnum} from "@/helpers/functions/enum";
import {EndReasonsEnum} from "@/models/enum/EndReasonsEnum";
import {ProductLicenseRestDtoModel} from "@/models/ProductLicenseRestDtoModel";
import {ProductLicenseRestService} from "@/services/rest/pharma-master/ProductLicenseRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/AlertError2.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {PageDetailModeEnum} from "@/models/enum/PageDetailModeEnum";
import CustomerFormContainer from "@/components/layouts/customer/CustomerFormContainer.vue";

defineRule("check_date", (value: any, [begin, end]: any) => {
  if (begin > end) {
    return 'De einddatum moet gelijk aan of later zijn';
  }
  return true;
});

export default defineComponent({
  name: "CustomerLicenseForm",
  components: {CustomerFormContainer, BaseSpinner, BaseTitle, AlertError2},
  props: ['mode'],
  data: () => ({
    config,
    error: null,
    exceptions: [] as Exception[],

    productLicense: ProductLicenseRestDtoModel.createWithDefaults(),
    productLicenseUI: UIStateDto.createWithDefaults(),
  }),
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('license_info', ['getProductEnums']),
    PageDetailModeEnum() {
      return PageDetailModeEnum;
    },
    customerCode(): any {
      return this.$route.params.customer_code;
    },
    licenseId(): any {
      return this.$route.params.license_id;
    },
    endReasons(): Array<CodeDescriptionRestDto> {
      return createCodeDescriptionArrayFromEnum(EndReasonsEnum);
    },
    productEnums(): Array<CodeDescriptionRestDto> {
      return this.getProductEnums;
    },
    hasEndDate() {
      return (this.productLicense.end_date !== null);
    },
  },
  methods: {
    async reloadContent() {
      this.productLicenseUI.setIsReady(false);

      if (this.mode === PageDetailModeEnum.EDIT) {
        await this.searchLicense(this.licenseId);
      }

      this.productLicenseUI.setIsReady(true);
    },
    removeEndDate() {
      this.productLicense.end_date = null;
      this.productLicense.end_reason = null;
    },
    submitData() {
      if (this.productLicense.end_date === null) {
        this.productLicense.end_reason = null;
      }

      if (this.mode === PageDetailModeEnum.NEW) {
        this.createLicense();
      } else if (this.mode === PageDetailModeEnum.EDIT) {
        this.updateLicense();
      }
    },
    async createLicense() {
      this.productLicenseUI
          .setIsReady(false)
          .clearError();

      try {
        await ProductLicenseRestService.getInstance().createProductLicense(this.productLicense, this.customerCode);
        await handleSavedSuccessfully();
        await this.$router.push({
          name: 'edit-customer', params: {id: this.customerCode}
        });
      } catch (exceptions) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productLicenseUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.productLicenseUI.setIsReady(true);
      }
    },
    async updateLicense() {
      this.productLicenseUI
          .setIsReady(false)
          .clearError();

      try {
        await ProductLicenseRestService.getInstance().updateProductLicense(this.productLicense, this.licenseId);
        await handleSavedSuccessfully();
        await this.$router.push({
          name: 'edit-customer', params: {id: this.customerCode}
        });
      } catch (exceptions) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productLicenseUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.productLicenseUI.setIsReady(true);
      }
    },
    async searchLicense(id: string) {
      this.productLicenseUI
          .setIsReady(false)
          .clearError();

      try {
        this.productLicense = await ProductLicenseRestService.getInstance().findProductLicense(id);
        this.productLicenseUI.setIsReady(true);
      } catch (exceptions) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productLicenseUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async searchProductEnums() {
      try {
        await store.dispatch('license_info/searchProductEnums');
      } catch (exceptions) {
        this.exceptions = exceptions;
      }
    },
  }
});
