import {TextProcessingTaskCriteriaRestDto} from "@/api/pharma-tp-task/models";
import {PagingPayload} from "@/models/payload/PagingPayload";

export class TextProcessingTaskCriteriaRestDtoModel implements TextProcessingTaskCriteriaRestDto {
    x_dp_customer_code?: string | null;
    x_dp_language?: string | null;

    page?: number | null;
    page_size?: number | null;

    filter_cnk_code?: number | null;
    filter_task_type_code?: string | null;
    search_source_keyword?: string | null;
    search_task_code?: string | null;


    constructor(page: number, page_size: number) {
        this.page = page;
        this.page_size = page_size;
    }

    public static createWithDefaults(): TextProcessingTaskCriteriaRestDtoModel {
        const defaultPaging = PagingPayload.createWithDefaults();
        return new this(defaultPaging.number, defaultPaging.size);
    }
}