/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Master License Management API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CustomerFeatureAuthorizationPeriodRestDto } from '../models';
import { ProductLicenseWithFeaturesResponseRestDto } from '../models';
/**
 * ProductFeaturesEndpointsApi - axios parameter creator
 * @export
 */
export const ProductFeaturesEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.LIC.MGMT.07 Exclude a standard feature
         * @param {string} feature_code Uniquely identifies the feature to modify.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excludeFeature: async (feature_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature_code' is not null or undefined
            if (feature_code === null || feature_code === undefined) {
                throw new RequiredError('feature_code','Required parameter feature_code was null or undefined when calling excludeFeature.');
            }
            const localVarPath = `/license_mgmt/v1/feature/{feature_code}/exclude`
                .replace(`{${"feature_code"}}`, encodeURIComponent(String(feature_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.05 Find the feature settings stuctured per product license for a customer.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_license_active] Filter the results based on the fact if the license is currently active.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFeatureSettings: async (x_dp_customer_code?: string, filter_is_license_active?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/license_mgmt/v1/feature/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_is_license_active || typeof(filter_is_license_active) === "boolean") {
                localVarQueryParameter['filter_is_license_active'] = filter_is_license_active;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.06 Include an optional feature
         * @param {string} feature_code Uniquely identifies the feature to modify.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        includeFeature: async (feature_code: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'feature_code' is not null or undefined
            if (feature_code === null || feature_code === undefined) {
                throw new RequiredError('feature_code','Required parameter feature_code was null or undefined when calling includeFeature.');
            }
            const localVarPath = `/license_mgmt/v1/feature/{feature_code}/include`
                .replace(`{${"feature_code"}}`, encodeURIComponent(String(feature_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.08 Update customer feature authorization period
         * @param {CustomerFeatureAuthorizationPeriodRestDto} body 
         * @param {string} id Uniquely identifies the customer feature authorization to modify.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerFeatureAuthorization: async (body: CustomerFeatureAuthorizationPeriodRestDto, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCustomerFeatureAuthorization.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateCustomerFeatureAuthorization.');
            }
            const localVarPath = `/license_mgmt/v1/feature/customer_authorization/{id}/period`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductFeaturesEndpointsApi - functional programming interface
 * @export
 */
export const ProductFeaturesEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.LIC.MGMT.07 Exclude a standard feature
         * @param {string} feature_code Uniquely identifies the feature to modify.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excludeFeature(_axios: AxiosInstance, feature_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductFeaturesEndpointsApiAxiosParamCreator(configuration).excludeFeature(feature_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.05 Find the feature settings stuctured per product license for a customer.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {boolean} [filter_is_license_active] Filter the results based on the fact if the license is currently active.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFeatureSettings(_axios: AxiosInstance, x_dp_customer_code?: string, filter_is_license_active?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLicenseWithFeaturesResponseRestDto>> {
            const localVarAxiosArgs = await ProductFeaturesEndpointsApiAxiosParamCreator(configuration).findFeatureSettings(x_dp_customer_code, filter_is_license_active, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.06 Include an optional feature
         * @param {string} feature_code Uniquely identifies the feature to modify.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async includeFeature(_axios: AxiosInstance, feature_code: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductFeaturesEndpointsApiAxiosParamCreator(configuration).includeFeature(feature_code, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.LIC.MGMT.08 Update customer feature authorization period
         * @param {CustomerFeatureAuthorizationPeriodRestDto} body 
         * @param {string} id Uniquely identifies the customer feature authorization to modify.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerFeatureAuthorization(_axios: AxiosInstance, body: CustomerFeatureAuthorizationPeriodRestDto, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductFeaturesEndpointsApiAxiosParamCreator(configuration).updateCustomerFeatureAuthorization(body, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductFeaturesEndpointsApi - object-oriented interface
 * @export
 * @class ProductFeaturesEndpointsApi
 * @extends {BaseAPI}
 */
export class ProductFeaturesEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.LIC.MGMT.07 Exclude a standard feature
     * @param {string} feature_code Uniquely identifies the feature to modify.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFeaturesEndpointsApi
     */
     public excludeFeature(feature_code: string, x_dp_customer_code?: string, options?: any) {
        return ProductFeaturesEndpointsApiFp(this.configuration).excludeFeature(this.axios, feature_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.LIC.MGMT.05 Find the feature settings stuctured per product license for a customer.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {boolean} [filter_is_license_active] Filter the results based on the fact if the license is currently active.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFeaturesEndpointsApi
     */
     public findFeatureSettings(x_dp_customer_code?: string, filter_is_license_active?: boolean, options?: any) {
        return ProductFeaturesEndpointsApiFp(this.configuration).findFeatureSettings(this.axios, x_dp_customer_code, filter_is_license_active, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.LIC.MGMT.06 Include an optional feature
     * @param {string} feature_code Uniquely identifies the feature to modify.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFeaturesEndpointsApi
     */
     public includeFeature(feature_code: string, x_dp_customer_code?: string, options?: any) {
        return ProductFeaturesEndpointsApiFp(this.configuration).includeFeature(this.axios, feature_code, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.LIC.MGMT.08 Update customer feature authorization period
     * @param {CustomerFeatureAuthorizationPeriodRestDto} body 
     * @param {string} id Uniquely identifies the customer feature authorization to modify.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductFeaturesEndpointsApi
     */
     public updateCustomerFeatureAuthorization(body: CustomerFeatureAuthorizationPeriodRestDto, id: string, x_dp_customer_code?: string, options?: any) {
        return ProductFeaturesEndpointsApiFp(this.configuration).updateCustomerFeatureAuthorization(this.axios, body, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
