
import {defineComponent, PropType} from "vue";
import BasePaginationItem from "@/components/UI/Bootstrap/BasePaginationItem.vue";
import {PagingMetadataRestDto} from "@/api/pharma-tp-task/models";

export default defineComponent({
  name: "BasePagination",
  components: {BasePaginationItem},
  emits: ["changePage"],
  props: {
    paginationData: {
      type: Object as PropType<PagingMetadataRestDto>,
      required: true,
    },
    nPaginationItems: {
      type: Number,
      required: false,
      default: 5,
      validator(value: number) {
        return value >= 3 && (value % 2 === 1);
      }
    },
    showPageItemsCount: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    pageNumbersToShow(): number[] {
      if (!this.paginationData?.total_pages) {
        throw 'No total pages in prop paginationData'
      }

      const pageNumbersToShow = [] as number[];
      const paginationPadding = (this.nPaginationItems - 1) / 2 as number;

      let start = 0 as number;
      if ((this.paginationData.page_number - paginationPadding) <= 0) {
        start = 1;
      } else if (this.paginationData.page_number === this.paginationData.total_pages - 1) {
        start = this.paginationData.page_number - paginationPadding - 1;
      } else if (this.paginationData.page_number === this.paginationData.total_pages) {
        start = this.paginationData.total_pages - (this.nPaginationItems - 1);
      } else {
        start = this.paginationData.page_number - paginationPadding;
      }

      // console.log(`from ${start} to ${start + (nPaginationItems - 1)}`);
      for (let pageNumber = start; pageNumber < start + this.nPaginationItems && pageNumber <= this.paginationData.total_pages; pageNumber++) {
        pageNumbersToShow.push(pageNumber);
      }

      return pageNumbersToShow;
    },
    itemsCountOfTotal(): string {
      const firstItemCount = this.paginationData.size * (this.paginationData.page_number - 1) + 1 as number;
      const lastItemCount = firstItemCount + this.paginationData.page_element_count - 1 as number;

      return `${'Item'} ${firstItemCount} ${'to'} ${lastItemCount} ${'of'} ${this.paginationData.total_elements}`;
    },
  },
  methods: {
    changePage(page: number) {
      this.$emit('changePage', page);
    }
  }
});
