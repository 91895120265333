
import {defineComponent, PropType} from 'vue'
import {TextProcessingTaskSourceAndOutputOverviewRestDto} from "@/api/pharma-tp-task/models";
import {TaskTypeEnum} from "@/api/enums/task-type-enum";
import BaseModal from "@/components/UI/Modal/BaseModal.vue";
import {generateDomId} from "@/helpers/functions/string";
import BaseModalButton from "@/components/UI/Modal/BaseModalButton.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";

export default defineComponent({
  name: "TaskRunInput",
  components: {BaseModalButton, BaseModal},
  props: {
    taskSourceAndOutput: {
      type: Object as PropType<TextProcessingTaskSourceAndOutputOverviewRestDto>,
      required: true
    }
  },
  data() {
    return {
      modalId: generateDomId('taskInputModal-') as string,
      modalHasOpened: false as boolean,

      colWidth: 3 as number
    }
  },
  computed: {
    TaskTypeEnum() {
      return TaskTypeEnum
    },
    UtilityHelper() {
      return UtilityHelper
    },
  },
  methods: {
    decreaseColWidth(): void {
      if (this.colWidth > 1) {
        this.colWidth--;
      }
    },
    increaseColWidth(): void {
      this.colWidth++;
    }
  }
})
