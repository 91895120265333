import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 0,
  class: "badge text-bg-primary ms-2"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 2,
  class: "form-help"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dp_field = _resolveComponent("dp-field")!
  const _component_dp_error_message = _resolveComponent("dp-error-message")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dp-input mb-3", {
        'input-group': this.isInputGroup,
        'form-floating': this.isFloating,
        'has-error': this.errors[this.name],
      }])
  }, [
    (!this.isFloating && this.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: this.name,
          class: _normalizeClass(["form-label fw-700", {'d-flex align-items-center': this.showCode}])
        }, [
          _createTextVNode(_toDisplayString(this.label) + " ", 1),
          (this.showCode)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(this.selectOptions.find(option => option.code === this.modelValue)?.description ?? ''), 1))
            : _createCommentVNode("", true)
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_dp_field, {
      modelValue: this.modelValue,
      as: "select",
      id: this.name,
      "aria-label": 'Select ' + this.name,
      name: this.name,
      placeholder: this.placeholder,
      class: _normalizeClass({
          'is-invalid': this.errors[this.name],
          'form-select': true,
          'form-select-sm': (this.inputSize === this.enums.inputSizeEnum.SM),
          'form-select-lg': (this.inputSize === this.enums.inputSizeEnum.LG),
        }),
      rules: this.rules,
      disabled: this.disabled,
      "onUpdate:modelValue": _ctx.updateValue
    }, {
      default: _withCtx(() => [
        (this.selectText)
          ? (_openBlock(), _createElementBlock("option", {
              key: 0,
              disabled: _ctx.firstEmptyItemDisabled
            }, _toDisplayString(this.selectText), 9, _hoisted_3))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.selectOptions, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            class: "fw-700",
            key: option.code,
            value: option.code
          }, _toDisplayString((this.showCode) ? option.code : option.description), 9, _hoisted_4))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "id", "aria-label", "name", "placeholder", "class", "rules", "disabled", "onUpdate:modelValue"]),
    (this.isFloating && this.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: this.name,
          class: "form-label"
        }, _toDisplayString(this.label), 9, _hoisted_5))
      : _createCommentVNode("", true),
    _createVNode(_component_dp_error_message, {
      class: "invalid-feedback d-block",
      name: this.name
    }, null, 8, ["name"]),
    (this.help)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(this.help), 1))
      : _createCommentVNode("", true)
  ], 2))
}