import {RestService} from "@/services/rest/RestService";
import {ProductLicenseEndpointsApi} from "@/api/pharma-master-license-mgmt";
import {ProductLicenseRestDtoModel} from "@/models/ProductLicenseRestDtoModel";
import {ProductLicenseOverviewRestDtoModel} from "@/models/ProductLicenseOverviewRestDtoModel";

export class ProductLicenseRestService extends RestService {

    private apiService: ProductLicenseEndpointsApi;

    constructor() {
        super();

        this.apiService = new ProductLicenseEndpointsApi(this.getDpAxiosInstance(), undefined, this.getApiGwUrl('/pharma-master'));
    }

    public async findCustomerProductLicenses(customerCode: string, filterIsActive?: boolean): Promise<ProductLicenseOverviewRestDtoModel[]> {
        // console.log("ProductLicenseRestService::findCustomerProductLicenses")
        const response = await this.apiService.findCustomerProductLicenses(customerCode, filterIsActive);

        return response.data as ProductLicenseOverviewRestDtoModel[];
    }

    public async findProductLicense(id: string): Promise<ProductLicenseRestDtoModel> {
        // console.log("ProductLicenseRestService::findProductLicense")
        const response = await this.apiService.findProductLicense(id);

        return response.data as ProductLicenseRestDtoModel;
    }

    public async createProductLicense(body: ProductLicenseRestDtoModel, customerCode: string): Promise<void> {
        await this.apiService.createProductLicense(body, customerCode);
    }

    public async updateProductLicense(body: ProductLicenseRestDtoModel, id: string): Promise<void> {
        await this.apiService.updateProductLicense(body, id);
    }
}