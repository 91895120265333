
import {defineComponent} from "vue";

export default defineComponent({
  name: "PharmacyInfoField",
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    }
  },
});
