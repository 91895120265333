<template>
  <div class="container-fluid d-flex flex-column flex-grow-1 overflow-hidden">
    <div class="row h-100">
      <the-side-bar-menu></the-side-bar-menu>
      <main class="ms-sm-auto col-lg-10 py-md-4 px-5 h-100 overflow-auto position-relative bg-light">
        <router-view></router-view>
      </main>
    </div>
  </div>

  <div class="p-2 env badge border border-primary">
    <p class="m-0 text-primary fw-bolder"> {{ appVersion }} - {{ config.environment }} - {{ config.buildtime }}</p>
  </div>

  <DialogsWrapper/>
</template>

<script>
import {config} from '@/helpers/fetch-config';
import {version} from '../package.json';
import {mapActions} from 'vuex';
import TheSideBarMenu from '@/components/layouts/TheSideBarMenu';

export default {
  name: 'App',
  components: {
    TheSideBarMenu,
  },
  data: () => ({
    config,
    appVersion: version
  }),
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  mounted() {
    this.doAutoLogin();
  },
  methods: {
    ...mapActions(['doAutoLogin']),
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/home');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.env {
  position: fixed;
  bottom: 8px;
  right: 18px;
  font-size: 10px;
  padding-right: 10px;
  background-color: #fff;
}
</style>