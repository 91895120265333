import {
    CustomerCriteriaRestDto,
    CustomerRestDto,
    CustomerOverviewRestDto, CustomerCodeRestDto
} from "@/api/pharma-customer/models";
import {CustomerCrudApiService} from "@/main";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";

export default {
    namespaced: true,
    state() {
        return {
            customer: {} as CustomerRestDto,
            customer_overview: [] as CustomerOverviewRestDto[],
        };
    },
    actions: {
        async searchCustomer(context: any, customer_code: string) {
            const response = await CustomerCrudApiService.getCustomer(customer_code);

            context.commit("setCustomer", response.data);
        },
        async searchCustomerOverview(context: any, criteria: CustomerCriteriaRestDto) {
            const response = await CustomerCrudApiService.findCustomerByCustomerCriteriaRestDto(criteria);

            // todo move to middleware
            response.data.forEach((customer_overview: CustomerOverviewRestDto) => {
                if (customer_overview.main_license === null || customer_overview.main_license === undefined) {
                    // console.log("adding empty license")
                    customer_overview.main_license = CodeDescriptionRestDtoModel.createWithDefaults();
                    // console.log("main_license", customer_overview.main_license)
                }
            });

            context.commit("setCustomerOverview", response.data);
        },
        async createCustomer(context: any, customer: CustomerRestDto): Promise<CustomerCodeRestDto> {
            const response = await CustomerCrudApiService.createCustomer(customer);

            const customers = context.getters["getCustomerOverview"];
            customers.unshift(customer);

            return response.data;
        },
        async updateCustomer(context: any, customer: CustomerRestDto) {
            await CustomerCrudApiService.updateCustomer(customer, customer.customer_code);

            const customers = context.getters["getCustomerOverview"];
            const foundIndex = customers.findIndex((x: CustomerRestDto) => x.customer_code === customer.customer_code);
            customers[foundIndex] = customer;
            context.commit('setCustomerOverview', customers);
        },
    },
    mutations: {
        setCustomer(state: any, payload: any) {
            state.customer = payload;
        },
        setCustomerOverview(state: any, payload: any) {
            state.customer_overview = payload;
        },
    },
    getters: {
        getCustomer(state: any) {
            return state.customer;
        },
        getCustomerOverview(state: any) {
            return state.customer_overview;
        },
        hasCustomers(state: any) {
            return state.customer_overview && state.customer_overview.length > 0;
        },
    },
};
