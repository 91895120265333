<script>
import AbstractInputControl from "@/components/UI/AbstractInputControl";

export default {
  extends: AbstractInputControl,
  methods: {
    determineInputType() {
      return 'number';
    },
    updateValue(value) {
      if (value) {
        value = parseFloat(value);
        if (this.min && value < this.min) {
          value = this.min;
        } else if (this.max && value > this.max) {
          value = this.max;
        }
      }

      this.$emit('update:modelValue', value);
    },
  }
};
</script>
