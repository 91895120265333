import {RestService} from "@/services/rest/RestService";
import {DefaultEndpointsApi} from "@/api/pharma-cms-only";
import {CreateClosingPeriodDefaultsRestDto} from "@/api/pharma-cms-only/models";

export class DpOnlyRestService extends RestService {

    private apiService: DefaultEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new DefaultEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().cmsUrl);
    }

    public async createDefaultClosingPeriods(until_year: number, customer_code?: string | null): Promise<void> {
        const payload = {
            customer_code: customer_code,
            until_year: until_year
        } as CreateClosingPeriodDefaultsRestDto;
        console.log('createDefaultClosingPeriods', payload);

        await this.apiService.createDefaultClosingPeriods(payload);
    }
}