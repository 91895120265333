import {RestService} from "@/services/rest/RestService";
import {EnumEndpointsApi} from "@/api/pharma-master-nvp";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

export class NameValuePairEnumRestService extends RestService {

    private apiService: EnumEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new EnumEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().masterUrl);
    }

    public async findNameValuePairNames(x_dp_module_code?: string, x_dp_customer_code?: string): Promise<CodeDescriptionRestDto[]> {
        const response = await this.apiService.getNvpNames(x_dp_module_code, x_dp_customer_code);

        return response.data;
    }
}