import {AuthRestService} from "@/services/rest/AuthRestService";
import {AuthContext} from "@/context/AuthContext";
import {AuthInfoModel} from "@/models/AuthInfoModel";
import {LocalStorageKeyEnum} from "@/models/enum/LocalStorageKeyEnum";
import {Storage} from "@/models/facade/Storage";

let timer;

function fetchJwt(idToken) {
    const tokenParts = idToken.split('.');
    const encodedPayload = tokenParts[1];
    const rawPayload = atob(encodedPayload);
    return  JSON.parse(rawPayload);
}

function buildUser(idToken) {
    const jwt = fetchJwt(idToken)
    return { first_name : jwt.name, last_name : jwt.family_name}
}

export default {
    state() {
        return {
            authInfo: AuthInfoModel.createWithDefaults()
        };
    },
    actions: {
        async login(context, payload) {
            return context.dispatch('auth', {
                ...payload,
                mode: 'login'
            });
        },
        async signup(context, payload) {
            return context.dispatch('auth', {
                ...payload,
                mode: 'signup'
            });
        },
        async auth(context, payload) {
            const mode = payload.mode;

            if (mode === 'signup') {
                throw new Error("Signup not supported.")
            }

            window.location.replace(
                AuthRestService.getInstance().getLoginUrl()
            );
        },
        async handleOAuth2Callback(context, payload) {
            let token;

            try {
                token = await AuthRestService.getInstance().getTokenByCode(payload.code, payload.state);
            } catch (error) {
                console.error(error);
                return context.dispatch('autoLogout');
            }

            AuthContext.setOAuth2Token(token);

            if (!AuthContext.isDpUser()) {
                context.dispatch("autoLogout");
            }

            Storage.local.set(LocalStorageKeyEnum.OAUTH2_TOKEN, JSON.stringify(token));

            timer = setTimeout(function () {
                context.dispatch('refreshAuth');
            }, ((token.expiresIn - 100) * 1000));

            context.commit("setAuthInfo", new AuthInfoModel(
                buildUser(token.idToken),
                token.accessToken,
                token.idToken,
                false
            ));
        },
        async refreshAuth(context) {
            let token;

            try {
                token = await AuthRestService.getInstance().refreshToken(AuthContext.getOAuth2TokenOrFail().refreshToken);
            } catch (error) {
                return context.dispatch('autoLogout');
            }

            AuthContext.setOAuth2Token(token);
            Storage.local.set(LocalStorageKeyEnum.OAUTH2_TOKEN, JSON.stringify(token));

            clearTimeout(timer);
            timer = setTimeout(function () {
                context.dispatch('refreshAuth');
            }, ((token.expiresIn - 100) * 1000));

            context.commit("setAuthInfo", new AuthInfoModel(
                buildUser(token.idToken),
                token.accessToken,
                token.idToken,
                false
            ));
        },
        doAutoLogin(context) {
            let oauth2Token = Storage.local.get(LocalStorageKeyEnum.OAUTH2_TOKEN, null);

            if (oauth2Token === null) {
                return;
            }

            oauth2Token = JSON.parse(oauth2Token);

            if (!oauth2Token) {
                context.dispatch("autoLogout");
                return;
            }

            const expiresIn = +oauth2Token.expiresAt - new Date().getTime() - (100 * 1000);

            if (expiresIn <= 0) {
                context.dispatch("autoLogout");
                return;
            }

            AuthContext.setOAuth2Token(oauth2Token);

            timer = setTimeout(function () {
                context.dispatch("refreshAuth");
            }, expiresIn);

            if (oauth2Token.accessToken && oauth2Token.idToken) {
                context.commit("setAuthInfo", new AuthInfoModel(
                    buildUser(oauth2Token.idToken),
                    oauth2Token.accessToken,
                    oauth2Token.idToken,
                    false
                ));
            }
        },
        logout(context) {
            AuthContext.clear();

            Storage.local.remove(LocalStorageKeyEnum.OAUTH2_TOKEN);
            Storage.local.remove(LocalStorageKeyEnum.ACCESS_TOKEN);
            Storage.local.remove(LocalStorageKeyEnum.ID_TOKEN);
            Storage.local.remove(LocalStorageKeyEnum.TOKEN_EXPIRATION);

            clearTimeout(timer);

            context.commit('setAuthInfo', {
                idToken: null,
                accessToken: null,
                user: null
            });
        },
        autoLogout(context) {
            context.dispatch('logout');
            context.commit('setAutoLogout');
        }
    },
    mutations: {
        setAuthInfo(state, payload) {
            state.idToken = payload.idToken;
            state.accessToken = payload.accessToken;
            state.user = payload.user;

            state.didAutoLogout = false;
        },
        setAutoLogout(state) {
            state.didAutoLogout = true;
        }
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getIdToken(state) {
            return state.idToken;
        },
        getAccessToken(state) {
            return state.accessToken;
        },
        isAuthenticated(state) {
            return !!state.idToken;
        },
        didAutoLogout(state) {
            return state.didAutoLogout;
        }
    }
};