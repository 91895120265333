
import {defineComponent} from "vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {MonthlyDashboardRowRestDto, MonthlyDashboardTypeOverviewRestDto} from "@/api/pharma-master-dashboard/models";
import {DashboardRestService} from "@/services/rest/pharma-master/DashboardRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/AlertError2.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {DpExceptions} from "@/api/models/DpExceptions";
import {Exception} from "@/api/interfaces";
import {DpModuleEnum} from "@/api/enums/dp-module-enum";
import InputText from "@/components/UI/InputText.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import {arrayHasContent} from "@/helpers/functions/array";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {getMonthsForLocale} from "@/helpers/functions/date";

let Lodash = require("lodash");

export default defineComponent({
    name: "Dashboard",
    components: {BaseSpinner, InputCheckboxBoolean, InputText, InputSelect, BaseTitle, AlertError2},
    data() {
        return {
            monthlyDashboards: [] as MonthlyDashboardRowRestDto[],
            monthlyDashboardPerCustomer: undefined as any,
            monthlyDashboardTypes: [] as MonthlyDashboardTypeOverviewRestDto[],
            dashboardUI: UIStateDto.createWithDefaults(),

            filters: {
                dashboardTypeCode: '' as string,
                xDpCustomerCode: undefined as string | undefined,
                xDpModuleCode: undefined as DpModuleEnum | undefined,
                searchCustomerName: undefined as string | undefined,
                filterCustomerCode: undefined as string | undefined,
                filterCustomerGroups: false as boolean
            }
        }
    },
    mounted() {
        this.reloadContent();
    },
    computed: {
        dashboardTypeOptions(): CodeDescriptionRestDtoModel[] {
            const options = [] as CodeDescriptionRestDtoModel[];

            this.monthlyDashboardTypes.forEach((dashboardType: MonthlyDashboardTypeOverviewRestDto) => {
                options.push(new CodeDescriptionRestDtoModel(dashboardType.type.code, dashboardType.type.description ?? ''));
            });

            return options;
        },
        hasMonthlyDashboards(): boolean {
            return arrayHasContent(this.monthlyDashboards);
        }
    },
    methods: {
        getMonthsForLocale,
        catchExceptions(exceptions: unknown): void {
            ErrorHandlerQueue
                .create()
                .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.dashboardUI as UIStateDto))
                .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.dashboardUI as UIStateDto))
                .catch(exceptions, true);
        },
        onDashboardTypeChanged(dashboardTypeCode: string): void {
            try {
                this.filters.dashboardTypeCode = dashboardTypeCode;

                const moduleCode = this.monthlyDashboardTypes
                    .find((monthlyDashboardType: MonthlyDashboardTypeOverviewRestDto) => monthlyDashboardType.type.code === dashboardTypeCode)
                    ?.dp_module_code as DpModuleEnum | undefined;
                if (!moduleCode) {
                    throw new DpExceptions([
                        {message: 'No dp_module_code found for this dashboard type.'} as Exception
                    ]);
                }
                this.filters.xDpModuleCode = moduleCode;
            } catch (exceptions: unknown) {
                this.catchExceptions(exceptions);
            }
        },
        async reloadContent(): Promise<void> {
            this.dashboardUI
                .clearError()
                .setNotReady();

            try {
                this.monthlyDashboardTypes = await DashboardRestService.getInstance()
                    .findMonthlyDashboardTypes();

                if (this.monthlyDashboardTypes.length) {
                    this.onDashboardTypeChanged(this.monthlyDashboardTypes[0].type.code);
                    await this.searchDashboard();
                }
            } catch (exceptions: unknown) {
                this.catchExceptions(exceptions);
            } finally {
                this.dashboardUI.setReady();
            }
        },
        async searchDashboard(): Promise<void> {
            this.dashboardUI
                .clearError()
                .setNotReady();

            try {
                // Search and sort by name and year respectively
                this.monthlyDashboards = (await DashboardRestService.getInstance()
                    .findMonthlyDashboardsForType(
                        this.filters.dashboardTypeCode,
                        this.filters.xDpCustomerCode,
                        this.filters.xDpModuleCode,
                        this.filters.searchCustomerName,
                        this.filters.filterCustomerCode,
                        this.filters.filterCustomerGroups
                    ))
                    .sort((a: MonthlyDashboardRowRestDto, b: MonthlyDashboardRowRestDto) => {
                        const nameA = a.customer.name.toUpperCase();
                        const nameB = b.customer.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        return 0;
                    })
                    .sort((a: MonthlyDashboardRowRestDto, b: MonthlyDashboardRowRestDto) => a.year - b.year);

                //Group by customer
                this.monthlyDashboardPerCustomer = Lodash.groupBy(this.monthlyDashboards, "customer.customer_code");
            } catch (exceptions: unknown) {
                this.catchExceptions(exceptions);
            } finally {
                this.dashboardUI.setReady();
            }
        }
    }
});
