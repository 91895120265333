/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 * Generated: Sat, 10 Jun 2023 06:46:51 +0000
 */
export enum TypeEnum {
    STRING = 'STRING',
    INT = 'INT',
    FLOAT = 'FLOAT',
    BOOLEAN = 'BOOLEAN',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    TIME = 'TIME',
    EMAIL = 'EMAIL',
}