/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Content Block API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BannerContentRestDto } from '../models';
import { BannerContentV2RestDto } from '../models';
import { BannerInSlotContentRestDto } from '../models';
import { BannerSlotContentRestDto } from '../models';
import { ButtonContentRestDto } from '../models';
import { ContactFormContentRestDto } from '../models';
import { ContentBlockResponseRestDto } from '../models';
import { ContentOverviewRestDto } from '../models';
import { DocumentContentRestDto } from '../models';
import { HtmlContentRestDto } from '../models';
import { ImageContentRestDto } from '../models';
import { PageReferencesContentRestDto } from '../models';
import { PharmacyInfoContentRestDto } from '../models';
import { ProductFilterContentRestDto } from '../models';
import { RowSeparatorContentRestDto } from '../models';
import { TeamInfoContentRestDto } from '../models';
import { TextContentRestDto } from '../models';
import { TextWithImageContentRestDto } from '../models';
import { TextWithTitleContentRestDto } from '../models';
/**
 * ContentBlockEndpointsApi - axios parameter creator
 * @export
 */
export const ContentBlockEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CB.41 Add a content block containing banner for the specified type.
         * @param {BannerContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockBanner: async (body: BannerContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockBanner.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockBanner.');
            }
            const localVarPath = `/content_block/v1/content_block/banner/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.46 Add a content block containing banner in slot for the specified type.
         * @param {BannerInSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockBannerInSlot: async (body: BannerInSlotContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockBannerInSlot.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockBannerInSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_in_slot/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.49 Add a content block containing banner slot for the specified type.
         * @param {BannerSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockBannerSlot: async (body: BannerSlotContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockBannerSlot.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockBannerSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_slot/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.41 Add a content block containing banner for the specified type.
         * @param {BannerContentV2RestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockBannerV2: async (body: BannerContentV2RestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockBannerV2.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockBannerV2.');
            }
            const localVarPath = `/content_block/v2/content_block/banner/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.69 Add a content block containing button for the specified type.
         * @param {ButtonContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockButton: async (body: ButtonContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockButton.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockButton.');
            }
            const localVarPath = `/content_block/v1/content_block/button/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.78 Add a content block containing contact form for the specified type.
         * @param {ContactFormContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockContactForm: async (body: ContactFormContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockContactForm.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockContactForm.');
            }
            const localVarPath = `/content_block/v1/content_block/contact_form/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.58 Add a content block containing a document for the specified type.
         * @param {DocumentContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockDocument: async (body: DocumentContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockDocument.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockDocument.');
            }
            const localVarPath = `/content_block/v1/content_block/document/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.55 Add a content block containing html for the specified type.
         * @param {HtmlContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockHtml: async (body: HtmlContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockHtml.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockHtml.');
            }
            const localVarPath = `/content_block/v1/content_block/html/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.14 Add a content block containing an image_key for the specified type.
         * @param {ImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockImage: async (body: ImageContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockImage.');
            }
            const localVarPath = `/content_block/v1/content_block/image/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.72 Add a content block containing page references for the specified type.
         * @param {PageReferencesContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockPageReferences: async (body: PageReferencesContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockPageReferences.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockPageReferences.');
            }
            const localVarPath = `/content_block/v1/content_block/page_references/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.64 Add a content block containing pharmacy info for the specified type.
         * @param {PharmacyInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockPharmacyInfo: async (body: PharmacyInfoContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockPharmacyInfo.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockPharmacyInfo.');
            }
            const localVarPath = `/content_block/v1/content_block/pharmacy_info/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.61 Add a content block containing a product filter for the specified type.
         * @param {ProductFilterContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockProductFilter: async (body: ProductFilterContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockProductFilter.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockProductFilter.');
            }
            const localVarPath = `/content_block/v1/content_block/product_filter/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.74 Add a content block containing row separator for the specified type.
         * @param {RowSeparatorContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockRowSeparator: async (body: RowSeparatorContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockRowSeparator.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockRowSeparator.');
            }
            const localVarPath = `/content_block/v1/content_block/row_separator/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.75 Add a content block containing team info for the specified type.
         * @param {TeamInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockTeamInfo: async (body: TeamInfoContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockTeamInfo.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockTeamInfo.');
            }
            const localVarPath = `/content_block/v1/content_block/team_info/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.10 Add a content block containing text for the specified type.
         * @param {TextContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockText: async (body: TextContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockText.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockText.');
            }
            const localVarPath = `/content_block/v1/content_block/text/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.22 Add a content block containing text with title for the specified type.
         * @param {TextWithImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockTextWithImage: async (body: TextWithImageContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockTextWithImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockTextWithImage.');
            }
            const localVarPath = `/content_block/v1/content_block/text_with_image/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.18 Add a content block containing text with title for the specified type.
         * @param {TextWithTitleContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalContentBlockTextWithTitle: async (body: TextWithTitleContentRestDto, type: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createNormalContentBlockTextWithTitle.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createNormalContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/content_block/text_with_title/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.25 Delete a content block with the specified type and id.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNormalContentBlock: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling deleteNormalContentBlock.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteNormalContentBlock.');
            }
            const localVarPath = `/content_block/v1/content_block/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.52 Retrieve an overview of banner in slot elements for the specified banner slot.
         * @param {string} type 
         * @param {string} position_code 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {string} [filter_library_code] Filter the results to the content blocks that belong to the specified content library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBannerInSlotsForSlot: async (type: string, position_code: string, x_dp_customer_code?: string, filter_status_code?: string, filter_library_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling findBannerInSlotsForSlot.');
            }
            // verify required parameter 'position_code' is not null or undefined
            if (position_code === null || position_code === undefined) {
                throw new RequiredError('position_code','Required parameter position_code was null or undefined when calling findBannerInSlotsForSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_slot/{type}/{position_code}/banner_in_slot`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"position_code"}}`, encodeURIComponent(String(position_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (!!filter_library_code || typeof(filter_library_code) === "boolean") {
                localVarQueryParameter['filter_library_code'] = filter_library_code;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.26 Retrieve an overview of content blocks for the specified type.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {string} [filter_library_code] Filter the results to the content blocks that belong to the specified content library.
         * @param {boolean} [filter_linked] Filter the results based on the existance of an active link to another conten block.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findNormalContentBlocks: async (type: string, x_dp_customer_code?: string, filter_status_code?: string, filter_library_code?: string, filter_linked?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling findNormalContentBlocks.');
            }
            const localVarPath = `/content_block/v1/content_block/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!filter_status_code || typeof(filter_status_code) === "boolean") {
                localVarQueryParameter['filter_status_code'] = filter_status_code;
            }

            if (!!filter_library_code || typeof(filter_library_code) === "boolean") {
                localVarQueryParameter['filter_library_code'] = filter_library_code;
            }

            if (!!filter_linked || typeof(filter_linked) === "boolean") {
                localVarQueryParameter['filter_linked'] = filter_linked;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.43 Retrieve a content block containing banner for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockBanner: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockBanner.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockBanner.');
            }
            const localVarPath = `/content_block/v1/content_block/banner/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.48 Retrieve a content block containing banner in slot for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockBannerInSlot: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockBannerInSlot.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockBannerInSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_in_slot/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.51 Retrieve a content block containing banner slot for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockBannerSlot: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockBannerSlot.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockBannerSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_slot/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.43 Retrieve a content block containing banner for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockBannerV2: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockBannerV2.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockBannerV2.');
            }
            const localVarPath = `/content_block/v2/content_block/banner/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.68 Retrieve a content block containing button for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockButton: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockButton.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockButton.');
            }
            const localVarPath = `/content_block/v1/content_block/button/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.80 Retrieve a content block containing contact form for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockContactForm: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockContactForm.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockContactForm.');
            }
            const localVarPath = `/content_block/v1/content_block/contact_form/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.60 Retrieve a content block containing a document for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockDocument: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockDocument.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockDocument.');
            }
            const localVarPath = `/content_block/v1/content_block/document/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.57 Retrieve a content block containing HTML for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockHtml: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockHtml.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockHtml.');
            }
            const localVarPath = `/content_block/v1/content_block/html/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.16 Retrieve a content block containing an image_key for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockImage: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockImage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockImage.');
            }
            const localVarPath = `/content_block/v1/content_block/image/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.71 Retrieve a content block containing page references for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockPageReferences: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockPageReferences.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockPageReferences.');
            }
            const localVarPath = `/content_block/v1/content_block/page_references/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.66 Retrieve a content block containing pharmacy info for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockPharmacyInfo: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockPharmacyInfo.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockPharmacyInfo.');
            }
            const localVarPath = `/content_block/v1/content_block/pharmacy_info/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.63 Retrieve a content block containing a product filter for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockProductFilter: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockProductFilter.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockProductFilter.');
            }
            const localVarPath = `/content_block/v1/content_block/product_filter/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.73 Retrieve a content block containing row separator for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockRowSeparator: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockRowSeparator.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockRowSeparator.');
            }
            const localVarPath = `/content_block/v1/content_block/row_separator/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.77 Retrieve a content block containing team info for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockTeamInfo: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockTeamInfo.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockTeamInfo.');
            }
            const localVarPath = `/content_block/v1/content_block/team_info/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.12 Retrieve a content block containing text for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockText: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockText.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockText.');
            }
            const localVarPath = `/content_block/v1/content_block/text/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.24 Retrieve a content block containing text with image for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockTextWithImage: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockTextWithImage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockTextWithImage.');
            }
            const localVarPath = `/content_block/v1/content_block/text_with_image/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.20 Retrieve a content block containing text with title for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNormalContentBlockTextWithTitle: async (type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getNormalContentBlockTextWithTitle.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getNormalContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/content_block/text_with_title/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.42 Update a content block containing banner for the specified type.
         * @param {BannerContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockBanner: async (body: BannerContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockBanner.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockBanner.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockBanner.');
            }
            const localVarPath = `/content_block/v1/content_block/banner/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.47 Update a content block containing banner in slot for the specified type.
         * @param {BannerInSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockBannerInSlot: async (body: BannerInSlotContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockBannerInSlot.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockBannerInSlot.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockBannerInSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_in_slot/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.50 Update a content block containing banner slot for the specified type.
         * @param {BannerSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockBannerSlot: async (body: BannerSlotContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockBannerSlot.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockBannerSlot.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockBannerSlot.');
            }
            const localVarPath = `/content_block/v1/content_block/banner_slot/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.42 Update a content block containing banner for the specified type.
         * @param {BannerContentV2RestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockBannerV2: async (body: BannerContentV2RestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockBannerV2.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockBannerV2.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockBannerV2.');
            }
            const localVarPath = `/content_block/v2/content_block/banner/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.67 Update a content block containing button for the specified type.
         * @param {ButtonContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockButton: async (body: ButtonContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockButton.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockButton.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockButton.');
            }
            const localVarPath = `/content_block/v1/content_block/button/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.79 Update a content block containing contact form for the specified type.
         * @param {ContactFormContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockContactForm: async (body: ContactFormContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockContactForm.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockContactForm.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockContactForm.');
            }
            const localVarPath = `/content_block/v1/content_block/contact_form/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.59 Update a content block containing a document for the specified type.
         * @param {DocumentContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockDocument: async (body: DocumentContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockDocument.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockDocument.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockDocument.');
            }
            const localVarPath = `/content_block/v1/content_block/document/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.56 Update a content block containing HTML for the specified type.
         * @param {HtmlContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockHtml: async (body: HtmlContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockHtml.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockHtml.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockHtml.');
            }
            const localVarPath = `/content_block/v1/content_block/html/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.15 Update a content block containing an image_key for the specified type.
         * @param {ImageContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockImage: async (body: ImageContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockImage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockImage.');
            }
            const localVarPath = `/content_block/v1/content_block/image/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.70 Update a content block containing page references for the specified type.
         * @param {PageReferencesContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockPageReferences: async (body: PageReferencesContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockPageReferences.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockPageReferences.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockPageReferences.');
            }
            const localVarPath = `/content_block/v1/content_block/page_references/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.65 Update a content block containing pharmacy info for the specified type.
         * @param {PharmacyInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockPharmacyInfo: async (body: PharmacyInfoContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockPharmacyInfo.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockPharmacyInfo.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockPharmacyInfo.');
            }
            const localVarPath = `/content_block/v1/content_block/pharmacy_info/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.62 Update a content block containing a product filter for the specified type.
         * @param {ProductFilterContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockProductFilter: async (body: ProductFilterContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockProductFilter.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockProductFilter.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockProductFilter.');
            }
            const localVarPath = `/content_block/v1/content_block/product_filter/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.73 Update a content block containing row separator for the specified type.
         * @param {RowSeparatorContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockRowSeparator: async (body: RowSeparatorContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockRowSeparator.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockRowSeparator.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockRowSeparator.');
            }
            const localVarPath = `/content_block/v1/content_block/row_separator/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.76 Update a content block containing team info for the specified type.
         * @param {TeamInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockTeamInfo: async (body: TeamInfoContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockTeamInfo.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockTeamInfo.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockTeamInfo.');
            }
            const localVarPath = `/content_block/v1/content_block/team_info/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.11 Update a content block containing text for the specified type.
         * @param {TextContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockText: async (body: TextContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockText.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockText.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockText.');
            }
            const localVarPath = `/content_block/v1/content_block/text/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.23 Update a content block containing text with image for the specified type.
         * @param {TextWithImageContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockTextWithImage: async (body: TextWithImageContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockTextWithImage.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockTextWithImage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockTextWithImage.');
            }
            const localVarPath = `/content_block/v1/content_block/text_with_image/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.19 Update a content block containing text with title for the specified type.
         * @param {TextWithTitleContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNormalContentBlockTextWithTitle: async (body: TextWithTitleContentRestDto, type: string, id: string, x_dp_customer_code?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNormalContentBlockTextWithTitle.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateNormalContentBlockTextWithTitle.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateNormalContentBlockTextWithTitle.');
            }
            const localVarPath = `/content_block/v1/content_block/text_with_title/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentBlockEndpointsApi - functional programming interface
 * @export
 */
export const ContentBlockEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.CB.41 Add a content block containing banner for the specified type.
         * @param {BannerContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockBanner(_axios: AxiosInstance, body: BannerContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockBanner(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.46 Add a content block containing banner in slot for the specified type.
         * @param {BannerInSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockBannerInSlot(_axios: AxiosInstance, body: BannerInSlotContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockBannerInSlot(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.49 Add a content block containing banner slot for the specified type.
         * @param {BannerSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockBannerSlot(_axios: AxiosInstance, body: BannerSlotContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockBannerSlot(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.41 Add a content block containing banner for the specified type.
         * @param {BannerContentV2RestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockBannerV2(_axios: AxiosInstance, body: BannerContentV2RestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockBannerV2(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.69 Add a content block containing button for the specified type.
         * @param {ButtonContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockButton(_axios: AxiosInstance, body: ButtonContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockButton(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.78 Add a content block containing contact form for the specified type.
         * @param {ContactFormContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockContactForm(_axios: AxiosInstance, body: ContactFormContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockContactForm(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.58 Add a content block containing a document for the specified type.
         * @param {DocumentContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockDocument(_axios: AxiosInstance, body: DocumentContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockDocument(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.55 Add a content block containing html for the specified type.
         * @param {HtmlContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockHtml(_axios: AxiosInstance, body: HtmlContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockHtml(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.14 Add a content block containing an image_key for the specified type.
         * @param {ImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockImage(_axios: AxiosInstance, body: ImageContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockImage(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.72 Add a content block containing page references for the specified type.
         * @param {PageReferencesContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockPageReferences(_axios: AxiosInstance, body: PageReferencesContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockPageReferences(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.64 Add a content block containing pharmacy info for the specified type.
         * @param {PharmacyInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockPharmacyInfo(_axios: AxiosInstance, body: PharmacyInfoContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockPharmacyInfo(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.61 Add a content block containing a product filter for the specified type.
         * @param {ProductFilterContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockProductFilter(_axios: AxiosInstance, body: ProductFilterContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockProductFilter(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.74 Add a content block containing row separator for the specified type.
         * @param {RowSeparatorContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockRowSeparator(_axios: AxiosInstance, body: RowSeparatorContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockRowSeparator(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.75 Add a content block containing team info for the specified type.
         * @param {TeamInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockTeamInfo(_axios: AxiosInstance, body: TeamInfoContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockTeamInfo(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.10 Add a content block containing text for the specified type.
         * @param {TextContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockText(_axios: AxiosInstance, body: TextContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockText(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.22 Add a content block containing text with title for the specified type.
         * @param {TextWithImageContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockTextWithImage(_axios: AxiosInstance, body: TextWithImageContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockTextWithImage(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.18 Add a content block containing text with title for the specified type.
         * @param {TextWithTitleContentRestDto} body 
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalContentBlockTextWithTitle(_axios: AxiosInstance, body: TextWithTitleContentRestDto, type: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContentBlockResponseRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).createNormalContentBlockTextWithTitle(body, type, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.25 Delete a content block with the specified type and id.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNormalContentBlock(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).deleteNormalContentBlock(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.52 Retrieve an overview of banner in slot elements for the specified banner slot.
         * @param {string} type 
         * @param {string} position_code 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {string} [filter_library_code] Filter the results to the content blocks that belong to the specified content library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBannerInSlotsForSlot(_axios: AxiosInstance, type: string, position_code: string, x_dp_customer_code?: string, filter_status_code?: string, filter_library_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentOverviewRestDto>>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).findBannerInSlotsForSlot(type, position_code, x_dp_customer_code, filter_status_code, filter_library_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.26 Retrieve an overview of content blocks for the specified type.
         * @param {string} type 
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [filter_status_code] Filter the results based on a match on status_code.
         * @param {string} [filter_library_code] Filter the results to the content blocks that belong to the specified content library.
         * @param {boolean} [filter_linked] Filter the results based on the existance of an active link to another conten block.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findNormalContentBlocks(_axios: AxiosInstance, type: string, x_dp_customer_code?: string, filter_status_code?: string, filter_library_code?: string, filter_linked?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContentOverviewRestDto>>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).findNormalContentBlocks(type, x_dp_customer_code, filter_status_code, filter_library_code, filter_linked, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.43 Retrieve a content block containing banner for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockBanner(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockBanner(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.48 Retrieve a content block containing banner in slot for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockBannerInSlot(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerInSlotContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockBannerInSlot(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.51 Retrieve a content block containing banner slot for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockBannerSlot(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerSlotContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockBannerSlot(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.43 Retrieve a content block containing banner for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockBannerV2(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BannerContentV2RestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockBannerV2(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.68 Retrieve a content block containing button for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockButton(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ButtonContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockButton(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.80 Retrieve a content block containing contact form for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockContactForm(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactFormContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockContactForm(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.60 Retrieve a content block containing a document for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockDocument(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockDocument(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.57 Retrieve a content block containing HTML for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockHtml(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HtmlContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockHtml(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.16 Retrieve a content block containing an image_key for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockImage(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockImage(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.71 Retrieve a content block containing page references for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockPageReferences(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageReferencesContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockPageReferences(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.66 Retrieve a content block containing pharmacy info for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockPharmacyInfo(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PharmacyInfoContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockPharmacyInfo(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.63 Retrieve a content block containing a product filter for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockProductFilter(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductFilterContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockProductFilter(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.73 Retrieve a content block containing row separator for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockRowSeparator(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RowSeparatorContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockRowSeparator(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.77 Retrieve a content block containing team info for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockTeamInfo(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInfoContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockTeamInfo(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.12 Retrieve a content block containing text for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockText(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockText(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.24 Retrieve a content block containing text with image for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockTextWithImage(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextWithImageContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockTextWithImage(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.20 Retrieve a content block containing text with title for the specified type.
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNormalContentBlockTextWithTitle(_axios: AxiosInstance, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextWithTitleContentRestDto>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).getNormalContentBlockTextWithTitle(type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.42 Update a content block containing banner for the specified type.
         * @param {BannerContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockBanner(_axios: AxiosInstance, body: BannerContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockBanner(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.47 Update a content block containing banner in slot for the specified type.
         * @param {BannerInSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockBannerInSlot(_axios: AxiosInstance, body: BannerInSlotContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockBannerInSlot(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.50 Update a content block containing banner slot for the specified type.
         * @param {BannerSlotContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockBannerSlot(_axios: AxiosInstance, body: BannerSlotContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockBannerSlot(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.42 Update a content block containing banner for the specified type.
         * @param {BannerContentV2RestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockBannerV2(_axios: AxiosInstance, body: BannerContentV2RestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockBannerV2(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.67 Update a content block containing button for the specified type.
         * @param {ButtonContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockButton(_axios: AxiosInstance, body: ButtonContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockButton(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.79 Update a content block containing contact form for the specified type.
         * @param {ContactFormContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockContactForm(_axios: AxiosInstance, body: ContactFormContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockContactForm(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.59 Update a content block containing a document for the specified type.
         * @param {DocumentContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockDocument(_axios: AxiosInstance, body: DocumentContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockDocument(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.56 Update a content block containing HTML for the specified type.
         * @param {HtmlContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockHtml(_axios: AxiosInstance, body: HtmlContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockHtml(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.15 Update a content block containing an image_key for the specified type.
         * @param {ImageContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockImage(_axios: AxiosInstance, body: ImageContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockImage(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.70 Update a content block containing page references for the specified type.
         * @param {PageReferencesContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockPageReferences(_axios: AxiosInstance, body: PageReferencesContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockPageReferences(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.65 Update a content block containing pharmacy info for the specified type.
         * @param {PharmacyInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockPharmacyInfo(_axios: AxiosInstance, body: PharmacyInfoContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockPharmacyInfo(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.62 Update a content block containing a product filter for the specified type.
         * @param {ProductFilterContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockProductFilter(_axios: AxiosInstance, body: ProductFilterContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockProductFilter(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.73 Update a content block containing row separator for the specified type.
         * @param {RowSeparatorContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockRowSeparator(_axios: AxiosInstance, body: RowSeparatorContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockRowSeparator(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.76 Update a content block containing team info for the specified type.
         * @param {TeamInfoContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockTeamInfo(_axios: AxiosInstance, body: TeamInfoContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockTeamInfo(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.11 Update a content block containing text for the specified type.
         * @param {TextContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockText(_axios: AxiosInstance, body: TextContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockText(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.23 Update a content block containing text with image for the specified type.
         * @param {TextWithImageContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockTextWithImage(_axios: AxiosInstance, body: TextWithImageContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockTextWithImage(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.CB.19 Update a content block containing text with title for the specified type.
         * @param {TextWithTitleContentRestDto} body 
         * @param {string} type 
         * @param {string} id The unique identifier of this content block.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNormalContentBlockTextWithTitle(_axios: AxiosInstance, body: TextWithTitleContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContentBlockEndpointsApiAxiosParamCreator(configuration).updateNormalContentBlockTextWithTitle(body, type, id, x_dp_customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContentBlockEndpointsApi - object-oriented interface
 * @export
 * @class ContentBlockEndpointsApi
 * @extends {BaseAPI}
 */
export class ContentBlockEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.CB.41 Add a content block containing banner for the specified type.
     * @param {BannerContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockBanner(body: BannerContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockBanner(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.46 Add a content block containing banner in slot for the specified type.
     * @param {BannerInSlotContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockBannerInSlot(body: BannerInSlotContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockBannerInSlot(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.49 Add a content block containing banner slot for the specified type.
     * @param {BannerSlotContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockBannerSlot(body: BannerSlotContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockBannerSlot(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.41 Add a content block containing banner for the specified type.
     * @param {BannerContentV2RestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockBannerV2(body: BannerContentV2RestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockBannerV2(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.69 Add a content block containing button for the specified type.
     * @param {ButtonContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockButton(body: ButtonContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockButton(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.78 Add a content block containing contact form for the specified type.
     * @param {ContactFormContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockContactForm(body: ContactFormContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockContactForm(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.58 Add a content block containing a document for the specified type.
     * @param {DocumentContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockDocument(body: DocumentContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockDocument(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.55 Add a content block containing html for the specified type.
     * @param {HtmlContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockHtml(body: HtmlContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockHtml(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.14 Add a content block containing an image_key for the specified type.
     * @param {ImageContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockImage(body: ImageContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockImage(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.72 Add a content block containing page references for the specified type.
     * @param {PageReferencesContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockPageReferences(body: PageReferencesContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockPageReferences(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.64 Add a content block containing pharmacy info for the specified type.
     * @param {PharmacyInfoContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockPharmacyInfo(body: PharmacyInfoContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockPharmacyInfo(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.61 Add a content block containing a product filter for the specified type.
     * @param {ProductFilterContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockProductFilter(body: ProductFilterContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockProductFilter(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.74 Add a content block containing row separator for the specified type.
     * @param {RowSeparatorContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockRowSeparator(body: RowSeparatorContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockRowSeparator(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.75 Add a content block containing team info for the specified type.
     * @param {TeamInfoContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockTeamInfo(body: TeamInfoContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockTeamInfo(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.10 Add a content block containing text for the specified type.
     * @param {TextContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockText(body: TextContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockText(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.22 Add a content block containing text with title for the specified type.
     * @param {TextWithImageContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockTextWithImage(body: TextWithImageContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockTextWithImage(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.18 Add a content block containing text with title for the specified type.
     * @param {TextWithTitleContentRestDto} body 
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public createNormalContentBlockTextWithTitle(body: TextWithTitleContentRestDto, type: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).createNormalContentBlockTextWithTitle(this.axios, body, type, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.25 Delete a content block with the specified type and id.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public deleteNormalContentBlock(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).deleteNormalContentBlock(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.52 Retrieve an overview of banner in slot elements for the specified banner slot.
     * @param {string} type 
     * @param {string} position_code 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {string} [filter_library_code] Filter the results to the content blocks that belong to the specified content library.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public findBannerInSlotsForSlot(type: string, position_code: string, x_dp_customer_code?: string, filter_status_code?: string, filter_library_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).findBannerInSlotsForSlot(this.axios, type, position_code, x_dp_customer_code, filter_status_code, filter_library_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.26 Retrieve an overview of content blocks for the specified type.
     * @param {string} type 
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [filter_status_code] Filter the results based on a match on status_code.
     * @param {string} [filter_library_code] Filter the results to the content blocks that belong to the specified content library.
     * @param {boolean} [filter_linked] Filter the results based on the existance of an active link to another conten block.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public findNormalContentBlocks(type: string, x_dp_customer_code?: string, filter_status_code?: string, filter_library_code?: string, filter_linked?: boolean, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).findNormalContentBlocks(this.axios, type, x_dp_customer_code, filter_status_code, filter_library_code, filter_linked, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.43 Retrieve a content block containing banner for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockBanner(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockBanner(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.48 Retrieve a content block containing banner in slot for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockBannerInSlot(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockBannerInSlot(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.51 Retrieve a content block containing banner slot for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockBannerSlot(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockBannerSlot(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.43 Retrieve a content block containing banner for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockBannerV2(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockBannerV2(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.68 Retrieve a content block containing button for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockButton(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockButton(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.80 Retrieve a content block containing contact form for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockContactForm(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockContactForm(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.60 Retrieve a content block containing a document for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockDocument(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockDocument(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.57 Retrieve a content block containing HTML for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockHtml(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockHtml(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.16 Retrieve a content block containing an image_key for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockImage(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockImage(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.71 Retrieve a content block containing page references for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockPageReferences(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockPageReferences(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.66 Retrieve a content block containing pharmacy info for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockPharmacyInfo(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockPharmacyInfo(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.63 Retrieve a content block containing a product filter for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockProductFilter(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockProductFilter(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.73 Retrieve a content block containing row separator for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockRowSeparator(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockRowSeparator(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.77 Retrieve a content block containing team info for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockTeamInfo(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockTeamInfo(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.12 Retrieve a content block containing text for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockText(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockText(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.24 Retrieve a content block containing text with image for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockTextWithImage(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockTextWithImage(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.20 Retrieve a content block containing text with title for the specified type.
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public getNormalContentBlockTextWithTitle(type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).getNormalContentBlockTextWithTitle(this.axios, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.42 Update a content block containing banner for the specified type.
     * @param {BannerContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockBanner(body: BannerContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockBanner(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.47 Update a content block containing banner in slot for the specified type.
     * @param {BannerInSlotContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockBannerInSlot(body: BannerInSlotContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockBannerInSlot(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.50 Update a content block containing banner slot for the specified type.
     * @param {BannerSlotContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockBannerSlot(body: BannerSlotContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockBannerSlot(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.42 Update a content block containing banner for the specified type.
     * @param {BannerContentV2RestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockBannerV2(body: BannerContentV2RestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockBannerV2(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.67 Update a content block containing button for the specified type.
     * @param {ButtonContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockButton(body: ButtonContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockButton(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.79 Update a content block containing contact form for the specified type.
     * @param {ContactFormContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockContactForm(body: ContactFormContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockContactForm(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.59 Update a content block containing a document for the specified type.
     * @param {DocumentContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockDocument(body: DocumentContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockDocument(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.56 Update a content block containing HTML for the specified type.
     * @param {HtmlContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockHtml(body: HtmlContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockHtml(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.15 Update a content block containing an image_key for the specified type.
     * @param {ImageContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockImage(body: ImageContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockImage(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.70 Update a content block containing page references for the specified type.
     * @param {PageReferencesContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockPageReferences(body: PageReferencesContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockPageReferences(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.65 Update a content block containing pharmacy info for the specified type.
     * @param {PharmacyInfoContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockPharmacyInfo(body: PharmacyInfoContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockPharmacyInfo(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.62 Update a content block containing a product filter for the specified type.
     * @param {ProductFilterContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockProductFilter(body: ProductFilterContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockProductFilter(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.73 Update a content block containing row separator for the specified type.
     * @param {RowSeparatorContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockRowSeparator(body: RowSeparatorContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockRowSeparator(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.76 Update a content block containing team info for the specified type.
     * @param {TeamInfoContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockTeamInfo(body: TeamInfoContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockTeamInfo(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.11 Update a content block containing text for the specified type.
     * @param {TextContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockText(body: TextContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockText(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.23 Update a content block containing text with image for the specified type.
     * @param {TextWithImageContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockTextWithImage(body: TextWithImageContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockTextWithImage(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.CB.19 Update a content block containing text with title for the specified type.
     * @param {TextWithTitleContentRestDto} body 
     * @param {string} type 
     * @param {string} id The unique identifier of this content block.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentBlockEndpointsApi
     */
     public updateNormalContentBlockTextWithTitle(body: TextWithTitleContentRestDto, type: string, id: string, x_dp_customer_code?: string, options?: any) {
        return ContentBlockEndpointsApiFp(this.configuration).updateNormalContentBlockTextWithTitle(this.axios, body, type, id, x_dp_customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
