import {NameValuePairCriteriaPayload} from "@/models/payload/NameValuePairCriteriaPayload";


export default {
    namespaced: true,
    state() {
        return {
            nvp_criteria_payload: NameValuePairCriteriaPayload.createWithDefaults(),
        }
    },
    actions: {
        setNvpCriteriaPayload(context: any, payload: NameValuePairCriteriaPayload) {
            context.commit("setNvpCriteriaPayload", payload);
        },
        setNvpCriteriaModuleCode(context: any, xDpModuleCode: string) {
            context.commit("setNvpCriteriaModuleCode", xDpModuleCode);
        }
    },
    mutations: {
        setNvpCriteriaPayload(state: any, payload: NameValuePairCriteriaPayload) {
            state.nvp_criteria_payload = payload;
        },
        setNvpCriteriaModuleCode(state: any, xDpModuleCode: string) {
            state.nvp_criteria_payload.x_dp_module_code = xDpModuleCode;
        },
    },
    getters: {
        getNvpCriteriaPayload: (state: any): NameValuePairCriteriaPayload => {
            return state.nvp_criteria_payload;
        },
    }
}