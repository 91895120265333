<template>
  <div class="card-body">
    <slot>
      <div v-if="title || text" class="card-body">
        <h5 v-if="title" class="card-title">{{ title }}</h5>
        <p v-if="text" v-html="text" class="card-text"></p>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "BaseCardBody",
  props: {
    text: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  }
};
</script>