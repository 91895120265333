
import {defineComponent} from 'vue';

export default defineComponent({
  name: "JsonImport",
  emits: ['import'],
  props: {
    modalDomId: String,
  },
  data: () => ({
    json: "",
  }),
  methods: {
    import(): void {
      this.$emit('import', this.json);
    },
    prettify(): void {
      try {
        const parsedJson = JSON.parse(this.json);
        this.json = JSON.stringify(parsedJson, null, 4);
      } catch (error) {
        // ignore error
      }
    }
  }
})
