import {
    TextProcessingTaskCriteriaRestDtoModel
} from "@/models/api/pharma-tp-task/TextProcessingTaskCriteriaRestDtoModel";
import {PagingPayload} from "@/models/payload/PagingPayload";

export default {
    namespaced: true,
    state() {
        return {
            text_processing_criteria: TextProcessingTaskCriteriaRestDtoModel.createWithDefaults(),
            text_processing_paging: PagingPayload.createWithDefaults(),
        }
    },
    actions: {
        clearTextProcessingCriteria(context: any) {
            context.commit("clearTextProcessingCriteria");
        },
        setTextProcessingPaging(context: any, payload: PagingPayload) {
            context.commit("setTextProcessingPaging", payload);
        },
    },
    mutations: {
        clearTextProcessingCriteria(state: any) {
            state.text_processing_criteria = TextProcessingTaskCriteriaRestDtoModel.createWithDefaults();
            state.text_processing_criteria.page = state.text_processing_paging.number;
            state.text_processing_criteria.page_size = state.text_processing_paging.size;
        },
        setTextProcessingPaging(state: any, payload: PagingPayload) {
            state.text_processing_paging = payload;
            state.text_processing_criteria.page = state.text_processing_paging.number;
            state.text_processing_criteria.page_size = state.text_processing_paging.size;
        },
    },
    getters: {
        getTextProcessingCriteria: (state: any): TextProcessingTaskCriteriaRestDtoModel => {
            return state.text_processing_criteria;
        },
        getTextProcessingPaging: (state: any): PagingPayload => {
            return state.text_processing_paging;
        },
    }
}