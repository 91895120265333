import {RestService} from "@/services/rest/RestService";
import {PharmacistUserManagementEndpointsApi} from "@/api/pharma-idm-pharmacist-user";
import {PharmacistUserResultsRestDtoModel} from "@/models/PharmacistUserResultsRestDtoModel";
import {PharmacistUserRestDtoModel} from "@/models/PharmacistUserRestDtoModel";
import {PharmacistUserPasswordRestDto} from "@/api/pharma-idm-pharmacist-user/models";
import {
    CreatePharmacistUserRestDtoModel
} from "@/models/api/pharma-idm-pharmacist-user/CreatePharmacistUserRestDtoModel";

export class IdmPharmacistUserRestService extends RestService {

    private apiService: PharmacistUserManagementEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new PharmacistUserManagementEndpointsApi(this.getDpAxiosInstance(), undefined, this.getApiGwUrl('/pharma-idm'));
    }

    public async findPharmacistUsers(customerCode?: string): Promise<PharmacistUserResultsRestDtoModel> {
        const response = await this.apiService.findPharmacistUsers(customerCode);

        return response.data as PharmacistUserResultsRestDtoModel;
    }

    public async getPharmacistUser(userId: string): Promise<PharmacistUserRestDtoModel> {
        const response = await this.apiService.getPharmacistUserDetail(userId);

        return response.data as PharmacistUserRestDtoModel;
    }

    public async createPharmacistUserPassword(dto: CreatePharmacistUserRestDtoModel): Promise<void> {
        await this.apiService.createPharmacistUsers(dto);
    }

    public async updatePharmacistUserPassword(userId: string, password: string): Promise<void> {
        const body = {
            password: password,
        } as PharmacistUserPasswordRestDto;

        await this.apiService.updatePharmacistUserPassword(body, userId);
    }

    public async enablePharmacistUser(userId: string): Promise<void> {
        await this.apiService.enablePharmacistUser(userId);
    }

    public async disablePharmacistUser(userId: string): Promise<void> {
        await this.apiService.disablePharmacistUser(userId);
    }
}