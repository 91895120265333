<template>
  <button v-if="mode === 'open'"
          :class="this.class"
          type="button"
          data-bs-toggle="modal"
          :data-bs-target="'#' + modalId"
  >
    <slot></slot>
  </button>
  <button v-else-if="mode === 'close'"
          :class="this.class"
          type="button"
          data-bs-dismiss="modal"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseModalButton",
  props: {
    class: {
      type: String,
      required: false,
    },
    modalId: {
      type: String,
      required: false,
      default: 'baseModal',
    },
    mode: {
      type: String,
      required: false,
      default: 'open',
    }
  },
};
</script>