import {
    ProductLicenseWithFeaturesRestDto
} from "@/api/pharma-master-license-mgmt/models";
import {ProductFeatureForCustomerLicenseRestDtoModel} from "@/models/ProductFeatureForCustomerLicenseRestDtoModel";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";

export class ProductLicenseWithFeaturesRestDtoModel implements ProductLicenseWithFeaturesRestDto {
    id: string;
    product: CodeDescriptionRestDtoModel;
    is_active?: boolean | null;
    begin_date: string;
    end_date?: string | null;
    features: ProductFeatureForCustomerLicenseRestDtoModel[];

    constructor(id: string, product: CodeDescriptionRestDtoModel, begin_date: string, features: ProductFeatureForCustomerLicenseRestDtoModel[]) {
        this.id = id;
        this.product = product;
        this.begin_date = begin_date;
        this.features = features;
    }

    public static createWithDefaults(): ProductLicenseWithFeaturesRestDtoModel {
        return new ProductLicenseWithFeaturesRestDtoModel(
            '',
            CodeDescriptionRestDtoModel.createWithDefaults(),
            '',
            []
        );
    }
}