import {RestService} from "@/services/rest/RestService";
import {ProductFeaturesEndpointsApi} from "@/api/pharma-master-license-mgmt";
import {
    CustomerFeatureAuthorizationPeriodRestDto,
    ProductLicenseWithFeaturesResponseRestDto, ProductLicenseWithFeaturesRestDto
} from "@/api/pharma-master-license-mgmt/models";
import {ProductLicenseWithFeaturesRestDtoModel} from "@/models/ProductLicenseWithFeaturesRestDtoModel";

export class ProductFeaturesRestService extends RestService {

    private apiService: ProductFeaturesEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ProductFeaturesEndpointsApi(this.getDpAxiosInstance(), undefined, this.getConfig().masterUrl);
    }

    public async findProductLicenseWithFeatures(product_code: string, customer_code: string, filter_is_license_active?: boolean): Promise<ProductLicenseWithFeaturesRestDto | null> {
        // console.log("ProductFeaturesRestService::findProductLicenseWithFeatures")
        const response = await this.apiService.findFeatureSettings(customer_code, filter_is_license_active);
        const productLicenseWithFeatures =
            response.data.results.find((pl: ProductLicenseWithFeaturesRestDto) => pl.product.code === product_code);

        if (productLicenseWithFeatures) {
            return productLicenseWithFeatures;
        }

        return null;
    }

    public async includeProductFeature(feature_code: string, customer_code?: string): Promise<void> {
        await this.apiService.includeFeature(feature_code, customer_code);
    }

    public async excludeProductFeature(feature_code: string, customer_code?: string): Promise<void> {
        await this.apiService.excludeFeature(feature_code, customer_code);
    }

    public async updateCustomerFeatureAuthorization(customer_feature_authorization_period: CustomerFeatureAuthorizationPeriodRestDto, id: string, customer_code?: string): Promise<void> {
        await this.apiService.updateCustomerFeatureAuthorization(customer_feature_authorization_period, id, customer_code);
    }
}