<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="localeChanger"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ $t($i18n.locale) }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="localeChanger">
      <li v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`">
        <button class="dropdown-item" @click="changeLocale(locale)">{{ $t(locale)}}</button>
      </li>
    </ul>
  </div>
</template>

<script>
import { setLocale } from '@vee-validate/i18n';

export default {
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      setLocale(locale);
    },
  },
};
</script>
<style lang="scss" scoped>

.dropdown-menu {
  left: inherit;
  right: 0;
}

</style>