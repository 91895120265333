import store from "@/store";
import {
    ContentOverviewRestDto,
    SimpleTextContentRestDto,
} from "@/api/pharma-cms-content-block/models";
import {SimpleContentBlockApiService, ContentBlockApiService} from "@/main";
import contentBlockInstance from "@/store/pharma-cms-content-block/axios-pharma-cms-content-block";


export default {
    namespaced: true,
    state() {
        return {
            general_terms_dp_static: {} as SimpleTextContentRestDto,
            general_terms_dp_reservation: {} as SimpleTextContentRestDto,
            general_terms_dp_webshop: {} as SimpleTextContentRestDto,
            privacy_policy_dp_static: {} as SimpleTextContentRestDto,
            privacy_policy_dp_reservation: {} as SimpleTextContentRestDto,
            privacy_policy_dp_webshop: {} as SimpleTextContentRestDto,
            pharmacy_on_call_url: {} as SimpleTextContentRestDto,
            content_overview: [] as ContentOverviewRestDto[],
        }
    },
    actions: {
        async saveSimpleContentBlock(context: any, payload: any) {
            const cb_type = payload['cb_type'];
            const cb = payload['cb'];
            await SimpleContentBlockApiService.updateSimpleContentBlockText(cb, cb_type);
        },
        async saveSimpleContentBlockTextWithTitle(context: any, payload: any) {
            const cb_type = payload['cb_type'];
            const cb = payload['cb'];
            const x_dp_customer_code = payload['x_dp_customer_code'];
            await SimpleContentBlockApiService.updateSimpleContentBlockTextWithTitle(cb, cb_type, x_dp_customer_code);
        },
        async searchSimpleContentBlock(context: any, cb_type: string) {
            await SimpleContentBlockApiService.getSimpleContentBlockText(cb_type).then(response => {
                const payload = {
                    cb_type: cb_type,
                    cb: response.data,
                }
                store.commit('cms_content_block/setSimpleContentBlock', payload);
            });
        },
        async createNormalContentBlockTextWithTitle(context: any, payload: any) {
            await ContentBlockApiService.createNormalContentBlockTextWithTitle(payload.cb, payload.cb_type, payload.x_dp_customer_code);
        },
        async saveNormalContentBlockTextWithTitle(context: any, payload: any) {
            await ContentBlockApiService.updateNormalContentBlockTextWithTitle(payload.cb, payload.cb_type, payload.id, payload.x_dp_customer_code);
        },
        async searchNormalContentBlockTextWithTitle(content: any, payload: any) {
            await ContentBlockApiService.getNormalContentBlockTextWithTitle(payload.cb_type, payload.id, payload.x_dp_customer_code).then(response => {
                const payloadResponse = {
                    cb_type: payload.cb_type,
                    cb: response.data,
                }
                store.commit('cms_content_block/setNormalContentBlock', payloadResponse);
            })
        },
        async createNormalContentBlockImage(context: any, payload: any) {
            await ContentBlockApiService.createNormalContentBlockImage(payload.cb, payload.cb_type, payload.x_dp_customer_code);
        },
        async saveNormalContentBlockImage(context: any, payload: any) {
            await ContentBlockApiService.updateNormalContentBlockImage(payload.cb, payload.cb_type, payload.id, payload.x_dp_customer_code);
        },
        async searchNormalContentBlockImage(content: any, payload: any) {
            await ContentBlockApiService.getNormalContentBlockImage(payload.cb_type, payload.id, payload.x_dp_customer_code).then(response => {
                const payloadResponse = {
                    cb_type: payload.cb_type,
                    cb: response.data,
                }
                store.commit('cms_content_block/setNormalContentBlock', payloadResponse);
            })
        },
        async searchNormalContentBlocks(content: any, payload: any) {
            await ContentBlockApiService.findNormalContentBlocks(payload.cb_type, payload.x_dp_customer_code).then(response => {
                const payloadResponse = {
                    cb_type: payload.cb_type,
                    cbs: response.data,
                }
                store.commit('cms_content_block/setOverviewContentBlocks', payloadResponse);
            });
        },
        async deleteNormalContentBlock(content: any, payload: any) {
            ContentBlockApiService.deleteNormalContentBlock(payload.cb_type, payload.id, payload.x_dp_customer_code).then(response => {
                const payloadResponse = {
                    cb_type: payload.cb_type,
                    cb: response.data,
                }

                //TODO: map gebruiken
                //TODO: CB types invoegen (voor de voet)
            });
        }
    },
    mutations: {
        setSimpleContentBlock(state: any, payload: any) {
            const cb_type = payload['cb_type'];
            const cb = payload['cb'];
            // todo map gebruiken
            if (cb_type === 'GENERAL_TERMS_DP_STATIC') {
                store.state.general_terms_dp_static = cb;
            } else if (cb_type === 'GENERAL_TERMS_DP_RESERVATION') {
                store.state.general_terms_dp_reservation = cb;
            } else if (cb_type === 'GENERAL_TERMS_DP_WEBSHOP') {
                store.state.general_terms_dp_webshop = cb;
            } else if (cb_type === 'PRIVACY_POLICY_DP_STATIC') {
                store.state.privacy_policy_dp_static = cb;
            } else if (cb_type === 'PRIVACY_POLICY_DP_RESERVATION') {
                store.state.privacy_policy_dp_reservation = cb;
            } else if (cb_type === 'PRIVACY_POLICY_DP_WEBSHOP') {
                store.state.privacy_policy_dp_webshop = cb;
            } else if (cb_type === 'PHARMACY_ON_CALL_URL') {
                store.state.pharmacy_on_call_url = cb;
            } else {
                throw new Error('Unsupported content block to search ' + cb_type);
            }
        },
        setNormalContentBlock(state: any, payload: any) {
            const cb_type = payload['cb_type'];
            const cb = payload['cb'];

            //TODO: map gebruiken
            //TODO: CB types invoegen (voor de voet)
        },
        setOverviewContentBlocks(state: any, payload: any) {
            store.state.content_overview = payload.cbs;
        },
    },
    getters: {
        getSimpleContentBlock: (state: any) => (cb_type: String) => {
            // todo map gebruiken
            if (cb_type === 'GENERAL_TERMS_DP_STATIC') {
                return store.state.general_terms_dp_static;
            } else if (cb_type === 'GENERAL_TERMS_DP_RESERVATION') {
                return store.state.general_terms_dp_reservation;
            } else if (cb_type === 'GENERAL_TERMS_DP_WEBSHOP') {
                return store.state.general_terms_dp_webshop;
            } else if (cb_type === 'PRIVACY_POLICY_DP_STATIC') {
                return store.state.privacy_policy_dp_static;
            } else if (cb_type === 'PRIVACY_POLICY_DP_RESERVATION') {
                return store.state.privacy_policy_dp_reservation;
            } else if (cb_type === 'PRIVACY_POLICY_DP_WEBSHOP') {
                return store.state.privacy_policy_dp_webshop;
            } else if (cb_type === 'PHARMACY_ON_CALL_URL') {
                return store.state.pharmacy_on_call_url;
            } else {
                throw new Error('Unsupported content block to search ' + cb_type);
            }
        },
        getNormalContentBlock: (state: any) => (cb_type: String) => {
            //TODO: map gebruiken
            //TODO: CB types invoegen (voor de voet)
        },
        getOverviewContentBlocks: (state: any) => (cb_type: String) => {
            return store.state.content_overview;
        }
    }
}