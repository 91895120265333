<template>
  <cb-text-form :title="$t('privacyPolicy') + ' ' + $t('reservation')"
                :label="$t('content')"
                :cb_type="'PRIVACY_POLICY_DP_RESERVATION'"
                :use_textarea="true"/>
</template>

<script>
import CbTextForm from "../../components/layouts/CbTextForm";

export default {
  components: {CbTextForm}
}
</script>

<style scoped>

</style>