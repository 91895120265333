import {Singleton} from "@/patterns/Singleton";
import {Config} from "@/models/facade/Config";

export class Service extends Singleton {
    protected constructor() {
        super();
    }

    protected getConfig(): any {
        return Config.getInstance().getConfig();
    }
}