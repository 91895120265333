import {RestService} from "@/services/rest/RestService";
import {CustomerLanguageSettingsAPIApi} from "@/api/pharma-customer-settings";
import {LanguageSettingsRestDto} from "@/api/pharma-customer-settings/models";

export class LanguageSettingsRestService extends RestService {

    private apiService: CustomerLanguageSettingsAPIApi;

    protected constructor() {
        super();

        this.apiService = new CustomerLanguageSettingsAPIApi(this.getDpAxiosInstance(), undefined, this.getConfig().customerUrl);
    }

    public async findLanguageSettings(x_dp_customer_code?: string): Promise<LanguageSettingsRestDto> {
        const response = await this.apiService.getLanguageSettings(x_dp_customer_code);

        return response.data;
    }

    public async updateLanguageSettings(body: LanguageSettingsRestDto, x_dp_customer_code?: string): Promise<void> {
        await this.apiService.updateLanguageSettings(body, x_dp_customer_code);
    }
}