import {Exception} from "../api/interfaces/exception.interface";
import {ExceptionType} from "@/api/enums";

export class DpException extends Error implements Exception {
    // message: string;
    code: string;
    field: string;
    type: ExceptionType;

    constructor(message: string) {
        super(message);
        // this.message = message;
        this.code = '';
        this.field = '';
        this.type = ExceptionType.ERROR;
    }
}