
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import PharmacyInfoField from "@/components/layouts/pharmacy/PharmacyInfoField.vue";
import PharmacyInfoAddress from "@/components/layouts/pharmacy/PharmacyInfoAddress.vue";
import {PharmacyRestDtoModel} from "@/models/PharmacyRestDtoModel";
import {OrderSettingsRestService} from "@/services/rest/pharma-order/OrderSettingsRestService";
import {NameValuePairOverviewRestDto} from "@/api/pharma-order-settings/models";
import {NameValuePairEnum} from "@/models/enum/NameValuePairEnum";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {UIStateDto} from "@/dtos/UIStateDto";
import {UtilityHelper} from "@/helpers/UtilityHelper";

export default defineComponent({
  name: "PharmacyInfo",
  components: {AlertError2, PharmacyInfoField, PharmacyInfoAddress},
  props: {
    customerCode: {
      type: String,
      required: true,
    },
    pharmacyCode: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    toaster: useToast(),

    pharmacyInfo: PharmacyRestDtoModel.createWithDefaults() as PharmacyRestDtoModel,
    pharmacyInfoUI: UIStateDto.createWithDefaults(),
    emailFrom: null as string | null,
    emailFromUI: UIStateDto.createWithDefaults(),
  }),
  computed: {
    UtilityHelper() {
      return UtilityHelper
    },
    ...mapGetters('cms_pharmacy', ['getPharmacyInfo']),
  },
  mounted() {
    this.reloadContent();
  },
  methods: {
    ...mapActions('cms_pharmacy', ['searchPharmacyInfo']),
    reloadContent(): void {
      this.searchPharmacyInformation();
      this.searchEmailFrom();
    },
    async saveEmailFrom(): Promise<void> {
      this.emailFromUI.clearError();

      try {
        await OrderSettingsRestService.getInstance().updatePharmacyNvp(
            this.emailFrom,
            NameValuePairEnum.ORDER_NOTIFICATION_EMAIL_FROM,
            this.pharmacyCode,
            this.customerCode
        );
        handleSavedSuccessfully();

        await this.searchEmailFrom();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.emailFromUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async searchEmailFrom(): Promise<void> {
      this.emailFromUI.clearError();
      this.emailFromUI.setIsReady(false);
      this.emailFrom = null;

      try {
        const nvpsPharmacy = await OrderSettingsRestService.getInstance()
            .findNameValuePairsPharmacy(this.pharmacyCode, this.customerCode);
        const nvpEmailFrom = nvpsPharmacy
            .find((nvp: NameValuePairOverviewRestDto) => nvp.name === NameValuePairEnum.ORDER_NOTIFICATION_EMAIL_FROM);
        if (nvpEmailFrom?.value) {
          this.emailFrom = nvpEmailFrom.value;
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.emailFromUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.emailFromUI.setIsReady(true);
      }
    },
    async searchPharmacyInformation(): Promise<void> {
      this.pharmacyInfoUI.clearError();
      this.pharmacyInfoUI.setIsReady(false);

      try {
        const payload = {
          pharmacy_code: this.pharmacyCode,
          customer_code: this.customerCode,
        };
        await this.searchPharmacyInfo(payload);
        this.pharmacyInfo = this.getPharmacyInfo;
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacyInfoUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.pharmacyInfoUI.setIsReady(true);
      }
    }
  },
});
