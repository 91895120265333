import {PharmacyRestDto, PharmacyRestDtoStatusCodeEnum} from "@/api/pharma-cms-pharmacy/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {AddressRestDtoModel} from "@/models/AddressRestDtoModel";
import {NormalContentBlockModel} from "./NormalContentBlockModel";

export class PharmacyRestDtoModel extends NormalContentBlockModel implements PharmacyRestDto {
    company_name: MultilingualRestDtoModel;
    company_email: string;
    company_phone: string;
    company_address: AddressRestDtoModel;
    vat_number?: string;
    rpr?: string;
    different_contactinfo?: boolean;
    public_name: MultilingualRestDtoModel;
    public_email?: string;
    public_phone?: string;
    public_address?: AddressRestDtoModel;
    apb_number: string;
    pharmacy_holder_name?: MultilingualRestDtoModel;
    link_to_facebook?: string;
    link_to_instagram?: string;
    link_to_twitter?: string;
    link_to_youtube?: string;
    link_to_linkedin?: string;
    link_to_whatsapp?: string;
    image_key?: string;
    status_code: PharmacyRestDtoStatusCodeEnum;
    sort_weight?: number;

    constructor(company_name: MultilingualRestDtoModel, company_email: string, company_phone: string, company_address: AddressRestDtoModel, public_name: MultilingualRestDtoModel, apb_number: string, status_code: PharmacyRestDtoStatusCodeEnum) {
        super();
        this.company_name = company_name;
        this.company_email = company_email;
        this.company_phone = company_phone;
        this.company_address = company_address;
        this.public_name = public_name;
        this.apb_number = apb_number;
        this.status_code = status_code;
    }

    public static createWithDefaults(): PharmacyRestDtoModel {
        const model = new PharmacyRestDtoModel(
            MultilingualRestDtoModel.createWithDefaults(),
            '',
            '',
            AddressRestDtoModel.createWithDefaults(),
            MultilingualRestDtoModel.createWithDefaults(),
            '',
            PharmacyRestDtoStatusCodeEnum.PUBLISHED,
        );
        model.public_address = AddressRestDtoModel.createWithDefaults();
        model.pharmacy_holder_name = MultilingualRestDtoModel.createWithDefaults();
        model.different_contactinfo = false;
        model.sort_weight = NormalContentBlockModel.getDefaultSortWeight();

        return model;
    }
}