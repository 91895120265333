
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {DpNameValuePairOverviewRestDto} from "@/api/pharma-master-nvp/models";
import {UIStateDto} from "@/dtos/UIStateDto";
import {NameValuePairRestService} from "@/services/rest/pharma-master/NameValuePairRestService";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {arrayHasContent} from "@/helpers/functions/array";
import AlertError2 from "@/components/UI/AlertError2.vue";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import NameValuePairCriteria from "@/components/layouts/nvp/NameValuePairCriteria.vue";
import {NameValuePairCriteriaPayload} from "@/models/payload/NameValuePairCriteriaPayload";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "NameValuePairOverview",
  components: {NameValuePairCriteria, BaseSpinner, AlertError2, BaseTitle},
  data() {
    return {
      nvpOverview: [] as DpNameValuePairOverviewRestDto[],
      nvpOverviewUI: UIStateDto.createWithDefaults()
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('nvp', ["getNvpCriteriaPayload"]),
    nvpCriteriaPayload(): NameValuePairCriteriaPayload {
      return this.getNvpCriteriaPayload;
    },
    hasNameValuePairs(): boolean {
      return arrayHasContent(this.nvpOverview)
    },
    UtilityHelper() {
      return UtilityHelper
    }
  },
  methods: {
    reloadContent(): void {
      this.searchNameValuePairs();
    },
    async searchNameValuePairs(): Promise<void> {
      this.nvpOverviewUI
          .clearError()
          .setIsReady(false);

      try {
        this.nvpOverview = (await NameValuePairRestService.getInstance()
            .findNameValuePairs(this.nvpCriteriaPayload.x_dp_module_code, this.nvpCriteriaPayload.nvp_criteria))
            .results;
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.nvpOverviewUI as UIStateDto))
            .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.nvpOverviewUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.nvpOverviewUI.setIsReady(true);
      }
    }
  }
});
