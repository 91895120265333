import {CustomerFeatureAuthorizationPeriodRestDto} from "@/api/pharma-master-license-mgmt/models";

export class CustomerFeatureAuthorizationPeriodRestDtoModel implements CustomerFeatureAuthorizationPeriodRestDto {
    begin_date: string;
    end_date?: string | null;

    constructor(begin_date: string, end_date?: string | null) {
        this.begin_date = begin_date;
        this.end_date = end_date ?? null;
    }

    public static createWithDefaults(): CustomerFeatureAuthorizationPeriodRestDtoModel {
        return new CustomerFeatureAuthorizationPeriodRestDtoModel(new Date().toDateString());
    }
}