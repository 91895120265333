import {CodeDescriptionRestDto} from "@/api/pharma-master-enum/models";
import {DpException} from "@/exception/DpException";
import {EnumClassNameMasterEnum} from "@/models/enum/EnumClassNameMasterEnum";
import {MasterEnumEndpointsApiService} from "@/main";

export default {
    namespaced: true,
    state() {
        return {
            enums: new Map<EnumClassNameMasterEnum, CodeDescriptionRestDto[]>(),
        };
    },
    actions: {
        async findEnumByClassname(context: any, className: EnumClassNameMasterEnum): Promise<void> {
            if (!context.state.enums.has(className)) {
                const response =
                    await MasterEnumEndpointsApiService.getMasterEnumValuesByClassname(className);
                context.state.enums.set(className, response.data as CodeDescriptionRestDto[]);
            }
        },
        async findManyEnumByClassname(context: any, classNames: EnumClassNameMasterEnum[]): Promise<void> {
            const promises = [] as Promise<void>[];

            classNames.forEach((className: EnumClassNameMasterEnum) => {
                console.log('findEnumByClassname, className = ', className);
                promises.push(context.dispatch("findEnumByClassname", className));
            });

            await Promise.all(promises);
        },
    },
    mutations: {},
    getters: {
        getEnumByClassname: (state: any) => (className: EnumClassNameMasterEnum): CodeDescriptionRestDto[] => {
            if (!state.enums.has(className)) {
                throw new DpException('Enum `' + className + '` has not been initialized, yet.');
            }

            return state.enums.get(className);
        },
    },
};
