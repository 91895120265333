import {CustomerDomainRestDto} from "@/api/pharma-customer-settings/models";

export class CustomerDomainRestDtoModel implements CustomerDomainRestDto {
    domain_url?: string | null;
    is_active?: boolean | null;
    is_test?: boolean | null;


    constructor(domain_url?: string | null, is_active?: boolean | null, is_test?: boolean | null) {
        this.domain_url = domain_url;
        this.is_active = is_active;
        this.is_test = is_test;
    }

    public static createWithDefaults(): CustomerDomainRestDtoModel {
        return new this('', false, false);
    }
}