export class CodeDescriptionRestDtoModel {
    code: string;
    description: string;

    constructor(code: string, description: string) {
        this.code = code;
        this.description = description;
    }

    public static createWithDefaults(): CodeDescriptionRestDtoModel {
        return new CodeDescriptionRestDtoModel('', '');
    }
}