/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-Customer
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CustomerCodeRestDto } from '../models';
import { CustomerOverviewRestDto } from '../models';
import { CustomerRestDto } from '../models';
import { CustomerCriteriaRestDto } from '../models';
/**
 * CustomerCRUDApi - axios parameter creator
 * @export
 */
export const CustomerCRUDApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint verantwoordelijk voor het registreren van een Digital-Pharma Customer. Merk op, dit endpoint genereert ook de unieke customer_code die vanaf dan gebruikt zal worden om de aangemaakte Customer uniek te identificeren.
         * @summary REST.API.PC.CUS.01 Registreer Customer.
         * @param {CustomerRestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomer: async (body: CustomerRestDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createCustomer.');
            }
            const localVarPath = `/customer/v1/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API waarmee de Digital-Pharma Customer elementen gezocht kunnen worden. De resultaten worden alfabetisch gesorteerd op het name veld.
         * @summary REST.API.PC.CUS.04 Zoek Customer elementen.
         * @param {string} [search_name] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van het name veld. (Er wordt case insensitive gezocht.)
         * @param {number} [filter_account_number] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {number} [filter_customer_number] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {string} [filter_license_product_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor de gespecifieerde Digital-Pharma product code.
         * @param {string} [filter_product_type_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor een product van het gespecifieerde Digital-Pharma product type.
         * @param {string} [filter_customer_type_code] Filter de resultaten op basis van het type van customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCustomer: async (search_name?: string|null, filter_account_number?: number|null, filter_customer_number?: number|null, filter_license_product_code?: string|null, filter_product_type_code?: string|null, filter_customer_type_code?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/v1/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (!!search_name || typeof(search_name) === "boolean") {
                localVarQueryParameter['search_name'] = search_name;
            }

            if (!!filter_account_number || typeof(filter_account_number) === "boolean") {
                localVarQueryParameter['filter_account_number'] = filter_account_number;
            }

            if (!!filter_customer_number || typeof(filter_customer_number) === "boolean") {
                localVarQueryParameter['filter_customer_number'] = filter_customer_number;
            }

            if (!!filter_license_product_code || typeof(filter_license_product_code) === "boolean") {
                localVarQueryParameter['filter_license_product_code'] = filter_license_product_code;
            }

            if (!!filter_product_type_code || typeof(filter_product_type_code) === "boolean") {
                localVarQueryParameter['filter_product_type_code'] = filter_product_type_code;
            }

            if (!!filter_customer_type_code || typeof(filter_customer_type_code) === "boolean") {
                localVarQueryParameter['filter_customer_type_code'] = filter_customer_type_code;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API waarmee de details van een Digital-Pharma Customer geraadpleegd kunnen worden.
         * @summary REST.API.PC.CUS.03 Raadpleeg Customer.
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (customer_code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling getCustomer.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint verantwoordelijk voor het wijzigen van een Digital-Pharma Customer.
         * @summary REST.API.PC.CUS.02 Wijzig Customer.
         * @param {CustomerRestDto} body 
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomer: async (body: CustomerRestDto, customer_code?: string | null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateCustomer.');
            }
            // verify required parameter 'customer_code' is not null or undefined
            if (customer_code === null || customer_code === undefined) {
                throw new RequiredError('customer_code','Required parameter customer_code was null or undefined when calling updateCustomer.');
            }
            const localVarPath = `/customer/v1/customer/{customer_code}`
                .replace(`{${"customer_code"}}`, encodeURIComponent(String(customer_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerCRUDApi - functional programming interface
 * @export
 */
export const CustomerCRUDApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint verantwoordelijk voor het registreren van een Digital-Pharma Customer. Merk op, dit endpoint genereert ook de unieke customer_code die vanaf dan gebruikt zal worden om de aangemaakte Customer uniek te identificeren.
         * @summary REST.API.PC.CUS.01 Registreer Customer.
         * @param {CustomerRestDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCustomer(_axios: AxiosInstance, body: CustomerRestDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerCodeRestDto>> {
            const localVarAxiosArgs = await CustomerCRUDApiAxiosParamCreator(configuration).createCustomer(body, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API waarmee de Digital-Pharma Customer elementen gezocht kunnen worden. De resultaten worden alfabetisch gesorteerd op het name veld.
         * @summary REST.API.PC.CUS.04 Zoek Customer elementen.
         * @param {string} [search_name] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van het name veld. (Er wordt case insensitive gezocht.)
         * @param {number} [filter_account_number] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {number} [filter_customer_number] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
         * @param {string} [filter_license_product_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor de gespecifieerde Digital-Pharma product code.
         * @param {string} [filter_product_type_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor een product van het gespecifieerde Digital-Pharma product type.
         * @param {string} [filter_customer_type_code] Filter de resultaten op basis van het type van customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findCustomer(_axios: AxiosInstance, search_name?: string|null, filter_account_number?: number|null, filter_customer_number?: number|null, filter_license_product_code?: string|null, filter_product_type_code?: string|null, filter_customer_type_code?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerOverviewRestDto>>> {
            const localVarAxiosArgs = await CustomerCRUDApiAxiosParamCreator(configuration).findCustomer(search_name, filter_account_number, filter_customer_number, filter_license_product_code, filter_product_type_code, filter_customer_type_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API waarmee de details van een Digital-Pharma Customer geraadpleegd kunnen worden.
         * @summary REST.API.PC.CUS.03 Raadpleeg Customer.
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(_axios: AxiosInstance, customer_code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerRestDto>> {
            const localVarAxiosArgs = await CustomerCRUDApiAxiosParamCreator(configuration).getCustomer(customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint verantwoordelijk voor het wijzigen van een Digital-Pharma Customer.
         * @summary REST.API.PC.CUS.02 Wijzig Customer.
         * @param {CustomerRestDto} body 
         * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomer(_axios: AxiosInstance, body: CustomerRestDto, customer_code?: string | null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await CustomerCRUDApiAxiosParamCreator(configuration).updateCustomer(body, customer_code, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CustomerCRUDApi - object-oriented interface
 * @export
 * @class CustomerCRUDApi
 * @extends {BaseAPI}
 */
export class CustomerCRUDApi extends BaseAPI {
    /**
     * API endpoint verantwoordelijk voor het registreren van een Digital-Pharma Customer. Merk op, dit endpoint genereert ook de unieke customer_code die vanaf dan gebruikt zal worden om de aangemaakte Customer uniek te identificeren.
     * @summary REST.API.PC.CUS.01 Registreer Customer.
     * @param {CustomerRestDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCRUDApi
     */
     public createCustomer(body: CustomerRestDto, options?: any) {
        return CustomerCRUDApiFp(this.configuration).createCustomer(this.axios, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API waarmee de Digital-Pharma Customer elementen gezocht kunnen worden. De resultaten worden alfabetisch gesorteerd op het name veld.
     * @summary REST.API.PC.CUS.04 Zoek Customer elementen.
     * @param {string} [search_name] Zoek de resultaten waarbij de opgegeven parameter voorkomt in de waarde van het name veld. (Er wordt case insensitive gezocht.)
     * @param {number} [filter_account_number] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
     * @param {number} [filter_customer_number] Filter de resultaten waarbij er een exacte match is met de opgegeven parameter.
     * @param {string} [filter_license_product_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor de gespecifieerde Digital-Pharma product code.
     * @param {string} [filter_product_type_code] Filter de resultaten op basis van de aanwezigheid van een actieve licentie voor een product van het gespecifieerde Digital-Pharma product type.
     * @param {string} [filter_customer_type_code] Filter de resultaten op basis van het type van customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCRUDApi
     */
     private findCustomer(search_name?: string|null, filter_account_number?: number|null, filter_customer_number?: number|null, filter_license_product_code?: string|null, filter_product_type_code?: string|null, filter_customer_type_code?: string|null, options?: any) {
        return CustomerCRUDApiFp(this.configuration).findCustomer(this.axios, search_name, filter_account_number, filter_customer_number, filter_license_product_code, filter_product_type_code, filter_customer_type_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API waarmee de Digital-Pharma Customer elementen gezocht kunnen worden. De resultaten worden alfabetisch gesorteerd op het name veld.
     * @summary REST.API.PC.CUS.04 Zoek Customer elementen.
     * @param {CustomerCriteriaRestDto} [dto] The CriteriaRestDto for the api call.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCRUDApi
     */
    public findCustomerByCustomerCriteriaRestDto(dto: CustomerCriteriaRestDto, options?: any){
        return this.findCustomer(dto?.search_name, dto?.filter_account_number, dto?.filter_customer_number, dto?.filter_license_product_code, dto?.filter_product_type_code, dto?.filter_customer_type_code, options);
    }
    /**
     * API waarmee de details van een Digital-Pharma Customer geraadpleegd kunnen worden.
     * @summary REST.API.PC.CUS.03 Raadpleeg Customer.
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCRUDApi
     */
     public getCustomer(customer_code: string, options?: any) {
        return CustomerCRUDApiFp(this.configuration).getCustomer(this.axios, customer_code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint verantwoordelijk voor het wijzigen van een Digital-Pharma Customer.
     * @summary REST.API.PC.CUS.02 Wijzig Customer.
     * @param {CustomerRestDto} body 
     * @param {string} customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCRUDApi
     */
     public updateCustomer(body: CustomerRestDto, customer_code?: string | null, options?: any) {
        return CustomerCRUDApiFp(this.configuration).updateCustomer(this.axios, body, customer_code, options).then((request) => request(this.axios, this.basePath));
    }

}
