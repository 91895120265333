import {POSITION, useToast} from "vue-toastification";

export class UtilityHelper {
    public static async copyToClipboard(value: string): Promise<void> {
        await navigator.clipboard.writeText(value);

        useToast().info('Copied!', {
            position: POSITION.TOP_LEFT,
            timeout: 1000,
        });
    }

    public static async copyInnerTextToClipboard(event: Event): Promise<void> {
        const el = event.target as Element;

        await this.copyToClipboard(el.textContent ?? '');
    }

    public static consoleLog(value: any): void {
        console.log(value);
    }
}
