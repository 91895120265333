
import {defineComponent} from "vue";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import CustomerFormContainer from "@/components/layouts/customer/CustomerFormContainer.vue";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {
  CreatePharmacistUserRestDtoModel
} from "@/models/api/pharma-idm-pharmacist-user/CreatePharmacistUserRestDtoModel";
import InputCheckbox from "@/components/UI/InputCheckbox.vue";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {IdmPharmacistUserRestService} from "@/services/rest/pharma-idm/IdmPharmacistUserRestService";

interface Roles {
  [key: string]: boolean;
}

export default defineComponent({
  name: "PharmacistUserDetail",
  components: {InputCheckbox, AlertError2, CustomerFormContainer, BaseTitle, BaseSpinner},
  props: ["mode"],
  data: () => ({
    pharmacistUser: CreatePharmacistUserRestDtoModel.createWithDefaults(),
    pharmacistUserUI: UIStateDto.createWithDefaults(),
    customerUserRoles: ['ROLE_ADMIN', 'ROLE_CONTENT_ADMIN', 'ROLE_CATALOG_ADMIN', 'ROLE_ORDER_HANDLER'] as string[],
    customerUserRolesData: {
      'ROLE_ADMIN': true,
      'ROLE_CONTENT_ADMIN': true,
      'ROLE_CATALOG_ADMIN': true,
      'ROLE_ORDER_HANDLER': true,
    } as Roles,
  }),
  mounted() {
    this.pharmacistUser.customer_codes.push(this.customerCode);
    this.pharmacistUser.customer_user_roles = this.customerUserRoles;
    this.pharmacistUserUI.setReady();
  },
  computed: {
    customerCode(): any {
      return this.$route.params.customer_code;
    },
  },
  methods: {
    async submit(): Promise<void> {
      this.pharmacistUserUI
          .clearError()
          .setNotReady();

      try {
        await IdmPharmacistUserRestService.getInstance().createPharmacistUserPassword(this.pharmacistUser);
        await handleSavedSuccessfully();
        await this.$router.push({
          name: 'edit-customer', params: {id: this.customerCode}
        });
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pharmacistUserUI as UIStateDto))
            .catch(exceptions, true);
      }

      this.pharmacistUserUI
          .setReady();
    },
    addCustomerCode(): void {
      this.pharmacistUser.customer_codes.push("");
    },
    removeCustomerCode(i: number): void {
      this.pharmacistUser.customer_codes.splice(i, 1);
    },
    calculateCustomerUserRoles(): void {
      this.pharmacistUser.customer_user_roles =
          Object.keys(this.customerUserRolesData).filter(role => this.customerUserRolesData[role]);
    }
  }
});
