import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";

/**
 * Creates an array from an enumeration, receives an enumeration with key-value pairs
 * @param enumeration
 * @returns CodeDescriptionRestDto[]
 */
function createCodeDescriptionArrayFromEnum(enumeration: any): CodeDescriptionRestDto[] {
    const array = [] as CodeDescriptionRestDto[];

    const keys = Object.keys(enumeration) as [];
    const values = Object.values(enumeration) as [];
    keys.forEach((key, index) => {
        array.push({
            code: key,
            description: values[index],
        })
    });

    return array;
}

export {
    createCodeDescriptionArrayFromEnum
}