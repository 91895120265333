<template>
  <div :class="{greenbg: showDetails}" class="px-0">
    <div class="row row-cols-auto border-top border-1 border-primary py-1">
      <div class="col col-lg-3">
        <p class="fw-bold mb-0">{{ jobrun.job_name }}</p>
      </div>
      <div class="col col-lg-1">
        <p class="mb-0">{{ abbreviateCustomerCode(jobrun.dp_customer_code) }}</p>
      </div>
      <div class="col col-lg-2">
        <p class="mb-0">{{ jobrun.start_time }}</p>
      </div>
      <div class="col col-lg-1">
        <p>{{ formatDuration(jobrun.duration) }}</p>
      </div>
      <div class="col col-lg-2">
        <p>{{ jobrun.status }}</p>
      </div>
      <div class="col col-lg-2">
        <p>{{ jobrun.stopreason }}</p>
      </div>
      <div class="col col-lg-1">
        <button @click="toggleDetails" class="btn btn-primary btn-sm"><i class="bi bi-info-circle"></i></button>
      </div>
    </div>
    <div v-if="showDetails">
      <div class="row">
        <div class="col offset-lg-3">
          <p class="fw-bold fs-6 mb-0">{{ $t('application') }}</p>
          <p>{{ jobrun.application_name }}</p>
        </div>
        <div class="col">
          <p class="fw-bold fs-6 mb-0">{{ $t('environment') }}</p>
          <p>{{ jobrun.environment }}</p>
        </div>
        <div class="col">
          <p class="fw-bold fs-6 mb-0">{{ $t('endTime') }}</p>
          <p>{{ jobrun.end_time }}</p>
        </div>
      </div>
      <div class="row" v-if="jobrun.errormessage">
        <div class="col">
          <p class="fw-bold fs-6 mb-0">{{ $t('errorMessage') }}</p>
          <p>{{ jobrun.errormessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: ['jobrun'],
  data() {
    return {
      class: 'btn btn-primary',
      showDetails: false
    }
  },
  methods: {
    abbreviateCustomerCode(customerCode) {
      if (typeof customerCode === 'undefined' || customerCode === null) {
        return null;
      } else {
        return customerCode.substr(0, 9);
      }
    },
    formatDuration(duration) {
      if (typeof duration === 'undefined' || duration === null) {
        return null;
      } else {
        if (duration < 0) {
          //todo stuur error uit, negatieve tijd
        } else if (duration < 1000) {
          return duration + 'ms';
        } else {
          return (duration / 1000) + 's';
        }
      }
    },
    toggleDetails() {
      this.showDetails = !this.showDetails
    },
  },
  computed: {
    ...mapGetters('job_runs', ['getJobRuns']),
  },
};
</script>

<style lang="scss" scoped>
.greenbg {
  background-color: rgba(var(--bs-success-rgb), 0.1);
  border-bottom: 1px solid rgba(var(--bs-success-rgb), 0.5);
}
</style>