
import { defineComponent, PropType } from 'vue';
import { Exception } from '@/api/interfaces';

export default defineComponent({
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    exceptions: {
      type: Array as PropType<Exception[]>,
      default: () => [],
    },
  },
});
