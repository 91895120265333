
import {defineComponent} from 'vue'

export default defineComponent({
  name: "CustomerFormContainer",
  props: {
    columnClasses: {
      type: String,
      required: false,
      default: 'col col-xl-8 col-xxl-6'
    }
  }
});
