/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-TP Text Processing Task API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AiOutputAltTextRestDto } from '../models';
import { PagedTextProcessingTaskOverviewResultsRestDto } from '../models';
import { PagedTextProcessingTaskSourceAndOutputOverviewResultsRestDto } from '../models';
import { TaskOutputFeedbackRestDto } from '../models';
import { TextProcessingTaskDetailRestDto } from '../models';
import { TextProcessingTaskCriteriaRestDto } from '../models';
import { TextProcessingTaskSourceAndOutputCriteriaRestDto } from '../models';
/**
 * TextProcessingTaskAPIApi - axios parameter creator
 * @export
 */
export const TextProcessingTaskAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the text processing tasks.
         * @summary REST.API.TP.TPT.01 Find text processing tasks
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {string} [filter_task_type_code] Filters the results based on the type of task.
         * @param {string} [search_task_code] Filters the results based on (a part of) a task code.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [search_source_keyword] Search for a keyword in the source text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findTextProcessingTasks: async (x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_task_type_code?: string|null, search_task_code?: string|null, filter_cnk_code?: number|null, search_source_keyword?: string|null, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/text_processing_task/v1/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (!!filter_task_type_code || typeof(filter_task_type_code) === "boolean") {
                localVarQueryParameter['filter_task_type_code'] = filter_task_type_code;
            }

            if (!!search_task_code || typeof(search_task_code) === "boolean") {
                localVarQueryParameter['search_task_code'] = search_task_code;
            }

            if (!!filter_cnk_code || typeof(filter_cnk_code) === "boolean") {
                localVarQueryParameter['filter_cnk_code'] = filter_cnk_code;
            }

            if (!!search_source_keyword || typeof(search_source_keyword) === "boolean") {
                localVarQueryParameter['search_source_keyword'] = search_source_keyword;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the details of a text processing task.
         * @summary REST.API.TP.TPT.01 Retrieve text processing task details
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextProcessingTask: async (id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTextProcessingTask.');
            }
            const localVarPath = `/text_processing_task/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for retrieving the output of a text processing task (and its subtasks).
         * @summary REST.API.TP.TPT.03 Retrieve source and output of a text processing task
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [search_source_keyword] Search for a keyword in the source text
         * @param {number} [filter_feeback_score_from] Filters the results based on the feedbackscore.
         * @param {number} [filter_feeback_score_till] Filters the results based on the feedbackscore.
         * @param {boolean} [filter_is_alt_text_available] Filters the results based on the alternative text availability.
         * @param {boolean} [filter_is_feedback_note_available] Filters the results based on the feedbacks note availability.
         * @param {boolean} [filter_is_feedback_score_available] Filters the results based on the feedbacks score availability.
         * @param {boolean} [filter_is_on_hold] Filters the results based on the is_on_hold value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTextProcessingTaskOutputOverview: async (id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, search_source_keyword?: string|null, filter_feeback_score_from?: number|null, filter_feeback_score_till?: number|null, filter_is_alt_text_available?: boolean|null, filter_is_feedback_note_available?: boolean|null, filter_is_feedback_score_available?: boolean|null, filter_is_on_hold?: boolean|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTextProcessingTaskOutputOverview.');
            }
            const localVarPath = `/text_processing_task/v1/task/{id}/output`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (!!page || typeof(page) === "boolean") {
                localVarQueryParameter['page'] = page;
            }

            if (!!page_size || typeof(page_size) === "boolean") {
                localVarQueryParameter['page_size'] = page_size;
            }

            if (!!filter_cnk_code || typeof(filter_cnk_code) === "boolean") {
                localVarQueryParameter['filter_cnk_code'] = filter_cnk_code;
            }

            if (!!search_source_keyword || typeof(search_source_keyword) === "boolean") {
                localVarQueryParameter['search_source_keyword'] = search_source_keyword;
            }

            if (!!filter_feeback_score_from || typeof(filter_feeback_score_from) === "boolean") {
                localVarQueryParameter['filter_feeback_score_from'] = filter_feeback_score_from;
            }

            if (!!filter_feeback_score_till || typeof(filter_feeback_score_till) === "boolean") {
                localVarQueryParameter['filter_feeback_score_till'] = filter_feeback_score_till;
            }

            if (!!filter_is_alt_text_available || typeof(filter_is_alt_text_available) === "boolean") {
                localVarQueryParameter['filter_is_alt_text_available'] = filter_is_alt_text_available;
            }

            if (!!filter_is_feedback_note_available || typeof(filter_is_feedback_note_available) === "boolean") {
                localVarQueryParameter['filter_is_feedback_note_available'] = filter_is_feedback_note_available;
            }

            if (!!filter_is_feedback_score_available || typeof(filter_is_feedback_score_available) === "boolean") {
                localVarQueryParameter['filter_is_feedback_score_available'] = filter_is_feedback_score_available;
            }

            if (!!filter_is_on_hold || typeof(filter_is_on_hold) === "boolean") {
                localVarQueryParameter['filter_is_on_hold'] = filter_is_on_hold;
            }

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for saving alternative text for an AI task output.
         * @summary REST.API.TP.TPT.05 Saving alternative text for output
         * @param {AiOutputAltTextRestDto} body 
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} output_id The id that uniquely identifies the task output.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAiOutputAltText: async (body: AiOutputAltTextRestDto, id: string, output_id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAiOutputAltText.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveAiOutputAltText.');
            }
            // verify required parameter 'output_id' is not null or undefined
            if (output_id === null || output_id === undefined) {
                throw new RequiredError('output_id','Required parameter output_id was null or undefined when calling saveAiOutputAltText.');
            }
            const localVarPath = `/text_processing_task/v1/task/{id}/ai_output/{output_id}/alternative_text`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"output_id"}}`, encodeURIComponent(String(output_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * API endpoint responsible for saving feedback for a task output.
         * @summary REST.API.TP.TPT.04 Saving feedback for output
         * @param {TaskOutputFeedbackRestDto} body 
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} output_id The id that uniquely identifies the task output.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTextProcessingTaskOutputFeedback: async (body: TaskOutputFeedbackRestDto, id: string, output_id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveTextProcessingTaskOutputFeedback.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveTextProcessingTaskOutputFeedback.');
            }
            // verify required parameter 'output_id' is not null or undefined
            if (output_id === null || output_id === undefined) {
                throw new RequiredError('output_id','Required parameter output_id was null or undefined when calling saveTextProcessingTaskOutputFeedback.');
            }
            const localVarPath = `/text_processing_task/v1/task/{id}/output/{output_id}/feedback`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"output_id"}}`, encodeURIComponent(String(output_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TextProcessingTaskAPIApi - functional programming interface
 * @export
 */
export const TextProcessingTaskAPIApiFp = function(configuration?: Configuration) {
    return {
        /**
         * API endpoint responsible for retrieving the text processing tasks.
         * @summary REST.API.TP.TPT.01 Find text processing tasks
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {string} [filter_task_type_code] Filters the results based on the type of task.
         * @param {string} [search_task_code] Filters the results based on (a part of) a task code.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [search_source_keyword] Search for a keyword in the source text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findTextProcessingTasks(_axios: AxiosInstance, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_task_type_code?: string|null, search_task_code?: string|null, filter_cnk_code?: number|null, search_source_keyword?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedTextProcessingTaskOverviewResultsRestDto>> {
            const localVarAxiosArgs = await TextProcessingTaskAPIApiAxiosParamCreator(configuration).findTextProcessingTasks(x_dp_customer_code, x_dp_language, page, page_size, filter_task_type_code, search_task_code, filter_cnk_code, search_source_keyword, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the details of a text processing task.
         * @summary REST.API.TP.TPT.01 Retrieve text processing task details
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextProcessingTask(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TextProcessingTaskDetailRestDto>> {
            const localVarAxiosArgs = await TextProcessingTaskAPIApiAxiosParamCreator(configuration).getTextProcessingTask(id, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for retrieving the output of a text processing task (and its subtasks).
         * @summary REST.API.TP.TPT.03 Retrieve source and output of a text processing task
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {number} [page] Specifies the page number that is expected of the paged result.
         * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
         * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
         * @param {string} [search_source_keyword] Search for a keyword in the source text
         * @param {number} [filter_feeback_score_from] Filters the results based on the feedbackscore.
         * @param {number} [filter_feeback_score_till] Filters the results based on the feedbackscore.
         * @param {boolean} [filter_is_alt_text_available] Filters the results based on the alternative text availability.
         * @param {boolean} [filter_is_feedback_note_available] Filters the results based on the feedbacks note availability.
         * @param {boolean} [filter_is_feedback_score_available] Filters the results based on the feedbacks score availability.
         * @param {boolean} [filter_is_on_hold] Filters the results based on the is_on_hold value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTextProcessingTaskOutputOverview(_axios: AxiosInstance, id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, search_source_keyword?: string|null, filter_feeback_score_from?: number|null, filter_feeback_score_till?: number|null, filter_is_alt_text_available?: boolean|null, filter_is_feedback_note_available?: boolean|null, filter_is_feedback_score_available?: boolean|null, filter_is_on_hold?: boolean|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedTextProcessingTaskSourceAndOutputOverviewResultsRestDto>> {
            const localVarAxiosArgs = await TextProcessingTaskAPIApiAxiosParamCreator(configuration).getTextProcessingTaskOutputOverview(id, x_dp_customer_code, x_dp_language, page, page_size, filter_cnk_code, search_source_keyword, filter_feeback_score_from, filter_feeback_score_till, filter_is_alt_text_available, filter_is_feedback_note_available, filter_is_feedback_score_available, filter_is_on_hold, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for saving alternative text for an AI task output.
         * @summary REST.API.TP.TPT.05 Saving alternative text for output
         * @param {AiOutputAltTextRestDto} body 
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} output_id The id that uniquely identifies the task output.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAiOutputAltText(_axios: AxiosInstance, body: AiOutputAltTextRestDto, id: string, output_id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TextProcessingTaskAPIApiAxiosParamCreator(configuration).saveAiOutputAltText(body, id, output_id, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * API endpoint responsible for saving feedback for a task output.
         * @summary REST.API.TP.TPT.04 Saving feedback for output
         * @param {TaskOutputFeedbackRestDto} body 
         * @param {string} id The id that uniquely identifies the task.
         * @param {string} output_id The id that uniquely identifies the task output.
         * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTextProcessingTaskOutputFeedback(_axios: AxiosInstance, body: TaskOutputFeedbackRestDto, id: string, output_id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TextProcessingTaskAPIApiAxiosParamCreator(configuration).saveTextProcessingTaskOutputFeedback(body, id, output_id, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TextProcessingTaskAPIApi - object-oriented interface
 * @export
 * @class TextProcessingTaskAPIApi
 * @extends {BaseAPI}
 */
export class TextProcessingTaskAPIApi extends BaseAPI {
    /**
     * API endpoint responsible for retrieving the text processing tasks.
     * @summary REST.API.TP.TPT.01 Find text processing tasks
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {string} [filter_task_type_code] Filters the results based on the type of task.
     * @param {string} [search_task_code] Filters the results based on (a part of) a task code.
     * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
     * @param {string} [search_source_keyword] Search for a keyword in the source text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
     private findTextProcessingTasks(x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_task_type_code?: string|null, search_task_code?: string|null, filter_cnk_code?: number|null, search_source_keyword?: string|null, options?: any) {
        return TextProcessingTaskAPIApiFp(this.configuration).findTextProcessingTasks(this.axios, x_dp_customer_code, x_dp_language, page, page_size, filter_task_type_code, search_task_code, filter_cnk_code, search_source_keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the text processing tasks.
     * @summary REST.API.TP.TPT.01 Find text processing tasks
     * @param {TextProcessingTaskCriteriaRestDto} [dto] The CriteriaRestDto for the api call.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
    public findTextProcessingTasksByTextProcessingTaskCriteriaRestDto(dto: TextProcessingTaskCriteriaRestDto, options?: any){
        return this.findTextProcessingTasks(dto?.x_dp_customer_code, dto?.x_dp_language, dto?.page, dto?.page_size, dto?.filter_task_type_code, dto?.search_task_code, dto?.filter_cnk_code, dto?.search_source_keyword, options);
    }
    /**
     * API endpoint responsible for retrieving the details of a text processing task.
     * @summary REST.API.TP.TPT.01 Retrieve text processing task details
     * @param {string} id The id that uniquely identifies the task.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
     public getTextProcessingTask(id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return TextProcessingTaskAPIApiFp(this.configuration).getTextProcessingTask(this.axios, id, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the output of a text processing task (and its subtasks).
     * @summary REST.API.TP.TPT.03 Retrieve source and output of a text processing task
     * @param {string} id The id that uniquely identifies the task.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {number} [page] Specifies the page number that is expected of the paged result.
     * @param {number} [page_size] Specifies the maximum number of elements allowed on each page of the paged result.
     * @param {number} [filter_cnk_code] Filters the results to the specified CNK code.
     * @param {string} [search_source_keyword] Search for a keyword in the source text
     * @param {number} [filter_feeback_score_from] Filters the results based on the feedbackscore.
     * @param {number} [filter_feeback_score_till] Filters the results based on the feedbackscore.
     * @param {boolean} [filter_is_alt_text_available] Filters the results based on the alternative text availability.
     * @param {boolean} [filter_is_feedback_note_available] Filters the results based on the feedbacks note availability.
     * @param {boolean} [filter_is_feedback_score_available] Filters the results based on the feedbacks score availability.
     * @param {boolean} [filter_is_on_hold] Filters the results based on the is_on_hold value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
     private getTextProcessingTaskOutputOverview(id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, page?: number|null, page_size?: number|null, filter_cnk_code?: number|null, search_source_keyword?: string|null, filter_feeback_score_from?: number|null, filter_feeback_score_till?: number|null, filter_is_alt_text_available?: boolean|null, filter_is_feedback_note_available?: boolean|null, filter_is_feedback_score_available?: boolean|null, filter_is_on_hold?: boolean|null, options?: any) {
        return TextProcessingTaskAPIApiFp(this.configuration).getTextProcessingTaskOutputOverview(this.axios, id, x_dp_customer_code, x_dp_language, page, page_size, filter_cnk_code, search_source_keyword, filter_feeback_score_from, filter_feeback_score_till, filter_is_alt_text_available, filter_is_feedback_note_available, filter_is_feedback_score_available, filter_is_on_hold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for retrieving the output of a text processing task (and its subtasks).
     * @summary REST.API.TP.TPT.03 Retrieve source and output of a text processing task
     * @param {TextProcessingTaskSourceAndOutputCriteriaRestDto} [dto] The CriteriaRestDto for the api call.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
    public getTextProcessingTaskOutputOverviewByTextProcessingTaskSourceAndOutputCriteriaRestDto(dto: TextProcessingTaskSourceAndOutputCriteriaRestDto, options?: any){
        return this.getTextProcessingTaskOutputOverview(dto?.id, dto?.x_dp_customer_code, dto?.x_dp_language, dto?.page, dto?.page_size, dto?.filter_cnk_code, dto?.search_source_keyword, dto?.filter_feeback_score_from, dto?.filter_feeback_score_till, dto?.filter_is_alt_text_available, dto?.filter_is_feedback_note_available, dto?.filter_is_feedback_score_available, dto?.filter_is_on_hold, options);
    }
    /**
     * API endpoint responsible for saving alternative text for an AI task output.
     * @summary REST.API.TP.TPT.05 Saving alternative text for output
     * @param {AiOutputAltTextRestDto} body 
     * @param {string} id The id that uniquely identifies the task.
     * @param {string} output_id The id that uniquely identifies the task output.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
     public saveAiOutputAltText(body: AiOutputAltTextRestDto, id: string, output_id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return TextProcessingTaskAPIApiFp(this.configuration).saveAiOutputAltText(this.axios, body, id, output_id, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * API endpoint responsible for saving feedback for a task output.
     * @summary REST.API.TP.TPT.04 Saving feedback for output
     * @param {TaskOutputFeedbackRestDto} body 
     * @param {string} id The id that uniquely identifies the task.
     * @param {string} output_id The id that uniquely identifies the task output.
     * @param {string} [x_dp_customer_code] The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TextProcessingTaskAPIApi
     */
     public saveTextProcessingTaskOutputFeedback(body: TaskOutputFeedbackRestDto, id: string, output_id: string, x_dp_customer_code?: string|null, x_dp_language?: string|null, options?: any) {
        return TextProcessingTaskAPIApiFp(this.configuration).saveTextProcessingTaskOutputFeedback(this.axios, body, id, output_id, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
