import {
    PharmacyInformationApiService,
} from "@/main";
import {
    CodeDescriptionRestDto,
    PharmacyRestDto,
} from "@/api/pharma-cms-pharmacy/models";
import {PharmacyRestDtoModel} from "@/models/PharmacyRestDtoModel";
import {parseSavedPharmacy, parseSearchedPharmacy} from "@/store/pharma-cms-pharmacy/middleware";

export default {
    namespaced: true,
    state() {
        return {
            pharmacy_info: {} as PharmacyRestDto,
            pharmacies: [] as CodeDescriptionRestDto[],
        }
    },
    actions: {
        clearPharmacies(context: any) {
            context.commit("clearPharmacies");
        },
        clearPharmacyInfo(context: any) {
            context.commit("clearPharmacyInfo");
        },

        async searchPharmacies(context: any, customer_code: string) {
            const response = await PharmacyInformationApiService.findPharmacies(customer_code);

            if (response.status === 200) {
                context.commit("setPharmacies", response.data);
            } else {
                context.commit("clearPharmacies");
                context.commit("clearPharmacyInfo");
            }
        },
        async searchPharmacyInfo(context: any, payload: any) {
            const pharmacy_code = payload.pharmacy_code as string;
            const customer_code = payload.customer_code as string;

            const response = await PharmacyInformationApiService.getPharmacy(pharmacy_code, customer_code);

            context.commit("setPharmacyInfo", parseSearchedPharmacy(response.data));
        },
        async createPharmacyInfo(context: any, payload: any) {
            const pharmacy_info = parseSavedPharmacy(payload.pharmacy_info) as PharmacyRestDtoModel;
            const customer_code = payload.customer_code as string;

            await PharmacyInformationApiService.createPharmacy(pharmacy_info, customer_code);
        },
    },
    mutations: {
        clearPharmacies(state: any) {
            state.pharmacies = [];
        },
        clearPharmacyInfo(state: any) {
            state.pharmacy_info = PharmacyRestDtoModel.createWithDefaults();
        },
        setPharmacyInfo(state: any, pharmacy_info: PharmacyRestDto) {
            state.pharmacy_info = pharmacy_info;
        },
        setPharmacies(state: any, pharmacies: CodeDescriptionRestDto[]) {
            state.pharmacies = pharmacies;
        },
    },
    getters: {
        getPharmacyCode(state: any): CodeDescriptionRestDto[] {
            return state.pharmacies[0].code;
        },
        getPharmacyInfo(state: any): PharmacyRestDto {
            return state.pharmacy_info;
        },
        getPharmacies(state: any): CodeDescriptionRestDto[] {
            return state.pharmacies;
        },
    }
}