
import {defineComponent} from "vue";
import BaseIconIsActive from "@/components/UI/BaseIconIsActive.vue";
import BaseDateFromTill from "@/components/UI/BaseDateFromTill.vue";
import {UtilityHelper} from "@/helpers/UtilityHelper";
import {ProductFeaturesRestService} from "@/services/rest/pharma-master/ProductFeaturesRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ProductLicenseWithFeaturesRestDtoModel} from "@/models/ProductLicenseWithFeaturesRestDtoModel";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {arrayHasContent} from "@/helpers/functions/array";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {default as Modal} from 'bootstrap/js/dist/modal';
import BaseModal from "@/components/UI/Modal/BaseModal.vue";
import {
  CustomerFeatureAuthorizationOverviewRestDtoModel
} from "@/models/CustomerFeatureAuthorizationOverviewRestDtoModel";
import {ProductFeatureForCustomerLicenseRestDtoModel} from "@/models/ProductFeatureForCustomerLicenseRestDtoModel";
import InputDate from "@/components/UI/InputDate.vue";
import {CustomerFeatureAuthorizationPeriodRestDtoModel} from "@/models/CustomerFeatureAuthorizationPeriodRestDtoModel";

export default defineComponent({
  name: "ProductLicenseFeatureOverview",
  components: {
    InputDate,
    BaseModal, AlertError2, BaseSpinner, BaseDateFromTill, BaseIconIsActive
  },
  props: {
    customerCode: {
      type: String,
      required: true,
    },
    productLicenseCode: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      modalProductFeatureAuthorization: null as Modal | null,

      activeProductFeatureAuthorization: null as CustomerFeatureAuthorizationOverviewRestDtoModel | null,
      productFeatureAuthorizationUI: UIStateDto.createWithDefaults(),

      productLicenseWithFeatures: ProductLicenseWithFeaturesRestDtoModel.createWithDefaults(),
      productLicenseWithFeaturesUI: UIStateDto.createWithDefaults()
    }
  },
  watch: {
    productLicenseCode: {
      async handler(): Promise<void> {
        await this.reloadContent();
      },
      immediate: true
    }
  },
  mounted() {
    this.modalProductFeatureAuthorization = new Modal('#modal-product-feature-authorization');
    this.reloadContent();
  },
  unmounted() {
    this.modalProductFeatureAuthorization?.dispose();
  },
  computed: {
    UtilityHelper() {
      return UtilityHelper
    },
    productLicenseHasFeatures(): boolean {
      return arrayHasContent(this.productLicenseWithFeatures?.features);
    }
  },
  methods: {
    openEditFeatureAuthorization(featureCode: string, authorizationId: string) {
      console.log('openEditFeatureAuthorization with id', authorizationId)
      //switch active authorization
      const activeFeature = this.productLicenseWithFeatures.features.find((f: ProductFeatureForCustomerLicenseRestDtoModel) => f.feature.code === featureCode) as ProductFeatureForCustomerLicenseRestDtoModel;
      this.activeProductFeatureAuthorization = (activeFeature.customer_feature_authorizations?.find((cfa: CustomerFeatureAuthorizationOverviewRestDtoModel) => cfa.id === authorizationId)) ?? null;

      // open modal
      this.modalProductFeatureAuthorization?.show();
    },
    async includeFeature(featureCode: string): Promise<void> {
      console.log('includeFeature with featureCode', featureCode)
      this.productLicenseWithFeaturesUI.clearError();

      try {
        await ProductFeaturesRestService.getInstance().includeProductFeature(featureCode, this.customerCode);
        await handleSavedSuccessfully();

        await this.reloadContent();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productLicenseWithFeaturesUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async excludeFeature(featureCode: string): Promise<void> {
      console.log('excludeFeature with featureCode', featureCode)
      this.productLicenseWithFeaturesUI.clearError();

      try {
        await ProductFeaturesRestService.getInstance().excludeProductFeature(featureCode, this.customerCode)
        await handleSavedSuccessfully();

        await this.reloadContent();
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productLicenseWithFeaturesUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async updateFeatureAuthorization(): Promise<void> {
      console.log('updateFeatureAuthorization with id', this.activeProductFeatureAuthorization?.id)
      this.productFeatureAuthorizationUI.clearError();

      try {
        if (this.activeProductFeatureAuthorization?.id) {
          const authorizationPeriod = new CustomerFeatureAuthorizationPeriodRestDtoModel(
              this.activeProductFeatureAuthorization.begin_date,
              this.activeProductFeatureAuthorization.end_date
          );
          await ProductFeaturesRestService.getInstance().updateCustomerFeatureAuthorization(authorizationPeriod, this.activeProductFeatureAuthorization.id, this.customerCode);
          this.modalProductFeatureAuthorization?.hide();
          await handleSavedSuccessfully();

          await this.reloadContent();
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productFeatureAuthorizationUI as UIStateDto))
            .catch(exceptions, true);
      }
    },
    async reloadContent(): Promise<void> {
      await this.searchProductLicenseWithFeatures();
    },
    async searchProductLicenseWithFeatures(): Promise<void> {
      this.productLicenseWithFeaturesUI
          .clearError()
          .setIsReady(false);

      try {
        if (this.productLicenseCode) {
          this.productLicenseWithFeatures = await ProductFeaturesRestService.getInstance().findProductLicenseWithFeatures(this.productLicenseCode, this.customerCode);
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
            .create()
            .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.productLicenseWithFeaturesUI as UIStateDto))
            .catch(exceptions, true);
      } finally {
        this.productLicenseWithFeaturesUI.setIsReady(true);
      }
    },
  }
});
