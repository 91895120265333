import {ProductLicenseRestDto} from "@/api/pharma-master-license-mgmt/models";

export class ProductLicenseRestDtoModel implements ProductLicenseRestDto {
    product_code?: string | null;
    begin_date: string;
    end_date?: string | null;
    end_reason?: string | null;

    constructor(begin_date: string) {
        this.begin_date = begin_date;
    }

    public static createWithDefaults(): ProductLicenseRestDtoModel {
        const model = new ProductLicenseRestDtoModel(new Date().toDateString());
        model.product_code = null;
        model.end_date = null;
        model.end_reason = null;

        return model;
    }
}