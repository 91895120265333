export function registerDpLanguageInterceptor(instance) {
    instance.interceptors.request.use(
        (config) => {
            // todo joris: change to en
            config.headers['x-dp-language'] = 'nl';

            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );
}