import {AddressRestDto} from "@/api/pharma-cms-pharmacy/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";

export class AddressRestDtoModel implements AddressRestDto {
    street?: MultilingualRestDtoModel;
    house_number?: string;
    box?: string;
    postal_code?: string;
    postal_code_addition?: string;
    city_name?: MultilingualRestDtoModel;
    country_name?: MultilingualRestDtoModel;
    extra_address_line?: MultilingualRestDtoModel;


    constructor() {
    }

    public static createWithDefaults(): AddressRestDtoModel {
        const model = new AddressRestDtoModel();
        model.street = MultilingualRestDtoModel.createWithDefaults();
        model.city_name = MultilingualRestDtoModel.createWithDefaults();
        model.country_name = new MultilingualRestDtoModel(null, null, null, 'België');
        model.extra_address_line = MultilingualRestDtoModel.createWithDefaults();

        return model;
    }
}