<template>
  <nav id="sidebarMenu" class="col-lg-2 d-md-block gray-100 sidebar collapse">
    <div class="position-sticky">
      <div class="text-center text-bg-warning fw-700 d-flex justify-content-between align-items-center w-100" v-if="AppContext.isDev()">
        <span>&nbsp;</span>
        <span>DEV</span>
        <a :href="Config.getInstance().read('appUrlProd')"><i class="bi bi-arrow-repeat"></i></a>
      </div>
      <div class="text-center text-bg-danger fw-700 d-flex justify-content-between align-items-center w-100" v-if="AppContext.isProd()">
        <span>&nbsp;</span>
        <span>PROD</span>
        <a :href="Config.getInstance().read('appUrlDev')"><i class="bi bi-arrow-repeat"></i></a>
      </div>
      <router-link :to="{name: 'home'}" class="d-block text-center mb-4">
        <img alt="DP logo" src="@/assets/DigitalPharma-logo-DEF-grayscale.png" style="max-width:100%;max-height:48px"
             class="d-inline-block align-text-top"/>
      </router-link>
      <div id="sidebar-collapse" v-if="isAuthenticated">
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center" :to="{name: 'customers'}">
              <i class="bi-people"></i>
              Customers
            </router-link>
          </li>
            <li class="nav-item">
                <router-link class="nav-link d-flex align-items-center" :to="{name: 'dashboard'}">
                    <i class="bi-speedometer"></i>
                    Dashboard
                </router-link>
            </li>
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center" :to="{name: 'nvps'}">
              <i class="bi-link"></i>
              Name value pairs
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center" :to="{name: 'job-runs'}">
              <i class="bi-card-checklist"></i>
              Job runs
            </router-link>
          </li>
          <li class="nav-item">
            <a
                class="nav-link dropdown-toggle"
                data-bs-toggle="collapse"
                data-bs-target="#text-processing-collapse">
              <i class="bi bi-cpu"></i>{{ 'Text processing' }}
            </a>
            <div class="collapse hidden" id="text-processing-collapse" data-bs-parent="#sidebar-collapse">
              <ul class="btn-toggle-nav list-unstyled">
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'text-processing-task-overview'}">
                    {{ 'Tasks' }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link d-flex align-items-center" :to="{name: 'default-closing-periods'}">
              <i class="bi bi-calendar-week"></i>{{ 'Standaard sluitingsperiodes' }}
            </router-link>
          </li>
          <li class="nav-item">
            <a
                class="nav-link dropdown-toggle"
                data-bs-toggle="collapse"
                data-bs-target="#content-collapse">
              <i class="bi bi-info-circle"></i>{{ $t('content') }}
            </a>
            <div class="collapse hidden" id="content-collapse" data-bs-parent="#sidebar-collapse">
              <ul class="btn-toggle-nav list-unstyled">
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'terms-and-conditions-static'}">
                    {{ $t('termsAndConditions') + ' ' + $t('static') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="nav-link d-flex align-items-center"
                               :to="{name: 'terms-and-conditions-reservation'}">
                    {{ $t('termsAndConditions') + ' ' + $t('reservation') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'terms-and-conditions-webshop'}">
                    {{ $t('termsAndConditions') + ' ' + $t('webshop') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'privacy-policy-static'}">
                    {{ $t('privacyPolicy') + ' ' + $t('static') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'privacy-policy-reservation'}">
                    {{ $t('privacyPolicy') + ' ' + $t('reservation') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'privacy-policy-webshop'}">
                    {{ $t('privacyPolicy') + ' ' + $t('webshop') }}
                  </router-link>
                </li>
                <li>
                  <router-link class="nav-link d-flex align-items-center" :to="{name: 'pharmacy-on-call'}">
                    {{ $t('pharmacyOnCall') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a href="javascript.void(0)" class="nav-link d-flex align-items-center" @click.prevent="this.logout"><i
                class="bi bi-box-arrow-left"></i>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <div>
        <locale-changer class="locale-changer mx-3"></locale-changer>
      </div>
      <div v-if="isAuthenticated">
        <div>{{ $t('loggedInAs') }}</div>
        <div>{{ fullName }}</div>
        <button class="btn btn-primary my-2" @click="logout">{{ $t('logout') }}</button>
      </div>
      <div v-else>
        <div>{{ $t('notLoggedInShort') }}</div>
        <button class="btn btn-primary my-2" @click="login">{{ $t('login') }}</button>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from 'vuex';
import {AppContext} from "@/context/AppContext";
import {Config} from "@/models/facade/Config";

export default {
  computed: {
    Config() {
      return Config
    },
    AppContext() {
      return AppContext
    },
    ...mapGetters(['getUser', 'isAuthenticated']),
    fullName() {
      return `${this.getUser?.first_name} ${this.getUser?.last_name}`;
    },
  },
  methods: {
    async login() {
      await this.$store.dispatch('login');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/home');
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0; /* Height of navbar */
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 16px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: var(--bs-primary);
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

i {
  font-size: 1.3rem;
  margin-right: 0.5rem;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>