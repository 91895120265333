import store from "@/store";
import {OrderSettingsApiService} from "@/main";
import {
    NameValuePairOverviewRestDto, NameValuePairRestDto,
} from "@/api/pharma-order-settings/models";
import {NameValuePairEnum} from "@/models/enum/NameValuePairEnum";

export default {
    namespaced: true,
    state() {
        return {
            nvp_overview: [] as NameValuePairOverviewRestDto[],
            nvp: {} as object
        }
    },
    actions: {
        clearNameValuePair(context: any, name: NameValuePairEnum) {
            context.commit("clearNameValuePair", name);
        },
        async searchNameValuePair(context: any, payload: any) {
            const customer_code = payload.customer_code;
            const name = payload.name;

            const response = await OrderSettingsApiService.getNvpByName(name, customer_code);

            context.commit("setNameValuePair", {
                body: response.data,
                name: name
            });
        },
        async searchNameValuePairOverview(context: any, customer_code: string) {
            const response = await OrderSettingsApiService.findNvps(customer_code);

            context.commit("setNameValuePairOverview", response.data);
        },
        async saveNameValuePair(context: any, payload: any) {
            const customer_code = payload.customer_code as string;
            const body = payload.body;
            const name = payload.name as string;

            await OrderSettingsApiService.updateNvp({value: body.value}, name, customer_code);
        },
        async saveNameValuePairsBulk(context: any, payload: any) {
            const customer_code = payload.customer_code as string;
            const body = payload.body as string;

            const bodyCopy = JSON.parse(JSON.stringify(body));
            bodyCopy.forEach((nvp: any) => {
                if (!nvp.value.length) {
                    nvp.value = null;
                }
                delete nvp['description'];
            });

            await OrderSettingsApiService.updateNvpsInBulk(bodyCopy, customer_code);
        },
    },
    mutations: {
        clearNameValuePair(state: any, name: NameValuePairEnum) {
            if (state.nvp[name]) {
                delete state.nvp[name];
            } else {
                console.log('Name value pair has not yet been initialized: ' + name);
            }
        },
        setNameValuePair(state: any, payload: any) {
            const body = payload.body as NameValuePairRestDto;
            const name = payload.name as NameValuePairEnum;

            state.nvp[name] = body;
        },
        setNameValuePairOverview(state: any, nvp_overview: NameValuePairOverviewRestDto[]) {
            state.nvp_overview = nvp_overview;
        },
    },
    getters: {
        getNameValuePair: (state: any) => (name: NameValuePairEnum): NameValuePairRestDto|undefined => {
            if (state.nvp[name]) {
                return state.nvp[name];
            } else {
                console.log('Name value pair has not yet been initialized: ' + name);
                return undefined;
            }
        },
        getNameValuePairOverview(state: any): NameValuePairOverviewRestDto[] {
            return state.nvp_overview;
        },
    }
}