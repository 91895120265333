<template>
  <div class="callback">
    Loading...
  </div>
</template>

<script>
export default {
  methods: {
    async handleOAuth2Callback() {
      const query = this.$route.query;

      if (query.code !== undefined) {
        await this.$store.dispatch('handleOAuth2Callback', query);
      }

      this.$router.push('/home');
    },
  },
  mounted() {
    this.handleOAuth2Callback();
  },
};
</script>