import { createInstance } from '@/helpers/axios';
import { config } from '@/helpers/fetch-config';

const axiosOptions = {
    baseURL: config.licenseInfo
};

const licenseInfoInstance = createInstance(axiosOptions);

// possibility to add instance specific configuration like interceptors etc.

export default licenseInfoInstance;
