
import {defineComponent} from "vue";

export default defineComponent({
  name: 'ConfirmModal',
  emits: ['confirm', 'cancel'],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    body: {
      type: String,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: false,
      default() {
        return 'Cancel';
      }
    },
    cancelButtonClass: {
      type: String,
      required: false,
      default: 'btn-secondary',
    },
    confirmButtonText: {
      type: String,
      required: false,
      default() {
        return 'Confirm';
      }
    },
    confirmButtonClass: {
      type: String,
      required: false,
      default: 'btn-primary',
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
});
