import {XDPModuleCodeEnum} from "@/models/enum/XDPModuleCodeEnum";
import {DpNvpCriteriaRestDtoModel} from "@/models/DpNvpCriteriaRestDtoModel";

export class NameValuePairCriteriaPayload {
    x_dp_module_code: XDPModuleCodeEnum;
    nvp_criteria: DpNvpCriteriaRestDtoModel;

    constructor(x_dp_module_code: XDPModuleCodeEnum, nvp_criteria: DpNvpCriteriaRestDtoModel) {
        this.x_dp_module_code = x_dp_module_code;
        this.nvp_criteria = nvp_criteria;
    }

    public static createWithDefaults(): NameValuePairCriteriaPayload {
        return new NameValuePairCriteriaPayload(XDPModuleCodeEnum.ORDER, DpNvpCriteriaRestDtoModel.createWithDefaults());
    }

    public static cloneFrom(payload: NameValuePairCriteriaPayload): NameValuePairCriteriaPayload {
        return new NameValuePairCriteriaPayload(payload.x_dp_module_code, payload.nvp_criteria);
    }
}