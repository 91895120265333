import {XDPModuleCodeEnum} from "@/models/enum/XDPModuleCodeEnum";
import {DpNvpCriteriaRestDtoModel} from "@/models/DpNvpCriteriaRestDtoModel";

export class PagingPayload {
    number: number;
    size: number;

    constructor(number: number, size: number) {
        this.number = number;
        this.size = size;
    }

    public static createWithDefaults(): PagingPayload {
        return new this(1, 24);
    }

    public static cloneFrom(payload: PagingPayload): PagingPayload {
        return new this(payload.number, payload.size);
    }
}