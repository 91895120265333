
import {config} from '@/helpers/fetch-config';
import {CustomerCriteriaRestDto} from "@/api/pharma-customer/models";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {CodeDescriptionRestDto} from "@/api/pharma-master-enum/models";
import {EnumClassNameMasterEnum} from "@/models/enum/EnumClassNameMasterEnum";

export default defineComponent({
  emits: ['searchCustomers'],
  data() {
    return {
      config,
      isReady: false as boolean,
      customerCriteriaRestDto: {} as CustomerCriteriaRestDto,
      statusOptions: [
        {
          code: 'SUCCESS',
          description: 'Success',
        },
        {
          code: 'FAILED',
          description: 'Failed',
        },
      ],
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('master_enum', ['getEnumByClassname']),
    enumProductType(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameMasterEnum.PRODUCT_TYPE);
    },
    enumProduct(): CodeDescriptionRestDto[] {
      return this.getEnumByClassname(EnumClassNameMasterEnum.PRODUCT);
    },
  },
  methods: {
    ...mapActions('master_enum', ['findManyEnumByClassname']),
    submitData() {
      this.$emit('searchCustomers', this.customerCriteriaRestDto);
    },
    clearCriteria() {
      this.customerCriteriaRestDto = {};
    },
    async reloadContent(): Promise<void> {
      this.isReady = false;
      await this.findManyEnumByClassname([
        EnumClassNameMasterEnum.PRODUCT_TYPE,
        EnumClassNameMasterEnum.PRODUCT
      ]);

      this.isReady = true;
    },
  },
});
