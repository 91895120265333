
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import AlertError2 from "@/components/UI/AlertError2.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {
  PagingMetadataRestDto, TextProcessingTaskOverviewRestDto,
} from "@/api/pharma-tp-task/models";
import {TextProcessingTaskRestService} from "@/services/rest/tp-task/TextProcessingTaskRestService";
import BaseSpinner from "@/components/UI/BaseSpinner.vue";
import {arrayHasContent} from "@/helpers/functions/array";
import {formatDateTime} from "@/helpers/functions/date";
import {
  TextProcessingTaskCriteriaRestDtoModel
} from "@/models/api/pharma-tp-task/TextProcessingTaskCriteriaRestDtoModel";
import TaskDetailControls from "@/components/layouts/text-processing/TaskDetailControls.vue";
import {PagingPayload} from "@/models/payload/PagingPayload";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputText from "@/components/UI/InputText.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";

export default defineComponent({
  name: "TaskOverview",
  components: {InputSelect, InputText, InputNumber, TaskDetailControls, BaseSpinner, AlertError2, BaseTitle},
  data() {
    return {
      taskOverview: [] as TextProcessingTaskOverviewRestDto[],
      taskOverviewPagingMetadata: {} as PagingMetadataRestDto,
      taskOverviewUI: UIStateDto.createWithDefaults()
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('text_processing', ["getTextProcessingCriteria", "getTextProcessingPaging"]),
    hasTasks(): boolean {
      return arrayHasContent(this.taskOverview);
    },
    textProcessingCriteria(): TextProcessingTaskCriteriaRestDtoModel {
      return this.getTextProcessingCriteria;
    },
    textProcessingPaging(): PagingPayload {
      return this.getTextProcessingPaging;
    },
    optionsTaskType(): CodeDescriptionRestDto[] {
      return [
        new CodeDescriptionRestDtoModel('AI_API', 'AI_API'),
        new CodeDescriptionRestDtoModel('HANDLE_HTML_PAGE', 'HANDLE_HTML_PAGE'),
        new CodeDescriptionRestDtoModel('QUERY_PROCESSING', 'QUERY_PROCESSING'),
        new CodeDescriptionRestDtoModel('SCRAPE_PAGE', 'SCRAPE_PAGE'),
        new CodeDescriptionRestDtoModel('TASK_COMPARISON', 'TASK_COMPARISON')
      ]
    },
  },
  methods: {
    ...mapActions('text_processing', ["clearTextProcessingCriteria", "setTextProcessingPaging"]),
    formatDateTime,
    clearCriteria(): void {
      this.clearTextProcessingCriteria();
    },
    async onControlsChanged(payload: PagingPayload): Promise<void> {
      await this.setTextProcessingPaging(payload);
      await this.reloadContent();
    },
    async reloadContent(): Promise<void> {
      this.taskOverviewUI
          .clearError()
          .setIsReady(false);

      try {
        const response = await TextProcessingTaskRestService.getInstance()
            .findTextProcessingTasksWithCriteria(this.textProcessingCriteria);
        this.taskOverview = response.results;
        this.taskOverviewPagingMetadata = response?.paging_metadata ?? {};
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.taskOverviewUI.setIsReady(true);
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.taskOverviewUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.taskOverviewUI as UIStateDto))
          .catch(exceptions, true);
    }
  }
})
