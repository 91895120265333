import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PharmacyInfoField = _resolveComponent("PharmacyInfoField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PharmacyInfoField, {
        label: _ctx.$t('address'),
        value: this.street
      }, null, 8, ["label", "value"]),
      (this.pharmacyInfo.company_address.extra_address_line.nl)
        ? (_openBlock(), _createBlock(_component_PharmacyInfoField, {
            key: 0,
            value: this.pharmacyInfo.company_address.extra_address_line.nl
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_PharmacyInfoField, {
        value: this.pharmacyInfo.company_address.postal_code + ' ' + this.pharmacyInfo.company_address.city_name.nl
      }, null, 8, ["value"]),
      _createVNode(_component_PharmacyInfoField, {
        value: this.pharmacyInfo.company_address.country_name.nl
      }, null, 8, ["value"])
    ]),
    _createVNode(_component_PharmacyInfoField, {
      class: "col-4",
      label: _ctx.$t('telephone'),
      value: this.pharmacyInfo.company_phone
    }, null, 8, ["label", "value"]),
    _createVNode(_component_PharmacyInfoField, {
      class: "col-4",
      label: _ctx.$t('emailAddress'),
      value: this.pharmacyInfo.company_email
    }, null, 8, ["label", "value"])
  ]))
}